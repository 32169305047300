import React, { Component } from 'react'
import get from 'lodash/get'
import { Filter } from './Filter'
import { FilterSlider } from './FilterSlider'
import { SortButton } from '../widgets/Sort'

// import { Icon } from 'shared/widgets'

export default function Column(props) {
  let {
    isTitle,
    title,
    // orderable enables sorting button that triggers new request for sorted data
    orderable,
    format,
    field,
    orderingField = field,
    item,
    index,
    align = 'left',
    width,
    list,
    filterable,
    accountingFilterable,
    filterableRange,
    className,
    onFilter,
    isDashboard,
    // sortable enables SortButton that triggers onSort method to reorder the data
    sortable,
    onSort,
    // sortDirection is the direction table is currently sorted by
    sortDirection,
    // sortedField is field the table is currently sorted by, should equal field when sorting by this column
    sortedField,
    hidden = false,
    hideFollowupClasses = false,
  } = props

  className = `${className || ''} text-${align}`
  const style = { width }
  let order
  let orderBy
  let direction = 'asc'
  if (isTitle) {
    if (sortable) {
      // Column Header will show a sort button to allow updating the table data
      return (<th style={style}>
        <SortButton
          fieldName={field}
          onSort={onSort}
          sortDirection={sortDirection}
          sortedField={sortedField}
        >
          {title}
        </SortButton>
      </th>)
    } else if (orderable && list.filters) {
      // Column Header will show sort / filter icons to allow request for new data
      orderBy = list.filters.data?.ordering || 'index'
      if (orderBy[0] === '-') {
        direction = 'desc'
        orderBy = orderBy.substr(1)
      }
      if (orderBy === orderingField) {
        order = (
          <i className={`icon-order-by-${direction}`} />
        )
      }

      className += ' orderable'
    }
    return (
      <th style={style} className={className + ' th--sort' + (filterable ? ' th-with-filter' : '')} onClick={reorder} hidden={hidden}>
        <span className='filter-title'>
          {title}
          {orderable && !filterable && <i data-testid="table-header-filter-icon" className="table-filter"/>}
          {filterable &&
          <Filter onFilter={onFilter} list={filterable} checkedList={list.filters.data.status}/>
          }
          {accountingFilterable && <Filter onFilter={onFilter} list={accountingFilterable} checkedList={''}/>}
          {filterableRange && (
            <FilterSlider
              onFilter={onFilter}
              settings={filterableRange}
              cu_filter={props.cu_filter}
              cu_min={props.cu_min}
              cu_max={props.cu_max}
              cuHandler={props.cuHandler}
            />
          )}
        </span>
        {order}
      </th>
    )
  }
  function reorder() {
    if (orderable && list.filters && list.filters.orderBy) {
      let prefix = orderable === 'desc' ? '-' : ''
      if (orderBy === orderingField) {
        prefix = direction === 'asc' ? '-' : ''
      }
      list.filters.orderBy(`${prefix}${orderingField}`)
    }
  }

  if (!format) {
    format = (value) => value
  }

  let printedValue = get(item, field)

  if (format.prototype instanceof Component) {
    const FormatTag = format
    printedValue = <FormatTag>{printedValue}</FormatTag>
  } else {
    printedValue = format(printedValue, item, { index, field })
  }

  const followupOrderClass = item.is_follow_up === true && !hideFollowupClasses && !isDashboard ? 'followup-td' : ''
  const hasfollowupOrdersClass = item.follow_up_orders && item.follow_up_orders.length > 0 && !isDashboard ? 'followup-parent' : ''
  const isFirstFollowupClass = item.is_first && !hideFollowupClasses && !isDashboard ? 'followup-first' : ''
  const isLastFollowupClass = item.is_last && !hideFollowupClasses && !isDashboard ? 'followup-last' : ''
  const isRushClass = (item.priority === 'Rush' || item.rush) && !title && !isDashboard && !followupOrderClass ? 'danger-color' : ''
  const isRefund = ('stripe_transfer_reversal_id' in item)
  const isRedText = (
    (isRefund && field === 'gross_amount_in_cents' && (isRefund ? item.gross_amount_in_cents > 0 : item.amount > 0)) ||
    (!isRefund && field === 'processing_fee_in_cents' && item.processing_fee_in_cents > 0) ||
    (isRefund && field === 'net_amount_in_cents' && item.net_amount_in_cents > 0) ||
    (!isRefund && field === 'net_amount_in_cents' && item.net_amount_in_cents < 0) ||
    (!isRefund && field === 'gross_amount_in_cents' && item.amount < 0)
  )

  return (
    <td
      hidden={hidden}
      className={`${className} ${followupOrderClass} ${hasfollowupOrdersClass} ${isFirstFollowupClass} ${isLastFollowupClass} ${isRushClass} ${item.is_follow_up && item.priority === 'Rush' ? 'p-0' : ''}`}
      style={{ color: isRedText ? 'red' : '#151b1e' }}
    >
      {printedValue}
    </td>
  )
}
