import React from 'react'
import PropTypes from 'prop-types'
import { Layout, Collapse, Space, Statistic, Row as AntRow, Col as AntCol } from 'antd'
import {
  ToolOutlined,
  CheckCircleFilled,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CloseCircleFilled,
  DeleteFilled,
  FileTextOutlined,
} from '@ant-design/icons'
import './styles/GridControlsCard.scss'

const { Sider } = Layout
const { Panel } = Collapse

const GridControlsCard = ({ isExpanded, onToggleExpand, rowCounts, flashFields, sections = [] }) => {
  if (!isExpanded) {
    return (
      <div className="rotated-title">
        <MenuUnfoldOutlined className="expand-button" onClick={() => onToggleExpand(true)} />
        <div className="content-wrapper">
          <span>Grid Controls</span>
          <ToolOutlined />
        </div>
      </div>
    )
  }

  return (
    <Sider width={320} className="grid-controls-sider" trigger={null}>
      <div className="sider-header">
        <Space>
          <ToolOutlined />
          <span>Grid Controls</span>
        </Space>
        <MenuFoldOutlined onClick={() => onToggleExpand(false)} className="collapse-button" />
      </div>

      <div className="sider-content">
        <Collapse defaultActiveKey={['statistics', 'validation', 'filters']} ghost>
          <Panel header="Statistics" key="statistics">
            <AntRow>
              <AntCol span={12}>
                <Statistic
                  className={flashFields.has('total') ? 'flash-highlight' : ''}
                  title="Total Rows"
                  value={rowCounts.total}
                  prefix={<FileTextOutlined />}
                />
              </AntCol>
              <AntCol span={12}>
                <Statistic
                  className={flashFields.has('valid') ? 'flash-highlight' : ''}
                  title="Valid Rows"
                  value={rowCounts.valid}
                  valueStyle={{ color: '#52c41a' }}
                  prefix={<CheckCircleFilled />}
                />
              </AntCol>
              <AntCol span={12}>
                <Statistic
                  className={flashFields.has('invalid') ? 'flash-highlight' : ''}
                  title="Invalid Rows"
                  value={rowCounts.invalid}
                  valueStyle={{ color: '#ff4d4f' }}
                  prefix={<CloseCircleFilled />}
                />
              </AntCol>
              <AntCol span={12}>
                <Statistic
                  className={flashFields.has('pendingDeletion') ? 'flash-highlight' : ''}
                  title="Pending Deletion"
                  value={rowCounts.pendingDeletion}
                  valueStyle={{ color: '#ff4d4f' }}
                  prefix={<DeleteFilled />}
                />
              </AntCol>
            </AntRow>
          </Panel>

          {sections.map((section, index) => (
            <Panel key={`section-${index}`} header={section.props?.title || `Section ${index + 1}`}>
              {section}
            </Panel>
          ))}
        </Collapse>
      </div>
    </Sider>
  )
}

GridControlsCard.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onToggleExpand: PropTypes.func.isRequired,
  rowCounts: PropTypes.shape({
    total: PropTypes.number.isRequired,
    valid: PropTypes.number.isRequired,
    invalid: PropTypes.number.isRequired,
    pendingDeletion: PropTypes.number.isRequired,
  }).isRequired,
  flashFields: PropTypes.instanceOf(Set),
  sections: PropTypes.arrayOf(PropTypes.node),
}

export default GridControlsCard
