import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { Typography, Skeleton, Statistic, Space, Switch } from 'antd'
import { ArrowLeftOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import LoadingSpinner from './LoadingSpinner'
import GridContainer from './GridContainer'
import FeeManagementHeader from './FeeManagementHeader'
import { OVERRIDE_TYPE_CONFIG } from '../constants/Breadcrumbs'
import { REQUIRED_COLUMNS_BY_OVERRIDE_TYPE, OVERRIDE_TYPES } from '../constants/Validation'
import '../styles/ViewOverride.scss'

const { Text } = Typography

const renderDetailValue = (key, value) => {
  if (key.toLowerCase().includes('created date') || key.toLowerCase().includes('last modified')) {
    return format(new Date(value), 'MMM d, yyyy h:mm a')
  }
  return value
}

const ViewOverride = ({ override, gridData, isLoading, onBack, onSave, onStatusChange, status }) => {
  const Icon = override?.overrideType ? OVERRIDE_TYPE_CONFIG[override.overrideType].icon : null

  if (!override && !isLoading) {
    return (
      <div className="view-override">
        <FeeManagementHeader title="Override Not Found" icon={ArrowLeftOutlined} onBack={onBack} />
      </div>
    )
  }

  return (
    <div className="view-override fee-management-container">
      <FeeManagementHeader
        title={override?.title}
        icon={Icon}
        onBack={onBack}
        breadcrumbs={[
          {
            text: OVERRIDE_TYPE_CONFIG[override?.overrideType]?.title,
            path: `/fee-management/overrides/${override?.overrideType}`,
            icon: Icon,
          },
          {
            text: override?.title,
            path: `/fee-management/override/${override?.id}`,
          },
        ]}
      />

      <div className="grid-layout">
        <div className="metadata-section">
          <div className="details-grid">
            {isLoading ? (
              <div className="skeleton-row">
                <Skeleton active paragraph={{ rows: 1 }} />
                <Skeleton active paragraph={{ rows: 1 }} />
                <Skeleton active paragraph={{ rows: 1 }} />
                <Skeleton active paragraph={{ rows: 1 }} />
              </div>
            ) : (
              <>
                {Object.entries(override?.details || {}).map(([key, value]) => (
                  <Statistic key={key} title={key} value={renderDetailValue(key, value)} />
                ))}
                <div className="status-item">
                  <Text className="status-label">Status</Text>
                  <Space align="center" size={12}>
                    <Switch
                      checked={status === 'active'}
                      onChange={onStatusChange}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      loading={isLoading}
                      disabled={isLoading}
                    />
                    <Text strong className={status === 'active' ? 'status-active' : 'status-inactive'}>
                      {status === 'active' ? 'Active' : 'Inactive'}
                    </Text>
                  </Space>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="grid-section">
          {isLoading ? (
            <div className="loading-container">
              <LoadingSpinner text="Loading override data..." />
            </div>
          ) : gridData ? (
            <GridContainer
              initialRowData={gridData}
              onSave={onSave}
              overrideType={override?.overrideType}
              overrideName={override?.title}
              selectedFields={[
                ...REQUIRED_COLUMNS_BY_OVERRIDE_TYPE[override?.overrideType],
                ...override?.overriddenFields,
              ]}
            />
          ) : (
            <div className="loading-container">
              <LoadingSpinner text="Preparing grid data..." />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ViewOverride.propTypes = {
  override: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    overrideType: PropTypes.oneOf(Object.values(OVERRIDE_TYPES).map((type) => type.value)).isRequired,
    overriddenFields: PropTypes.arrayOf(PropTypes.string).isRequired,
    details: PropTypes.object.isRequired,
  }),
  gridData: PropTypes.array,
  isLoading: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  status: PropTypes.oneOf(['active', 'inactive']).isRequired,
}

export default ViewOverride
