import FeeMgmtLayout from './FeeMgmtLayout'

const routes = [
  {
    path: '/',
    name: 'fee-management',
    component: FeeMgmtLayout,
  },
]

export default routes
