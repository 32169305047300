import React, { Component } from 'react'
import { Nav, NavItem, NavLink as RsNavLink } from 'reactstrap'
import classNames from 'classnames'

import nav from './_nav'
import { NavLink } from '../../../router'
import { checkPermission } from '../../../utils/helpers'
import Rephrase from '../../../utils/Rephrase'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { Icon } from '@ant-design/compatible'

class Sidebar extends Component {
  componentWillUnmount() {
    // Cleanup any subscriptions
    if (this.props.flags) {
      this.props.flags.off && this.props.flags.off()
    }
  }

  handleClick = (e) => {
    e.preventDefault()
    e.target.parentElement.classList.toggle('open')
  }

  getMinimize = (e) => {
    e.preventDefault()
    this.props.toggleMinimizeSidebar()
  }

  activeRoute = (routeName, props) => {
    // return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
    return props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown'
  }

  hideMobile = () => {
    if (document.body.classList.contains('sidebar-mobile-show')) {
      document.body.classList.toggle('sidebar-mobile-show')
    }
  }

  render() {
    const props = this.props
    const showReviewSettingsMenu = Boolean(
      props.flags?.showReviewSettingsMenu && props.user?.data?.is_internal_reggora_user,
    )

    // simple wrapper for nav-title item
    const wrapper = (item) => {
      return item.wrapper && item.wrapper.element
        ? React.createElement(item.wrapper.element, item.wrapper.attributes, item.name)
        : item.name
    }

    // nav list section title
    const title = (title, key) => {
      const classes = classNames('nav-title', title.class)
      return (
        <li key={key} className={classes}>
          <Rephrase>{wrapper(title)}</Rephrase>
        </li>
      )
    }

    // nav list divider
    const divider = (divider, key) => {
      const classes = classNames('divider', divider.class)
      return <li key={key} className={classes}></li>
    }

    // nav label with nav link
    const navLabel = (item, key) => {
      const classes = {
        item: classNames('hidden-cn', item.class),
        link: classNames('nav-label', item.class ? item.class : ''),
        icon: classNames(
          !item.icon ? 'fa fa-circle' : item.icon,
          item.label.variant ? `text-${item.label.variant}` : '',
          item.label.class ? item.label.class : '',
        ),
      }
      return navLink(item, key, classes)
    }

    // nav item with nav link
    const navItem = (item, key) => {
      const classes = {
        item: classNames(item.class),
        link: classNames('nav-link', item.variant ? `nav-link-${item.variant}` : ''),
        icon: classNames(item.icon),
      }
      return navLink(item, key, classes)
    }
    // nav link
    const navLink = (item, key, classes) => {
      const url = item.url ? item.url : ''
      const icon =
        item.name === 'Review Settings' ? (
          <i>
            <Icon type={classes.icon} />
          </i>
        ) : (
          <i className={classes.icon} />
        )
      if (item.children) {
        return (
          <li
            key={key}
            className={`nav-item nav-dropdown ${props.dropdown[key] ? 'active open' : ''}`}
            onClick={() => props.toggleDropdown(key)}
          >
            <a key={key} className="nav-link nav-dropdown-toggle">
              {icon}
              <Rephrase>{item.name}</Rephrase>
            </a>
            <ul className="nav-dropdown-items">
              {item.children
                .filter(({ url, permission, publicRoute }) =>
                  publicRoute || checkPermission(permission ? permission : `${url}_route`)
                )
                .map((child, idx) => (
                  <NavLink
                    key={`${key}-${idx}`}
                    to={child.url}
                    className={`${classes.link} nav-link-child`}
                    activeClassName="active"
                    onClick={this.hideMobile}
                  >
                    <Rephrase>{child.name}</Rephrase>
                  </NavLink>
                ))}
            </ul>
          </li>
        )
      }
      return (
        <NavItem key={key} className={classes.item}>
          {isExternal(url) ? (
            <RsNavLink target={item.target} href={url} className={classes.link}>
              {icon}
              {item.name}
            </RsNavLink>
          ) : (
            <NavLink to={url} className={classes.link} activeClassName="active" onClick={this.hideMobile}>
              {icon}
              {item.name}
            </NavLink>
          )}
        </NavItem>
      )
    }
    const navType = (item, idx) =>
      item.title
        ? title(item, idx)
        : item.divider
          ? divider(item, idx)
          : item.label
            ? navLabel(item, idx)
            : navItem(item, idx)

    const getNavLink = (item, index) => {
      if (item.demo) {
        if (props.user.data && props.user.data.is_demo) {
          return navType(item, index)
        } else {
          return null
        }
      } else {
        return navType(item, index)
      }
    }

    const navList = (routes) => {
      return routes
        .filter(Boolean)
        .filter((route) => route.url)
        .filter(({ permission, publicRoute, url }) =>
          publicRoute || checkPermission(permission ? permission : `${url}_route`)
        )
        .map((item, index) => getNavLink(item, index))
    }

    const isExternal = (url) => {
      const link = url ? url.substring(0, 4) : ''
      return link === 'http'
    }

    return (
      <div className="sidebar">
        <nav className="sidebar-nav">
          <Nav>{navList(nav(showReviewSettingsMenu).items, withLDConsumer)}</Nav>
        </nav>
        <button className="sidebar-minimizer" type="button" onClick={this.getMinimize} />
      </div>
    )
  }
}

const SidebarWithLD = withLDConsumer()(Sidebar)

export default SidebarWithLD
