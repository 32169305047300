import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { buildQueryParams } from './common/utils/queryParams'
import httpStatuses from './httpStatusCodes'

export class ApiClient {
  constructor(baseUrl) {
    this.client = axios.create({
      baseURL: baseUrl,
      headers: {
        Authorization: this.getAuthorizationHeader(),
        'Content-Type': 'application/json',
      },
    })
    // By default, axios throws an error if the response status is outside the range of 200-299
    this.client.interceptors.response.use(res => res.data, error => ApiClient.responseErrorHandler(error))
  }

  getAuthorizationHeader() {
    const authToken = localStorage.getItem('reggora_lender_auth_token')
    return authToken ? `bearer ${authToken}` : ''
  }

  get(url, queryParams = {}, config = {}) {
    const serializedQueryParams = ApiClient.queryParamSerializer(queryParams)
    const requestUrl = `${url}${serializedQueryParams}`
    return this.client.get(requestUrl, config)
  }

  /**
   * @param {string} url
   */
  post(url, queryParams = {}, body = {}, config = {}) {
    const serializedQueryParams = ApiClient.queryParamSerializer(queryParams)
    const requestUrl = `${url}${serializedQueryParams}`
    return this.client.post(requestUrl, body, config)
  }

  postForm(url, queryParams = {}, body = {}, config = {}) {
    const serializedQueryParams = ApiClient.queryParamSerializer(queryParams)
    const requestUrl = `${url}${serializedQueryParams}`
    return this.client.postForm(requestUrl, body, config)
  }

  put(url, queryParams = {}, body = {}, config = {}) {
    const serializedQueryParams = ApiClient.queryParamSerializer(queryParams)
    const requestUrl = `${url}${serializedQueryParams}`
    return this.client.put(requestUrl, body, config)
  }

  putForm(url, queryParams = {}, body = {}, config = {}) {
    const serializedQueryParams = ApiClient.queryParamSerializer(queryParams)
    const requestUrl = `${url}${serializedQueryParams}`
    return this.client.putForm(requestUrl, body, config)
  }

  patch(url, queryParams = {}, body = {}, config = {}) {
    const serializedQueryParams = ApiClient.queryParamSerializer(queryParams)
    const requestUrl = `${url}${serializedQueryParams}`
    return this.client.patch(requestUrl, body, config)
  }

  patchForm(url, queryParams = {}, body = {}, config = {}) {
    const serializedQueryParams = ApiClient.queryParamSerializer(queryParams)
    const requestUrl = `${url}${serializedQueryParams}`
    return this.client.patchForm(requestUrl, body, config)
  }

  options(url, config = {}) {
    return this.client.options(url, config)
  }

  delete(url, config = {}) {
    return this.client.delete(url, config)
  }

  static queryParamSerializer(params) {
    return isEmpty(params) ? '' : '?' + buildQueryParams(params)
  }

  static responseErrorHandler(error) {
    throw error
  }
}

export const statusCodes = httpStatuses

/**
 *
 * @param {string} baseUrl
 * @returns {ApiClient}
 */
export default function(baseUrl) {
  return new ApiClient(baseUrl)
}
