import React, { useMemo, useCallback } from 'react'
import {
  WarningFilled,
  WarningOutlined,
  DeleteOutlined,
  DeleteFilled,
  CopyFilled,
  ExclamationCircleFilled,
  StopFilled,
  ClusterOutlined,
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import {
  FeeValidationErrorTypes,
  LOCATION_TYPES,
  FEE_MGMT_COLUMNS,
  ROW_NUMBER_COL,
  ERROR_MESSAGE_TEMPLATES,
} from '../constants/Validation'
import IconHeader from '../IconHeader'
import GridSelectFilter from '../GridSelectFilter'
import FindReplaceFilter from '../FindReplaceFilter'
import EntitySelectCellEditor from '../components/EntitySelectCellEditor'
import SelectCellEditor from '../components/SelectCellEditor'
import { isEntityColumn } from './useEntityMapping'

const ICON_STYLE = {
  fontSize: '18px',
  position: 'relative',
  top: '1px',
}

const NON_BREAKING_SPACE = '\u00A0'

export const formatErrorMessage = (errorType, columnKey, details) => {
  // Get the template
  const templates = ERROR_MESSAGE_TEMPLATES[errorType] || {}
  const template = templates[columnKey] || templates.default || `${errorType} error`

  // No details, return template directly
  const hasDetails = details && Object.keys(details).length > 0
  if (!hasDetails) {
    return template
  }

  // Replace template variables with values from details
  return template.replace(/\${(\w+)}/g, (match, key) => {
    if (key === 'rowNumbers' && Array.isArray(details.rowNumbers)) {
      return details.rowNumbers.join(', ')
    }
    return details[key] !== undefined ? details[key] : match
  })
}

export const getValidationIcon = (validationState) => {
  if (!validationState) {
    return null
  }

  // Duplicate rows always take precedence
  if (validationState.isDuplicateRow) {
    const tooltipMessage = validationState.duplicateOf?.message || 'Duplicate row'

    return (
      <Tooltip title={tooltipMessage} placement="right">
        <CopyFilled
          style={{
            ...ICON_STYLE,
            color: 'rgba(255, 255, 255, 0.9)',
          }}
        />
      </Tooltip>
    )
  }

  // Check if there are any errors in the details
  const hasErrors = Object.values(validationState.validationErrors || {}).some(
    (error) => error.details && Object.keys(error.details).length > 0,
  )

  // If no errors, return null (don't show any icon)
  if (!hasErrors) {
    return null
  }

  // Get all unique error types from details
  const allErrorTypes = new Set()
  Object.values(validationState.validationErrors || {}).forEach((columnErrors) => {
    if (columnErrors.details) {
      Object.keys(columnErrors.details).forEach((errorType) => allErrorTypes.add(errorType))
    }
  })

  const errorTypes = Array.from(allErrorTypes)

  // For multiple errors (excluding duplicates), show stop icon in red
  if (errorTypes.length > 1) {
    return <StopFilled style={{ ...ICON_STYLE, color: '#ff4d4f' }} />
  }

  // For single error types
  const errorType = errorTypes[0]
  switch (errorType) {
    case FeeValidationErrorTypes.MISSING_VALUE:
      return <ExclamationCircleFilled style={{ ...ICON_STYLE, color: '#ff4d4f' }} />
    case FeeValidationErrorTypes.INVALID_FORMAT:
      return <WarningFilled style={{ ...ICON_STYLE, color: '#faad14' }} />
    case FeeValidationErrorTypes.DUPLICATE_STATE:
    case FeeValidationErrorTypes.DUPLICATE_COUNTY:
    case FeeValidationErrorTypes.DUPLICATE_ZIP:
      return <ClusterOutlined style={{ ...ICON_STYLE, color: '#1890ff' }} />
    default:
      return <WarningFilled style={{ ...ICON_STYLE, color: '#faad14' }} />
  }
}

export const ErrorCellRenderer = React.memo(({ data }) => {
  if (!data) {
    return null
  }

  const icon = getValidationIcon(data)
  if (!icon) {
    return null
  }

  return (
    <div className="error-cell" style={{ cursor: 'default' }}>
      {icon}
    </div>
  )
})

export const DeleteCellRenderer = React.memo(({ data, context }) => {
  if (!data || !context) {
    return <span className="delete-cell" />
  }

  const handleClick = (e) => {
    e.stopPropagation()
    // If it's a duplicate row, don't allow unmarking
    if (data.isDuplicateRow) {
      return
    }
    context.onToggleDelete([data.rowNumber], !data.userMarkedForDelete)
  }

  const isDuplicateRow = data.isDuplicateRow
  const isUserMarkedForDelete = data.userMarkedForDelete

  const deleteCellStyle = {
    fontSize: '16px',
    cursor: isDuplicateRow ? 'not-allowed' : 'pointer',
  }

  // Icon with tooltip for duplicate rows, or regular icon for normal rows
  const deleteIcon = isDuplicateRow ? (
    <Tooltip title="Duplicate rows cannot be unmarked" placement="top">
      <DeleteFilled className="anticon delete-marked" style={deleteCellStyle} onClick={handleClick} />
    </Tooltip>
  ) : isUserMarkedForDelete ? (
    <DeleteFilled className="anticon delete-marked" style={deleteCellStyle} onClick={handleClick} />
  ) : (
    <DeleteOutlined className="anticon" style={deleteCellStyle} onClick={handleClick} />
  )

  return (
    <span className="delete-cell" style={{ cursor: isDuplicateRow ? 'not-allowed' : 'pointer' }}>
      {deleteIcon}
    </span>
  )
})

export const CellWithErrorTooltip = React.memo((props) => {
  const { value, data, colDef } = props
  const field = colDef.field

  // First check if this is a duplicate row - if so, don't show error tooltips
  if (data?.isDuplicateRow) {
    return (
      <Tooltip title={value} mouseEnterDelay={0.3} overlayClassName="text-overflow-tooltip">
        <span className="cell-content">{value}</span>
      </Tooltip>
    )
  }

  const errors = data?.validationErrors?.[field]
  const rowNumber = data?.rowNumber
  const toolTipKey = `${rowNumber}-${field}`

  if (!errors || !errors.details || Object.keys(errors.details).length === 0) {
    return (
      <Tooltip title={value} mouseEnterDelay={0.3} overlayClassName="text-overflow-tooltip">
        <span className="cell-content">{value}</span>
      </Tooltip>
    )
  }

  // Generate error messages
  const errorMessages = []

  // Process each error type from the details object
  Object.keys(errors.details).forEach((errorType) => {
    const details = errors.details[errorType]?.values || []

    if (details.length > 0) {
      details.forEach((detail) => {
        const message = formatErrorMessage(errorType, field, detail)
        errorMessages.push(message)
      })
    } else {
      // No details, use the error type directly
      errorMessages.push(formatErrorMessage(errorType, field))
    }
  })

  if (errorMessages.length === 0) {
    return (
      <Tooltip title={value} mouseEnterDelay={0.3} overlayClassName="text-overflow-tooltip">
        <span className="cell-content">{value}</span>
      </Tooltip>
    )
  }

  return (
    <Tooltip
      title={
        <div>
          {errorMessages.map((msg, i) => (
            <div key={toolTipKey + i}>{msg}</div>
          ))}
        </div>
      }
      placement="top"
      mouseEnterDelay={0.3}
      overlayClassName="error-tooltip"
    >
      <div style={{ width: '100%', height: '100%' }}>
        <Tooltip title={value} placement="bottom" mouseEnterDelay={0.3} overlayClassName="text-overflow-tooltip">
          <span className="cell-content">{value || NON_BREAKING_SPACE}</span>
        </Tooltip>
      </div>
    </Tooltip>
  )
})

CellWithErrorTooltip.propTypes = {
  value: PropTypes.any,
  data: PropTypes.object,
  colDef: PropTypes.object,
}

ErrorCellRenderer.propTypes = {
  data: PropTypes.object,
}

DeleteCellRenderer.propTypes = {
  data: PropTypes.object,
  context: PropTypes.shape({
    onToggleDelete: PropTypes.func.isRequired,
  }),
}

DeleteCellRenderer.defaultProps = {
  data: undefined,
  context: undefined,
}

const formatCommaSeparatedValues = (value) => {
  if (!value) {
    return value
  }
  // Replace any comma followed by a character with a comma, space, and that character
  return String(value).replace(/,([^\s])/g, ', $1')
}

const useGridColumns = ({ rowData, headerDeleteActive, onHeaderDeleteToggle, gridContext }) => {
  // Track column existence
  const columnExistsInData = useMemo(() => {
    const exists = {}
    if (!Array.isArray(rowData)) {
      return exists
    }

    // Initialize all columns as not hidden
    Object.values(FEE_MGMT_COLUMNS).forEach((col) => {
      exists[col.columnKey] = true
    })

    return exists
  }, [rowData])

  // Common column configuration factory
  const createBaseColumn = useCallback(
    (columnConfig) => {
      const shouldHide = !columnExistsInData[columnConfig.columnKey]

      const baseConfig = {
        colId: columnConfig.columnKey,
        field: columnConfig.columnKey,
        headerName: columnConfig.readable,
        editable: true,
        hide: shouldHide,
        cellClass: (params) => {
          const errorField = columnConfig.columnKey
          if (!params.data?.validationErrors?.[errorField]) {
            return ''
          }

          // Check only for details with error values
          const hasErrorDetails =
            params.data.validationErrors[errorField].details &&
            Object.keys(params.data.validationErrors[errorField].details).length > 0

          return hasErrorDetails ? 'cell-has-error' : ''
        },
        context: {
          ...columnConfig.context.customGridColumnOptions,
        },
      }

      return baseConfig
    },
    [columnExistsInData],
  )

  // Factory for creating select filter columns
  const createSelectFilterColumn = useCallback(
    (columnConfig, options, doesFilterPassFn) => {
      const baseConfig = createBaseColumn(columnConfig)
      return {
        ...baseConfig,
        filter: GridSelectFilter,
        filterParams: {
          options,
          doesFilterPassFn,
        },
      }
    },
    [createBaseColumn],
  )

  // Factory for creating find/replace filter columns
  const createFindReplaceColumn = useCallback(
    (columnConfig) => {
      const baseConfig = createBaseColumn(columnConfig)

      // Use the isEntityColumn function to determine if this column should use EntitySelectCellEditor
      if (isEntityColumn(columnConfig.columnKey)) {
        return {
          ...baseConfig,
          filter: FindReplaceFilter,
          filterParams: {
            context: gridContext,
          },
          cellEditor: EntitySelectCellEditor,
          cellEditorPopup: false,
        }
      }

      return {
        ...baseConfig,
        filter: FindReplaceFilter,
        filterParams: {
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          context: gridContext,
        },
      }
    },
    [createBaseColumn, gridContext],
  )

  const createErrorColumn = useCallback(() => {
    const baseErrorColumn = createSelectFilterColumn(
      FEE_MGMT_COLUMNS.ERROR,
      [
        { value: 'errors', label: 'Has Errors' },
        { value: 'noErrors', label: 'No Errors' },
      ],
      (value, data, filterText) => {
        if (value === 'validation') {
          const errorType = filterText
          const cellErrors = data.validationErrors || {}
          return Object.values(cellErrors).some((error) => error.errorTypes && error.errorTypes.has(errorType))
        }
        if (value === 'errors') {
          return (
            data.isDuplicateRow ||
            (data.validationErrors && Object.values(data.validationErrors).some((col) => col.errorTypes?.size > 0))
          )
        }
        if (value === 'noErrors') {
          return (
            !data.isDuplicateRow &&
            (!data.validationErrors || Object.values(data.validationErrors).every((col) => !col.errorTypes?.size))
          )
        }
        return true
      },
    )

    return {
      ...baseErrorColumn,
      headerComponent: IconHeader,
      headerComponentParams: {
        icon: WarningOutlined,
        tooltip: 'Error',
        showMenu: true,
      },
      width: 48,
      cellRenderer: ErrorCellRenderer,
      cellClass: 'action-cell align-left',
      editable: false,
      sort: 'desc',
      sortIndex: 0,
      valueGetter: (params) => {
        const hasErrors =
          params.data.validationErrors &&
          Object.values(params.data.validationErrors).some((col) => col.errorTypes?.size > 0)
        return hasErrors ? 1 : 0
      },
    }
  }, [createSelectFilterColumn])

  // Add a new helper to create columns with error tooltips
  const createColumnWithErrorTooltip = useCallback(
    (columnConfig) => {
      const baseConfig = createFindReplaceColumn(columnConfig)

      return {
        ...baseConfig,
        cellRenderer: CellWithErrorTooltip,
      }
    },
    [createFindReplaceColumn],
  )

  // Memoize column definitions
  const columnDefs = useMemo(() => {
    const columns = [
      // Row number column
      {
        colId: ROW_NUMBER_COL.columnKey,
        field: ROW_NUMBER_COL.columnKey,
        headerName: ROW_NUMBER_COL.readable,
        width: 48,
        editable: false,
        filter: false,
        sortable: true,
        cellClass: 'align-right',
      },
      // Delete column
      {
        ...createSelectFilterColumn(
          FEE_MGMT_COLUMNS.DELETE,
          [
            { value: 'marked', label: 'Marked' },
            { value: 'unmarked', label: 'Unmarked' },
          ],
          (value, data) => {
            if (value === 'marked') {
              return data.isDuplicateRow || data.userMarkedForDelete
            }
            if (value === 'unmarked') {
              return !data.isDuplicateRow && !data.userMarkedForDelete
            }
            return true
          },
        ),
        headerComponent: IconHeader,
        headerComponentParams: {
          icon: DeleteOutlined,
          tooltip: 'Delete',
          showMenu: true,
          onClick: () => {
            onHeaderDeleteToggle()
          },
        },
        cellRenderer: (params) => {
          const isDuplicateRow = params.data?.isDuplicateRow
          const isUserMarkedForDelete = params.data?.userMarkedForDelete
          const iconStyle = {
            cursor: isDuplicateRow ? 'not-allowed' : 'pointer',
          }

          // Return icon with tooltip for duplicate rows
          if (isDuplicateRow) {
            return (
              <div className="delete-cell" style={iconStyle}>
                <Tooltip title="Duplicate rows cannot be unmarked" placement="top">
                  <DeleteFilled className="delete-marked" />
                </Tooltip>
              </div>
            )
          }

          // Return normal icon for regular rows
          return (
            <div className="delete-cell">
              {isUserMarkedForDelete ? <DeleteFilled className="delete-marked" /> : <DeleteOutlined />}
            </div>
          )
        },
        cellClass: 'action-cell align-left',
        editable: false,
        width: 48,
        onCellClicked: (params) => {
          // Skip action if it's a duplicate row
          if (params.data?.isDuplicateRow) {
            return
          }
          const context = params.context || {}
          context.onToggleDelete([params.data.rowNumber], !params.data.userMarkedForDelete)
        },
      },
      // Error column
      createErrorColumn(),
      // Data columns
      createColumnWithErrorTooltip(FEE_MGMT_COLUMNS.PRODUCT_NAME),
      createColumnWithErrorTooltip(FEE_MGMT_COLUMNS.VENDOR),
      {
        ...createSelectFilterColumn(
          FEE_MGMT_COLUMNS.LOCATION_TYPE,
          Object.values(LOCATION_TYPES).map((type) => ({ value: type, label: type })),
          (value, data) => (value === 'all' ? true : data.locationType === value),
        ),
        cellClass: (params) => {
          const errorField = FEE_MGMT_COLUMNS.LOCATION_TYPE.columnKey
          const errors = params.data?.validationErrors?.[errorField]

          if (!errors) {
            return ''
          }

          // Check only details
          const hasErrorDetails = errors.details && Object.keys(errors.details).length > 0

          return hasErrorDetails ? 'cell-has-error' : ''
        },
        width: 120,
        cellRenderer: (params) => {
          const { value, data, colDef } = params
          const field = colDef.field
          const errors = data?.validationErrors?.[field]

          // Basic style based on location type
          let locationStyle = {}
          if (value === LOCATION_TYPES.STATE) {
            locationStyle = { color: '#5c9bfa' } // Blue for multi-state
          } else if (value === LOCATION_TYPES.COUNTY) {
            locationStyle = { color: '#52c41a' } // Green for county
          } else if (value === LOCATION_TYPES.ZIP) {
            locationStyle = { color: '#fa8c16' } // Orange for zip
          }

          // If there are errors, use CellWithErrorTooltip
          if (errors && Object.keys(errors.details || {}).length > 0) {
            return <CellWithErrorTooltip {...params} />
          }

          // Otherwise, display with style
          return <span style={locationStyle}>{value}</span>
        },
        cellEditor: SelectCellEditor,
        cellEditorParams: {
          options: Object.values(LOCATION_TYPES).map((type) => ({ value: type, label: type })),
          placeholder: 'Select location type',
        },
        cellEditorPopup: false,
      },
      {
        ...createColumnWithErrorTooltip(FEE_MGMT_COLUMNS.STATES),
        cellRenderer: (params) => {
          const formattedValue = formatCommaSeparatedValues(params.value)
          return <CellWithErrorTooltip {...params} value={formattedValue} />
        },
      },
      {
        ...createColumnWithErrorTooltip(FEE_MGMT_COLUMNS.COUNTIES),
        cellRenderer: (params) => {
          const formattedValue = formatCommaSeparatedValues(params.value)
          return <CellWithErrorTooltip {...params} value={formattedValue} />
        },
      },
      {
        ...createColumnWithErrorTooltip(FEE_MGMT_COLUMNS.ZIP_CODES),
        cellRenderer: (params) => {
          const formattedValue = formatCommaSeparatedValues(params.value)
          return <CellWithErrorTooltip {...params} value={formattedValue} />
        },
      },
      createColumnWithErrorTooltip(FEE_MGMT_COLUMNS.FEE),
      createColumnWithErrorTooltip(FEE_MGMT_COLUMNS.DUE_DATE),
    ]

    return columns
  }, [onHeaderDeleteToggle, createSelectFilterColumn, createColumnWithErrorTooltip, createErrorColumn])

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 120,
      resizable: true,
      suppressMovable: true,
      menuTabs: ['filterMenuTab'],
      filter: true,
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
        maxNumConditions: 1,
      },
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      unSortIcon: true,
      singleClickEdit: true,
    }),
    [],
  )

  return { columnDefs, defaultColDef }
}

export default useGridColumns
