import React, { useMemo } from 'react'
import { Tooltip, Button } from 'antd'
import { FileSearchOutlined } from '@ant-design/icons'
import parse from 'html-react-parser'

const DataPointValue = (props) => {
  const { rendered_value } = props
  const parsed_rendered_value = useMemo(() => {
    return parse(rendered_value)
  }, [rendered_value])
  return <>{parsed_rendered_value}</>
}

const DataPoint = (props) => {
  const { name, rendered_value, onClick } = props

  if (onClick) {
    return (
      <span>
        {`${name}: `}
        {rendered_value && (
          <>
            <DataPointValue rendered_value={rendered_value} />
            <Tooltip title="Find">
              <Button className='mx-1' shape='circle' onClick={() => onClick(rendered_value)} icon={ <FileSearchOutlined />}>
              </Button>
            </Tooltip>
          </>
        )}
      </span>
    )
  }
  return (
    <span>
      {`${name}: `}
      {rendered_value && (
        <DataPointValue rendered_value={rendered_value} />)
      }
    </span>
  )
}

export default function ReviewDatapoints(props) {
  const { data_points, onClick } = props
  const relatedDataPoints = data_points || []
  const hasAnyDatapoints = relatedDataPoints.length !== 0

  if (!hasAnyDatapoints) {
    return <></>
  }

  return (
    <>
      <span className="data-points-label"> Related Data Points: </span>
      {relatedDataPoints.length === 1 ? (
        <DataPoint
          value={relatedDataPoints[0].value}
          rendered_value={relatedDataPoints[0].rendered_value}
          name={relatedDataPoints[0].name}
          onClick={onClick}/>
      ) : (
        <ul className="bullets">
          {relatedDataPoints.map((field, index) => (
            <li key={index}>
              <DataPoint
                value={field.value}
                rendered_value={field.rendered_value}
                name={field.name}
                onClick={onClick}/>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}
