import React, { useState } from 'react'
import { notification } from 'antd'
import { compose } from 'redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import SendConsumerModal from './SendConsumerModal'
import { connectResource } from '../../../common/utils/resource'

const BORROWER_ROLE_MATCH = 'borrower'

function openNotification({ title, message, duration }) {
  const args = {
    message: title,
    description: message,
    duration: duration || 2,
  }
  notification.open(args)
}

const SendConsumerModalContainer = props => {
  const [selectedConsumers, setSelectedConsumers] = useState([])
  const [radioState, setRadioState] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { blockManualAppraisalDeliveryToNonBorrowers } = useFlags()

  const passesBorrowerCheck = consumer => {
    if (blockManualAppraisalDeliveryToNonBorrowers) {
      return (consumer.role || '').toLowerCase().includes(BORROWER_ROLE_MATCH)
    }
    return true
  }

  const onSubmit = () => {
    setIsLoading(true)
    const users = []

    selectedConsumers.map((consumer) => {
      users.push(consumer.id)
    })
    const data = {
      consumers: users,
      order_id: props.orderData.id,
      submission_version: props.version,
    }
    props.sendReport.create(data)
      .then(({ failed }) => {
        let args = {
          title: 'Success',
          message: 'Successfully sent emails.',
          duration: 2,
        }
        if (failed && failed.length) {
          args = {
            title: 'Error',
            message: `Error sending to: ${(failed.join(', '))}`,
            duration: 5,
          }
        }
        openNotification(args)
        props.onHide()
        props.refreshOrder()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleChange = (selectedConsumers) => setSelectedConsumers(selectedConsumers)

  const handleRadio = ({ target: { value, name } }) => setRadioState({ ...radioState, [name]: value })

  let consumers = []

  if (props.orderData.schedule && props.orderData.schedule.consumers && props.orderData.schedule.consumers.length) {
    consumers = props.orderData.schedule.consumers.filter(c => !c.hidden && passesBorrowerCheck(c))
  }

  return <SendConsumerModal
    {...props}
    selectedConsumers={selectedConsumers}
    handleChange={handleChange}
    handleRadio={handleRadio}
    consumers={consumers}
    onSubmit={onSubmit}
    isLoading={isLoading}
  />
}

export default compose(
  connectResource({
    namespace: 'sendReport',
    endpoint: 'order/send-report',
    prefetch: false,
    apiVersion: 2,
  }),

)(SendConsumerModalContainer)
