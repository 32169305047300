/* Custom console logging utility to include the file path and line number of the caller
 * Formats objects as JSON inline for easy copy/paste into cursor for debugging
 */
const logFormattedMessage = (message, data = {}) => {
  const stack = new Error().stack
  const callerLine = stack.split('\n')[2].trim() // Adjust index as needed
  const filePath = callerLine.substring(callerLine.indexOf('at ') + 3, callerLine.lastIndexOf(':'))
  const lineNumber = callerLine.substring(callerLine.lastIndexOf(':') + 1, callerLine.lastIndexOf(')'))

  // Use a custom stringify function that handles circular references
  const safeJsonStringify = (obj) => {
    const seen = new WeakSet()
    return JSON.stringify(
      obj,
      (key, value) => {
        // Handle functions specially
        if (typeof value === 'function') {
          return '[Function]'
        }

        // Handle DOM nodes specially
        if (value instanceof Node) {
          return `[${value.nodeName} Element]`
        }

        // Check for circular references
        if (typeof value === 'object' && value !== null) {
          if (seen.has(value)) {
            return '[Circular Reference]'
          }
          seen.add(value)
        }
        return value
      },
      2,
    )
  }

  console.log(`[${new Date().toISOString()}] ${message} - ${filePath}:${lineNumber}`, safeJsonStringify(data))
}

export { logFormattedMessage }
