import { useMemo, useCallback } from 'react'
import {
  ZIP_REGEX,
  STATE_REGEX,
  COUNTY_REGEX,
  FEE_MGMT_COLUMNS,
  FeeValidationErrorTypes,
  LOCATION_TYPES,
  FEE_MGMT_COLS_COLUMN_KEYS,
  REQUIRED_COLUMNS_BY_OVERRIDE_TYPE,
  REQUIRED_COLUMNS_BY_OVERRIDE_TYPE_READABLE,
  OVERRIDE_COLUMNS_KEYS,
  OVERRIDE_COLUMNS_READABLE,
  OVERRIDE_TYPES,
} from '../constants/Validation'

// Helper functions for multi-value fields
const parseMultiValue = (value) => {
  if (!value) {
    return new Set()
  }
  // Sort values when creating the set
  return new Set(
    [...value.split(',')]
      .map((v) => v.trim())
      .filter(Boolean)
      .sort(),
  )
}

const stringifyMultiValue = (valueSet) => {
  if (!valueSet || valueSet.size === 0) {
    return ''
  }
  return Array.from(valueSet).join(', ')
}

// Helper to create a new column validation state
const createColumnValidation = () => ({
  errorTypes: new Set(),
  details: {},
})

// Validation helper functions
const createValidationSummary = () => {
  const errorCounts = {}
  const errorRows = {}

  Object.keys(FeeValidationErrorTypes).forEach((key) => {
    const type = FeeValidationErrorTypes[key]
    errorCounts[type] = 0
    errorRows[type] = new Set()
  })

  return {
    errorCounts: errorCounts,
    errorRows: errorRows,
    totalErrorCount: 0,
    hasErrors: false,
  }
}

// Helper to add an error to a row's validation state
const addError = (validationState, columnName, errorType, details = null) => {
  // Initialize validationState if it doesn't exist
  if (!validationState) {
    validationState = {
      isDuplicateRow: false,
      userMarkedForDelete: false,
      validationErrors: {},
    }
  }
  // Initialize validationErrors if it doesn't exist
  if (!validationState.validationErrors) {
    validationState.validationErrors = {}
  }

  if (!validationState.validationErrors[columnName]) {
    validationState.validationErrors[columnName] = createColumnValidation()
  }
  validationState.validationErrors[columnName].errorTypes.add(errorType)
  if (details) {
    if (!validationState.validationErrors[columnName].details[errorType]) {
      validationState.validationErrors[columnName].details[errorType] = { values: [] }
    }
    validationState.validationErrors[columnName].details[errorType].values.push(details)
  }
}

const createRowKey = (row, availableColumns) => {
  // Create a unique key string for each row based on the available columns
  const { LOCATION, VENDOR } = availableColumns
  const parts = [row[FEE_MGMT_COLS_COLUMN_KEYS.PRODUCT_NAME] || '']

  if (VENDOR) {
    parts.push(row[FEE_MGMT_COLS_COLUMN_KEYS.VENDOR] || '')
  }
  if (LOCATION) {
    parts.push(stringifyMultiValue(row.states), stringifyMultiValue(row.counties), stringifyMultiValue(row.zipCodes))
  }

  return parts.join('|')
}

const createCacheKey = (row, rowKey) => `${row['Product Name'] || ''}:${rowKey}`

const validateMissingValues = (row, validationState, summary, availableColumns) => {
  const { LOCATION, VENDOR, FEE, DUE_DATE } = availableColumns

  const addMissingValueError = (columnName) => {
    addError(validationState, columnName, FeeValidationErrorTypes.MISSING_VALUE, {})
    summary.errorRows[FeeValidationErrorTypes.MISSING_VALUE].add(row.rowNumber)
  }

  if (LOCATION) {
    const rowLocationType = row[FEE_MGMT_COLS_COLUMN_KEYS.LOCATION_TYPE]?.trim()
    switch (rowLocationType) {
      case '':
        addMissingValueError(FEE_MGMT_COLS_COLUMN_KEYS.LOCATION_TYPE)
        break
      case LOCATION_TYPES.STATE:
        if (row.states.size === 0) {
          addMissingValueError(FEE_MGMT_COLS_COLUMN_KEYS.STATES)
        }
        if (row.counties.size > 0) {
          addError(
            validationState,
            FEE_MGMT_COLS_COLUMN_KEYS.COUNTIES,
            FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE,
            { value: 'County not allowed for Multi-State Location Type' },
          )
          summary.errorRows[FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE].add(row.rowNumber)
        }
        if (row.zipCodes.size > 0) {
          addError(
            validationState,
            FEE_MGMT_COLS_COLUMN_KEYS.ZIP_CODES,
            FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE,
            { value: 'Zip Code not allowed for Multi-State Location Type' },
          )
          summary.errorRows[FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE].add(row.rowNumber)
        }
        break
      case LOCATION_TYPES.COUNTY:
        if (row.states.size === 0) {
          addMissingValueError(FEE_MGMT_COLS_COLUMN_KEYS.STATES)
        }
        if (row.zipCodes.size > 0) {
          addError(
            validationState,
            FEE_MGMT_COLS_COLUMN_KEYS.ZIP_CODES,
            FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE,
            { value: 'Zip Code not allowed for County Location Type' },
          )
          summary.errorRows[FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE].add(row.rowNumber)
        }
        if (row.counties.size === 0) {
          addMissingValueError(FEE_MGMT_COLS_COLUMN_KEYS.COUNTIES)
        }
        break
      case LOCATION_TYPES.ZIP:
        if (row.states.size === 0) {
          addMissingValueError(FEE_MGMT_COLS_COLUMN_KEYS.STATES)
        }
        if (row.counties.size > 0) {
          addError(
            validationState,
            FEE_MGMT_COLS_COLUMN_KEYS.COUNTIES,
            FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE,
            { value: 'County not allowed for Zip Code Location Type' },
          )
          summary.errorRows[FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE].add(row.rowNumber)
        }
        if (row.zipCodes.size === 0) {
          addMissingValueError(FEE_MGMT_COLS_COLUMN_KEYS.ZIP_CODES)
        }
        break
      default:
        break
    }
  }

  if (VENDOR && !row[FEE_MGMT_COLS_COLUMN_KEYS.VENDOR]?.trim()) {
    addMissingValueError(FEE_MGMT_COLS_COLUMN_KEYS.VENDOR)
  }
  if (!row[FEE_MGMT_COLS_COLUMN_KEYS.PRODUCT_NAME]?.trim()) {
    addMissingValueError(FEE_MGMT_COLS_COLUMN_KEYS.PRODUCT_NAME)
  }

  // Only require one of fee or due date if both are available for override
  if (FEE && DUE_DATE) {
    if (!row[FEE_MGMT_COLS_COLUMN_KEYS.FEE] && !row[FEE_MGMT_COLS_COLUMN_KEYS.DUE_DATE]) {
      addMissingValueError(FEE_MGMT_COLS_COLUMN_KEYS.FEE)
      addMissingValueError(FEE_MGMT_COLS_COLUMN_KEYS.DUE_DATE)
    }
    return
  }

  if (FEE && !row[FEE_MGMT_COLS_COLUMN_KEYS.FEE]) {
    addMissingValueError(FEE_MGMT_COLS_COLUMN_KEYS.FEE)
  }
  if (DUE_DATE && !row[FEE_MGMT_COLS_COLUMN_KEYS.DUE_DATE]) {
    addMissingValueError(FEE_MGMT_COLS_COLUMN_KEYS.DUE_DATE)
  }
}

// Remove state/county/zip validation from validateFormats and create a specialized function
const validateLocations = (row, validationState, summary, columns) => {
  // Skip if location type column isn't available
  if (!columns.has(FEE_MGMT_COLS_COLUMN_KEYS.LOCATION_TYPE)) {
    return
  }

  // Validate Location Type first
  const locationType = row[FEE_MGMT_COLS_COLUMN_KEYS.LOCATION_TYPE]?.trim()
  if (locationType && !Object.values(LOCATION_TYPES).includes(locationType)) {
    addError(validationState, FEE_MGMT_COLS_COLUMN_KEYS.LOCATION_TYPE, FeeValidationErrorTypes.INVALID_VALUE, {
      value: locationType,
    })
    summary.errorRows[FeeValidationErrorTypes.INVALID_VALUE].add(row.rowNumber)
  }

  // Validate States
  if (row.states.size > 0) {
    const invalidStates = Array.from(row.states).filter((state) => !STATE_REGEX.test(state))
    if (invalidStates.length > 0) {
      addError(validationState, FEE_MGMT_COLS_COLUMN_KEYS.STATES, FeeValidationErrorTypes.INVALID_VALUE, {
        value: invalidStates.join(', '),
      })
      summary.errorRows[FeeValidationErrorTypes.INVALID_VALUE].add(row.rowNumber)
    }
  }

  // Validate Counties - only applicable for COUNTY location type
  if (row.counties.size > 0) {
    const invalidCounties = Array.from(row.counties).filter((county) => !COUNTY_REGEX.test(county))
    if (invalidCounties.length > 0) {
      addError(validationState, FEE_MGMT_COLS_COLUMN_KEYS.COUNTIES, FeeValidationErrorTypes.INVALID_VALUE, {
        value: invalidCounties.join(', '),
      })
      summary.errorRows[FeeValidationErrorTypes.INVALID_VALUE].add(row.rowNumber)
    }
  }

  // Validate ZIP codes - only applicable for ZIP location type
  if (row.zipCodes.size > 0) {
    const invalidZips = Array.from(row.zipCodes).filter((zip) => !ZIP_REGEX.test(zip))
    if (invalidZips.length > 0) {
      addError(validationState, FEE_MGMT_COLS_COLUMN_KEYS.ZIP_CODES, FeeValidationErrorTypes.INVALID_VALUE, {
        value: invalidZips.join(', '),
      })
      summary.errorRows[FeeValidationErrorTypes.INVALID_VALUE].add(row.rowNumber)
    }
  }
}

const isUndefinedOrNull = (value) => {
  if (value === undefined || value === null) {
    return true
  }
  return false
}

// Simplify validateFormats to only handle numeric field validation
const validateFormats = (row, validationState, summary, columns) => {
  // Validate Fee is an integer
  if (columns.has(FEE_MGMT_COLS_COLUMN_KEYS.FEE)) {
    const feeValue = row[FEE_MGMT_COLS_COLUMN_KEYS.FEE]
    if (isUndefinedOrNull(feeValue)) {
      return
    }
    const feeNumber = Number(feeValue)
    if (!Number.isInteger(feeNumber)) {
      addError(validationState, FEE_MGMT_COLS_COLUMN_KEYS.FEE, FeeValidationErrorTypes.INVALID_FORMAT, {
        value: row[FEE_MGMT_COLS_COLUMN_KEYS.FEE],
      })
      summary.errorRows[FeeValidationErrorTypes.INVALID_FORMAT].add(row.rowNumber)
    }
  }

  if (columns.has(FEE_MGMT_COLS_COLUMN_KEYS.DUE_DATE)) {
    const dueDateValue = row[FEE_MGMT_COLS_COLUMN_KEYS.DUE_DATE]
    if (isUndefinedOrNull(dueDateValue)) {
      return
    }
    const dueDateNumber = Number(dueDateValue)
    if (!Number.isInteger(dueDateNumber)) {
      addError(validationState, FEE_MGMT_COLS_COLUMN_KEYS.DUE_DATE, FeeValidationErrorTypes.INVALID_FORMAT, {
        value: row[FEE_MGMT_COLS_COLUMN_KEYS.DUE_DATE],
      })
      summary.errorRows[FeeValidationErrorTypes.INVALID_FORMAT].add(row.rowNumber)
    }
  }
}

const markDuplicateRows = (productGroups, markedDuplicateRows, duplicateRowsMap) => {
  productGroups.forEach((group) => {
    if (group.length > 1) {
      group.sort((a, b) => a.rowNumber - b.rowNumber)
      const seenKeys = new Map()

      group.forEach(({ rowNumber, rowKey }) => {
        if (seenKeys.has(rowKey)) {
          markedDuplicateRows.add(rowNumber)
          duplicateRowsMap.set(rowNumber, seenKeys.get(rowKey))
        } else {
          seenKeys.set(rowKey, rowNumber)
        }
      })
    }
  })
}

const trackLocation = (
  locationTypeCondition,
  locationValues,
  regex,
  keyToRows,
  keyGenerator,
  groupKey,
  row,
  validationState,
  locationType,
) => {
  if (locationTypeCondition) {
    locationValues.forEach((value) => {
      if (regex.test(value)) {
        const key = keyGenerator(groupKey, value)
        if (!keyToRows.has(key)) {
          keyToRows.set(key, new Set())
        }
        keyToRows.get(key).add({
          rowNumber: row.rowNumber,
          validationState,
          locationType,
        })
      }
    })
  }
}

const trackLocations = (
  { validationState, row, states, counties, zipCodes, vendor, productName },
  { stateKeyToRows, countyKeyToRows, zipKeyToRows },
) => {
  if (!validationState.isDuplicateRow && !validationState.userMarkedForDelete) {
    const locationType = row[FEE_MGMT_COLS_COLUMN_KEYS.LOCATION_TYPE]?.toUpperCase()
    const groupKey = vendor ? `${productName}:${vendor}` : productName

    // Track states only for MULTI-STATE location type
    trackLocation(
      states.size > 0 && locationType === LOCATION_TYPES.STATE,
      states,
      STATE_REGEX,
      stateKeyToRows,
      (groupKey, state) => `${groupKey}:${state}`,
      groupKey,
      row,
      validationState,
      locationType,
    )

    // Track counties only for COUNTY location type
    trackLocation(
      counties.size > 0 && states.size > 0 && locationType === LOCATION_TYPES.COUNTY,
      counties,
      COUNTY_REGEX,
      countyKeyToRows,
      (groupKey, county) => {
        const state = Array.from(states)[0] // Assuming only one state for county
        return `${groupKey}:${state}:${county}`
      },
      groupKey,
      row,
      validationState,
      locationType,
    )

    // Track ZIP codes only for ZIP location type
    trackLocation(
      zipCodes.size > 0 && locationType === LOCATION_TYPES.ZIP,
      zipCodes,
      ZIP_REGEX,
      zipKeyToRows,
      (groupKey, zip) => `${groupKey}:${zip}`,
      groupKey,
      row,
      validationState,
      locationType,
    )
  }
}

const trackVendors = (validationState, vendor, productName, vendorKeyToRows, availableColumns) => {
  if (availableColumns.VENDOR && !availableColumns.LOCATION && !validationState.isDuplicateRow) {
    const vendorKey = `${productName}:${vendor}`
    if (!vendorKeyToRows.has(vendorKey)) {
      vendorKeyToRows.set(vendorKey, new Set())
    }
  }
}

const checkDuplicateLocation = (
  keyToRows,
  locationType,
  readableColumn,
  errorType,
  keySplitIndices,
  formatValue,
  summary,
) => {
  keyToRows.forEach((rowSet, key) => {
    const rowLocations = Array.from(rowSet).filter((row) => row.locationType === locationType)
    if (rowLocations.length > 1) {
      const keyParts = key.split(':')

      // Extract the actual values based on the key structure
      let formattedValue

      // Handle special case for counties which need both state and county
      if (errorType === FeeValidationErrorTypes.DUPLICATE_COUNTY) {
        // For counties, we need to extract the actual county name which is the last part of the key
        const lastIndex = keyParts.length - 1
        formattedValue = keyParts[lastIndex]
      } else {
        // For other types, use the provided indices
        const extractedValues = keySplitIndices.map((index) => {
          // Handle negative indices correctly
          const actualIndex = index < 0 ? keyParts.length + index : index
          return keyParts[actualIndex] || ''
        })
        formattedValue = formatValue ? formatValue(...extractedValues) : extractedValues.join(' - ')
      }

      const rowNumbers = rowLocations.map((rl) => rl.rowNumber).sort((a, b) => a - b)

      rowLocations.forEach(({ rowNumber, validationState }) => {
        addError(validationState, readableColumn, errorType, {
          value: formattedValue,
          rowNumbers: rowNumbers.filter((rn) => rn !== rowNumber), // All other row numbers
        })
        summary.errorRows[errorType].add(rowNumber)
      })
    }
  })
}

const checkDuplicateLocations = (stateKeyToRows, countyKeyToRows, zipKeyToRows, summary) => {
  checkDuplicateLocation(
    stateKeyToRows,
    LOCATION_TYPES.STATE,
    FEE_MGMT_COLS_COLUMN_KEYS.STATES,
    FeeValidationErrorTypes.DUPLICATE_STATE,
    [2], // Index of state in the key
    null,
    summary,
  )

  checkDuplicateLocation(
    countyKeyToRows,
    LOCATION_TYPES.COUNTY,
    FEE_MGMT_COLS_COLUMN_KEYS.COUNTIES,
    FeeValidationErrorTypes.DUPLICATE_COUNTY,
    // No specific indices needed, handled specially in the function
    null,
    null,
    summary,
  )

  checkDuplicateLocation(
    zipKeyToRows,
    LOCATION_TYPES.ZIP,
    FEE_MGMT_COLS_COLUMN_KEYS.ZIP_CODES,
    FeeValidationErrorTypes.DUPLICATE_ZIP,
    [2], // Index of zip in the key
    null,
    summary,
  )
}

const checkDuplicateVendor = (vendorKeyToRows, summary) => {
  vendorKeyToRows.forEach((rowSet, vendorKey) => {
    if (rowSet.size > 1) {
      const [, vendor] = vendorKey.split(':')
      const rowNumbers = Array.from(rowSet)
        .map((r) => r.rowNumber)
        .sort((a, b) => a - b)

      rowSet.forEach(({ rowNumber, validationState }) => {
        addError(validationState, FEE_MGMT_COLS_COLUMN_KEYS.VENDOR, FeeValidationErrorTypes.DUPLICATE_VENDOR, {
          value: vendor,
          rowNumbers: rowNumbers.filter((rn) => rn !== rowNumber),
        })
        summary.errorRows[FeeValidationErrorTypes.DUPLICATE_VENDOR].add(rowNumber)
      })
    }
  })
}

const createProductGroups = (rows, availableColumns, productGroups, rowLocationMap) => {
  rows.forEach((row) => {
    const productName = row[FEE_MGMT_COLS_COLUMN_KEYS.PRODUCT_NAME] || ''
    const states = availableColumns.LOCATION ? parseMultiValue(row[FEE_MGMT_COLS_COLUMN_KEYS.STATES]) : new Set()
    const counties = availableColumns.LOCATION ? parseMultiValue(row[FEE_MGMT_COLS_COLUMN_KEYS.COUNTIES]) : new Set()
    const zipCodes = availableColumns.LOCATION ? parseMultiValue(row[FEE_MGMT_COLS_COLUMN_KEYS.ZIP_CODES]) : new Set()
    rowLocationMap.set(row.rowNumber, { states: states, counties: counties, zipCodes: zipCodes })
    const rowKey = createRowKey({ ...row, states, counties, zipCodes }, availableColumns)

    if (!productGroups.has(productName)) {
      productGroups.set(productName, [])
    }
    productGroups.get(productName).push({ rowNumber: row.rowNumber, rowKey, row })
  })
}

// Update validateEntities to include specific location validation
const validateEntities = (row, validationState, summary, overrideType, mappingData) => {
  // Validate product exists
  const productName = row[FEE_MGMT_COLS_COLUMN_KEYS.PRODUCT_NAME]?.trim()
  if (productName) {
    const productExists = mappingData.products.some((p) => p.description === productName)
    if (!productExists) {
      addError(validationState, FEE_MGMT_COLS_COLUMN_KEYS.PRODUCT_NAME, FeeValidationErrorTypes.INVALID_VALUE, {
        value: productName,
      })
      summary.errorRows[FeeValidationErrorTypes.INVALID_VALUE].add(row.rowNumber)
    }
  }

  // Validate vendor exists if needed
  if (overrideType === OVERRIDE_TYPES.VENDOR.value || overrideType === OVERRIDE_TYPES.VENDOR_LOCATION.value) {
    const vendorName = row[FEE_MGMT_COLS_COLUMN_KEYS.VENDOR]?.trim()
    if (vendorName) {
      const vendorExists = mappingData.panelists.some((p) => p.name === vendorName)
      if (!vendorExists) {
        addError(validationState, FEE_MGMT_COLS_COLUMN_KEYS.VENDOR, FeeValidationErrorTypes.INVALID_VALUE, {
          value: vendorName,
        })
        summary.errorRows[FeeValidationErrorTypes.INVALID_VALUE].add(row.rowNumber)
      }
    }
  }

  // Location validations if needed (only applies for location-related override types)
  if (overrideType === OVERRIDE_TYPES.LOCATION.value || overrideType === OVERRIDE_TYPES.VENDOR_LOCATION.value) {
    // State validation
    const statesArr = row.states?.split(',').map((s) => s.trim()) || []
    if (statesArr.length > 0) {
      const invalidStates = statesArr.filter((state) => !mappingData.state_and_counties_map[state])
      if (invalidStates.length > 0) {
        addError(validationState, FEE_MGMT_COLS_COLUMN_KEYS.STATES, FeeValidationErrorTypes.INVALID_VALUE, {
          value: invalidStates.join(', '),
        })
        summary.errorRows[FeeValidationErrorTypes.INVALID_VALUE].add(row.rowNumber)
      }
    }

    // County validation

    const locationType = row[FEE_MGMT_COLS_COLUMN_KEYS.LOCATION_TYPE]?.trim()
    const countiesArr = row.counties?.split(',').map((c) => c.trim()) || []
    if (locationType === LOCATION_TYPES.COUNTY && statesArr.length === 1 && countiesArr.length > 0) {
      const state = statesArr[0] // Counties are associated with a single state
      const stateData = mappingData.state_and_counties_map[state]

      if (stateData) {
        const invalidCounties = countiesArr.filter((county) => !stateData.counties[county.toUpperCase()])

        if (invalidCounties.length > 0) {
          addError(validationState, FEE_MGMT_COLS_COLUMN_KEYS.COUNTIES, FeeValidationErrorTypes.INVALID_VALUE, {
            value: invalidCounties.join(', '),
          })
          summary.errorRows[FeeValidationErrorTypes.INVALID_VALUE].add(row.rowNumber)
        }
      }
    }
  }
}

export const useValidation = () => {
  const validationCache = useMemo(() => new Map(), [])

  const getRequiredColumns = useCallback((overrideType, selectedFields, readable = false) => {
    const requiredColumns = readable ? REQUIRED_COLUMNS_BY_OVERRIDE_TYPE_READABLE : REQUIRED_COLUMNS_BY_OVERRIDE_TYPE
    return {
      requiredColumns: [...(requiredColumns.common || []), ...(requiredColumns[overrideType] || [])],
      overrideColumns: selectedFields.filter((field) =>
        readable ? OVERRIDE_COLUMNS_READABLE.includes(field) : OVERRIDE_COLUMNS_KEYS.includes(field),
      ),
    }
  }, [])

  const validateHeaders = useCallback(
    (headers, overrideType, selectedFields) => {
      const requiredColumns = getRequiredColumns(overrideType, selectedFields)
      const errors = []
      const extraColumns = []

      // Check for required columns
      requiredColumns.forEach((column) => {
        if (!headers.has(column)) {
          errors.push(`Missing required column: ${column}`)
        }
      })

      // Find extra columns and filter them out from headers
      const allowedColumns = new Set(requiredColumns)
      const filteredHeaders = new Set()

      headers.forEach((header) => {
        if (allowedColumns.has(header)) {
          filteredHeaders.add(header)
        } else {
          extraColumns.push(header)
        }
      })

      const result = {
        isValid: errors.length === 0,
        errors,
        requiredColumns,
        extraColumns,
        headers: filteredHeaders, // Return filtered headers
      }

      return result
    },
    [getRequiredColumns],
  )

  const validateRows = useCallback(
    (rows, { overrideType, overrideFields, mappingData }) => {
      console.log('validateRows called')

      const summary = createValidationSummary()
      const stateKeyToRows = new Map()
      const countyKeyToRows = new Map()
      const zipKeyToRows = new Map()
      const vendorKeyToRows = new Map()
      const productGroups = new Map()
      const markedDuplicateRows = new Set()
      const duplicateRowsMap = new Map()
      const { requiredColumns, overrideColumns } = getRequiredColumns(overrideType, overrideFields)
      const availableColumns = {
        LOCATION: requiredColumns.includes(FEE_MGMT_COLS_COLUMN_KEYS.LOCATION_TYPE),
        VENDOR: requiredColumns.includes(FEE_MGMT_COLS_COLUMN_KEYS.VENDOR),
        FEE: overrideColumns.includes(FEE_MGMT_COLS_COLUMN_KEYS.FEE),
        DUE_DATE: overrideColumns.includes(FEE_MGMT_COLS_COLUMN_KEYS.DUE_DATE),
      }

      const rowLocationMap = new Map()
      createProductGroups(rows, availableColumns, productGroups, rowLocationMap)
      markDuplicateRows(productGroups, markedDuplicateRows, duplicateRowsMap)

      const processedRows = rows.map((row) => {
        const productName = row[FEE_MGMT_COLS_COLUMN_KEYS.PRODUCT_NAME] || ''
        const vendor = availableColumns.VENDOR ? row[FEE_MGMT_COLS_COLUMN_KEYS.VENDOR] : null
        const { states, counties, zipCodes } = rowLocationMap.get(row.rowNumber)

        // Create validation state
        const validationState = {
          isDuplicateRow: markedDuplicateRows.has(row.rowNumber),
          userMarkedForDelete: row.userMarkedForDelete || false,
          validationErrors: {},
        }

        // Add separate property for duplicate information if it's a duplicate row
        if (validationState.isDuplicateRow) {
          summary.errorRows[FeeValidationErrorTypes.DUPLICATE_ROW].add(row.rowNumber)
          const originalRowNumber = duplicateRowsMap.get(row.rowNumber)

          // Add a dedicated property for duplicate information
          validationState.duplicateOf = {
            rowNumber: originalRowNumber,
            message: `Duplicate of row ${originalRowNumber}`,
          }

          return { validationState, row, states, counties, zipCodes, productName }
        }

        validateMissingValues({ ...row, states, counties, zipCodes }, validationState, summary, availableColumns)

        // Add the new location validation
        validateLocations(
          { ...row, states, counties, zipCodes },
          validationState,
          summary,
          new Set([...requiredColumns, ...overrideColumns]),
        )

        // Now only validates Fee and Due Date numeric formats
        validateFormats(
          { ...row, states, counties, zipCodes },
          validationState,
          summary,
          new Set([...requiredColumns, ...overrideColumns]),
        )

        trackLocations(
          { validationState, row, states, counties, zipCodes, vendor, productName },
          { stateKeyToRows, countyKeyToRows, zipKeyToRows },
        )

        trackVendors(validationState, vendor, productName, vendorKeyToRows, availableColumns)

        // Add entity validation
        if (mappingData) {
          validateEntities(row, validationState, summary, overrideType, mappingData)
        }

        return { validationState, row, states, counties, zipCodes, productName }
      })

      checkDuplicateLocations(stateKeyToRows, countyKeyToRows, zipKeyToRows, summary)
      checkDuplicateVendor(vendorKeyToRows, summary)

      const finalRows = processedRows.map(({ validationState, row, states, counties, zipCodes }) => {
        const mappedRow = {
          rowNumber: row.rowNumber,
        }

        // Excludes any extra columns from a file upload
        Object.values(FEE_MGMT_COLUMNS).forEach((column) => {
          if (column.columnKey in row) {
            mappedRow[column.columnKey] = row[column.columnKey]
          }
        })

        const result = {
          ...mappedRow,
          isDuplicateRow: validationState.isDuplicateRow,
          userMarkedForDelete: validationState.userMarkedForDelete,
          validationErrors: validationState.validationErrors,
        }

        // Add the duplicateOf property if it exists
        if (validationState.duplicateOf) {
          result.duplicateOf = validationState.duplicateOf
        }

        const rowKey = createRowKey({ ...row, states, counties, zipCodes }, availableColumns)
        const cacheKey = createCacheKey(row, rowKey)
        validationCache.set(cacheKey, result)

        return result
      })

      Object.keys(summary.errorRows).forEach((errorType) => {
        summary.errorCounts[errorType] = summary.errorRows[errorType].size
      })

      summary.totalErrorCount = Object.values(summary.errorCounts).reduce((a, b) => a + b, 0)
      summary.hasErrors = summary.totalErrorCount > 0

      return { rows: finalRows, summary }
    },
    [validationCache, getRequiredColumns],
  )

  return {
    validateRows,
    validateHeaders,
    getRequiredColumns,
  }
}
