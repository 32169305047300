import React from 'react'
import moment from 'moment'
import { Message } from 'react-chat-ui'
import { Tooltip, Avatar } from 'antd'
import Rephrase from '@common/utils/Rephrase'
import { USER_TYPES } from '@common/constants/userTypes'
import { checkCanViewAppraiserNameOnOrder, utcToLocal, checkPermission } from '@common/utils/helpers'

export const getConversationTooltips = (conversation, orderData) => {
  const tooltips = []
  if (!conversation || !conversation.users) {
    return tooltips
  }
  let lender_users = []
  let amp_users = []
  const users = conversation.users.filter(u => u.type === USER_TYPES.APPRAISER)
  if (conversation.type === 'unified_conversation') {
    lender_users = conversation.users.filter(u => u.type === USER_TYPES.LENDER)
    amp_users = conversation.users.filter(u => u.type === USER_TYPES.AMC)
  } else {
    lender_users = conversation.users.filter(u => u.type === USER_TYPES.LENDER || u.type === USER_TYPES.AMC)
  }
  const custom_users = conversation.users.filter(u => u.type === USER_TYPES.CUSTOM)

  if (checkCanViewAppraiserNameOnOrder(orderData)) {
    users.forEach((user) => {
      tooltips.push(
        <Tooltip
          key={user.id}
          title={<span>{`${user.firstname || ''} ${user.lastname ||
                        ''}`} <Rephrase>(Appraisal Firm)</Rephrase></span>}
        >
          <Avatar className="appraiser-chat-avatar">
            {user.firstname ? user.firstname[0] : ''}
            {user.lastname ? user.lastname[0] : ''}
          </Avatar>
        </Tooltip>,
      )
    })
  }

  custom_users.forEach((user) => {
    tooltips.push(
      <Tooltip
        key={user.id}
        title={`${user.firstname || ''} ${user.lastname || ''}`}
      >
        <Avatar className="appraiser-chat-avatar">
          {user.firstname ? user.firstname[0] : ''}
          {user.lastname ? user.lastname[0] : ''}
        </Avatar>
      </Tooltip>,
    )
  })

  lender_users.forEach((user) => {
    tooltips.push(
      <Tooltip
        key={user.id}
        title={`${user.firstname || ''} ${user.lastname || ''}`}
      >
        <Avatar>
          {user.firstname ? user.firstname[0] : ''}
          {user.lastname ? user.lastname[0] : ''}
        </Avatar>
      </Tooltip>,
    )
  })

  if (checkCanViewAppraiserNameOnOrder(orderData)) {
    amp_users.forEach((user) => {
      tooltips.push(
        <Tooltip
          key={user.id}
          title={`${user.firstname || ''} ${user.lastname || ''}`}
        >
          <Avatar className="appraiser-chat-avatar">
            {user.firstname ? user.firstname[0] : ''}
            {user.lastname ? user.lastname[0] : ''}
          </Avatar>
        </Tooltip>,
      )
    })
  }

  return tooltips
}

export const prepConversationMessages = (
  sourceMessages,
  conversationId,
  currentUserID,
  currentUserType,
  isPastAppraiserConversation,
  orderData,
  handleApproveMessage = null,
  userCanApproveMessage = false,
) => {
  const messages = []
  const revisions = []
  const canViewApprovedBy = checkPermission('view_message_approver') && currentUserType === USER_TYPES.LENDER

  // Sort messages by sent_time (conversation) or created_date (vendor messages)
  const sortedMessages = (sourceMessages || []).slice().sort((a, b) => {
    const aDate = a.sent_time || a.created_date
    const bDate = b.sent_time || b.created_date
    if (aDate && bDate) {
      return new Date(aDate) - new Date(bDate)
    }
    return 0
  })

  if (sortedMessages) {
    for (const msg of sortedMessages) {
      const user_is_current_user = msg.sender.id === currentUserID
      const senderName = user_is_current_user ? 'You' : msg.sender.firstname + ' ' + msg.sender.lastname
      const sender_type = msg.sender.type || msg.sender_type
      if (msg.is_revision) {
        revisions.push(
          <div className="revision">
            {checkCanViewAppraiserNameOnOrder(orderData) ? (
              <span>
                  Revision from{' '}
                {msg.sender.firstname +
                    ' ' +
                    msg.sender.lastname +
                    ' - ' +
                    utcToLocal(msg.sent_time).fromNow()}
              </span>
            ) : (
              <span>Revision {utcToLocal(msg.sent_time).fromNow()}</span>
            )}
            <p>{msg.message}</p>
          </div>,
        )
      } else if (!msg.is_cancellation_request) {
        // Add this message to the regular chat messages array
        const lenderSender = sender_type.toUpperCase() === currentUserType
        messages.push(
          new Message({
            // This message id is purely to decide whether or not to move the message to the left of the right
            id: lenderSender ? 0 : msg.sender.id,
            senderName: (checkCanViewAppraiserNameOnOrder(orderData) || sender_type !== USER_TYPES.APPRAISER) ? `${senderName} - ${utcToLocal(msg.sent_time).format('MM/DD/YYYY h:mm a')} ` : utcToLocal(msg.sent_time).format('MM/DD/YYYY h:mm a'),
            message: msg.message,
          }),
        )

        const currentMessage = messages[messages.length - 1]

        currentMessage.timeago = utcToLocal(msg.sent_time).fromNow()
        currentMessage.showReadReceipts = msg.read_receipts && currentUserType === USER_TYPES.AMC
        currentMessage.readReceipts = msg.read_receipts
        currentMessage.created = msg.sent_time
        currentMessage.showUserSent = true
        currentMessage.sentByCurrentUser = user_is_current_user
        currentMessage.isTeamConversation = msg.is_team_conversation
        currentMessage.lender_sender =
            msg.sender && lenderSender ? msg.sender.id : null
        currentMessage.sender = msg.sender
        currentMessage.isPastAppraiserConversation =
            msg?.is_past_message ? msg.is_past_message : isPastAppraiserConversation
        currentMessage.visibleToInternal = msg.visible_to_internal
        currentMessage.visibleToVendor = msg.visible_to_vendor
        currentMessage.requiresApproval = msg.requires_approval
        currentMessage.approvedTimestamp = msg.approval_timestamp && ` - ${utcToLocal(
            msg.approval_timestamp,
          ).format('MM/DD/YYYY h:mm a')}`
        currentMessage.userCanApproveMessage = userCanApproveMessage
        currentMessage.conversationId = conversationId
        currentMessage.messageId = msg?.message_id
        currentMessage.handleApproveMessage = handleApproveMessage
        currentMessage.isInitialAmpAppraiserConversation = !!msg?.is_initial_amp_appraiser_conversation

        if (canViewApprovedBy && msg.approval_timestamp) {
          let approvedUserName = ''
          if (msg.approved_by?.firstname) {
            approvedUserName = msg.approved_by.firstname
          }
          if (msg.approved_by?.lastname) {
            if (approvedUserName) {
              approvedUserName = `${approvedUserName} `
            }
            approvedUserName = `${approvedUserName}${msg.approved_by.lastname}`
          }
          if (approvedUserName) {
            currentMessage.approvedBy = approvedUserName
            currentMessage.showUserApproved = true
          }
        }

        if (msg.created_date) {
          currentMessage.isVendorMessage = true
          currentMessage.timeago = utcToLocal(msg.created_date).fromNow()
          currentMessage.created = moment
            .utc(msg.created_date)
            .format('YYYY-MM-DD HH:mm:ss.SSSSSS')
          currentMessage.senderName = `${senderName} - ${utcToLocal(
              msg.created_date,
            ).format('MM/DD/YYYY h:mm a')}`
        }

        const currentMessageTime = moment(currentMessage.created)
        let hasPrecedingMessageWithSent = false

        messages.forEach((message, index) => {
          if (index === messages.length - 1) {
            // Dont do any comparison since this is the message itself
            return
          }

          // msg is the original version of the current message
          if (
            moment(currentMessageTime).diff(message.created, 'minutes') < 5 &&
              message.showUserSent &&
              currentMessage.sender.id === message.sender.id
          ) {
            // If the previous message is from the same person, is within a 5 minute time window, and
            // this message has a sent box above it, then we dont show it.
            hasPrecedingMessageWithSent = true
          }

          if (index === messages.length - 2) {
            // Looking at the message immediately above the message we are looking at.
            // If the previous message has a different sender, then
            if (currentMessage.sender.id !== message.sender.id) {
              // Dont do anything, because we definitely want the
              // This block will always be the last check because we are
              hasPrecedingMessageWithSent = false
            }
          }
        })
        currentMessage.showUserSent = !hasPrecedingMessageWithSent
      }
    }
  }

  return { messages: messages, revisions: revisions }
}
