export const selectSubmissionReviewsSlice = state => state?.submissionReviews

export const selectSubmissionReviewByVersion = (state, submissionVersion) => {
  const reviews = state?.submissionReviews?.submissionReviews.results
  let targetReview
  for (const review in reviews) {
    if (reviews[review].submission_version === submissionVersion) {
      targetReview = reviews[review]
    }
  }
  // const targetReview = reviews?.find(review => review.submission_version === submissionVersion)
  return targetReview
}

export const selectSubmissionReviewResultsByVersion = (state, submissionVersion) => {
  const reviews = state?.submissionReviews?.submissionReviews.results
  let targetReview
  for (const review in reviews) {
    if (reviews[review].submission_version === submissionVersion) {
      targetReview = reviews[review]
      break
    }
  }

  return state.submissionReviews.reviewResults?.[targetReview._id]
}

export const selectRepurchaseWarrantySubmissionByVersion = (state, submissionVersion) => {
  const repurchaseWarrantyReviews = state?.submissionReviews?.repurchaseWarrantyReviews
  const reviewId = repurchaseWarrantyReviews?.reviewIdBySubmissionVersion?.[submissionVersion]
  const targetReview = repurchaseWarrantyReviews?.results?.[reviewId]

  return targetReview
}

export const selectRepurchaseWarrantyReviewResultsByVersion = (state, submissionVersion) => {
  const repurchaseWarrantyReviews = state?.submissionReviews?.repurchaseWarrantyReviews
  const reviewId = repurchaseWarrantyReviews?.reviewIdBySubmissionVersion?.[submissionVersion]
  const targetReview = repurchaseWarrantyReviews?.results?.[reviewId]

  return state.submissionReviews.repurchaseWarrantyReviewsResults?.[targetReview?._id]
}

export const selectReviewNotesSlice = state => state?.submissionReviews?.reviewNotes

export const selectReviewNotesByKey = (state, orderId, submissionVersion) => {
  return selectReviewNotesSlice(state)?.[`${orderId}-${submissionVersion}`]
}

export const selectReviewNotesByVersion = (state, orderId, submissionVersion) => {
  return selectReviewNotesByKey(state, orderId, submissionVersion)
}

export const selectReviewNotesShownByVersion = (state, orderId, submissionVersion) => {
  return selectReviewNotesByKey(state, orderId, submissionVersion)?.showNotes
}

export const selectGlobalFields = state => state?.submissionReviews?.globalFields

export const selectRulesetsSlice = state => state?.submissionReviews?.rulesets

export const selectRulesets = state => { return selectRulesetsSlice(state)?.rulesets }
