import React, { useState, useEffect } from 'react'
import { MinimalButton, Spinner } from '@react-pdf-viewer/core'
import { NextIcon, PreviousIcon } from '@react-pdf-viewer/search'
import { Button, Input, Row } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useDebounce } from '@common/hooks/useDebounce'
import { formatDollars } from '@common/formatters/currency'
import { formatDigits } from '@common/formatters/numbers'

const SearchStatus = {
  NotSearchedYet: 'NotSearchedYet',
  Searching: 'Searching',
  FoundResults: 'FoundResults',
}

const digitsRegex = /^[+-]?\d{1,}$/
const yyyymmddRegex = /^(19|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[01])$/

const SearchSidebar = ({ searchPluginInstance, searchKeywords, searchTime }) => {
  const [searchStatus, setSearchStatus] = useState(SearchStatus.NotSearchedYet)
  const [matches, setMatches] = useState([])
  const [searchValue, setSearchValue] = useState(searchKeywords || '')
  const [currentKeywords, setCurrentKeywords] = useState('')
  const [currentMatch, setCurrentMatch] = useState(0)

  const {
    highlight,
    clearHighlights,
    jumpToMatch,
  } = searchPluginInstance

  const performSearch = useDebounce(() => {
    if (currentKeywords === '' || currentKeywords.length < 2) {
      setSearchStatus(SearchStatus.NotSearchedYet)
      clearHighlights()
      return
    }

    setSearchStatus(SearchStatus.Searching)
    highlight(currentKeywords).then((matches) => {
      setSearchStatus(SearchStatus.FoundResults)
      setMatches(matches)
      setCurrentMatch(matches.length > 0 ? 1 : 0)
    })
  }, 500)


  const queueNextSearch = (newterm) => {
    if (digitsRegex.test(newterm)) {
      const dollars = formatDollars(newterm)
      const digits = formatDigits(newterm)

      const keywords = [newterm, dollars, digits]
      setCurrentKeywords(keywords)
      return
    }

    if (yyyymmddRegex.test(newterm)) {
      const yyyymmddMatch = newterm.match(yyyymmddRegex)
      const yearPart = yyyymmddMatch[1]
      const monthPart = yyyymmddMatch[2]
      const dayPart = yyyymmddMatch[3]
      const keywords = [newterm, `${monthPart}/${dayPart}/${yearPart}`]
      setCurrentKeywords(keywords)
      return
    }

    if (newterm.length > 100) {
      const importantWords = newterm.split(' ')
      const firstTen = importantWords.slice(0, 10).join(' ')
      setCurrentKeywords(firstTen)
      return
    }

    setCurrentKeywords(newterm)
  }

  useEffect(() => {
    // Sync upstream keywords
    setSearchValue(searchKeywords || '')
  }, [searchKeywords, searchTime])

  useEffect(() => {
    // Sync keywords from search term
    queueNextSearch(searchValue || '')
  }, [searchValue])

  useEffect(() => {
    // Sync keywords
    // console.log('keywords', currentKeywords)
    performSearch()
  }, [currentKeywords, performSearch])

  const renderMatchSample = (match) => {
    //  match.startIndex    match.endIndex
    //      |                       |
    //      ▼                       ▼
    //  ....[_____props.keyword_____]....

    const wordsBefore = match.pageText.substr(match.startIndex - 20, 20)
    let words = wordsBefore.split(' ')
    words.shift()
    const begin = words.length === 0 ? wordsBefore : words.join(' ')

    const wordsAfter = match.pageText.substr(match.endIndex, 60)
    words = wordsAfter.split(' ')
    words.pop()
    const end = words.length === 0 ? wordsAfter : words.join(' ')

    return (
      <div>
        {begin}
        <span style={{ backgroundColor: 'rgb(255, 255, 0)' }}>
          {match.pageText.substring(match.startIndex, match.endIndex)}
        </span>
        {end}
      </div>
    )
  }

  const searchChanged = (val) => {
    if (val !== searchValue) {
      setSearchValue(val)
    }
  }

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      performSearch()
    }
  }

  const handleSearchClick = (e) => {
    performSearch()
  }

  const flagMatch = (match) => {
    setCurrentMatch(match)
    jumpToMatch(match)
  }

  const nextMatch = () => {
    if (currentMatch < matches.length) {
      setCurrentMatch(currentMatch + 1)
      jumpToMatch(currentMatch + 1)
    } else {
      setCurrentMatch(1)
      jumpToMatch(1)
    }
  }

  const previousMatch = () => {
    if (currentMatch > 1) {
      setCurrentMatch(currentMatch - 1)
      jumpToMatch(currentMatch - 1)
    } else {
      setCurrentMatch(matches.length)
      jumpToMatch(matches.length)
    }
  }

  return (
    <>
      <div style={{
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}>
        <Row justify='space-around' wrap={false}>
          <Input
            allowClear
            value={searchValue}
            placeholder='Search document'
            onChange={(event) => searchChanged(event.target.value)}
            onKeyDown={handleSearchKeyDown}>
          </Input>
          <Button onClick={handleSearchClick} icon={<SearchOutlined />}></Button>
        </Row>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <div style={{ padding: '.5rem' }}>
            <div style={{ position: 'relative' }}>
              {searchStatus === SearchStatus.Searching && (
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    bottom: 0,
                    position: 'absolute',
                    right: '.5rem',
                    top: 0,
                  }}
                >
                  <Spinner size="1.5rem" />
                </div>
              )}
            </div>
          </div>
          {searchStatus === SearchStatus.FoundResults && (
            <>
              {matches.length === 0 && (<> <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  padding: '.5rem',
                }}>
                    No results found
              </div>
              </>)}
              {matches.length > 0 && (
                <>
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      padding: '.5rem',
                    }}
                  >
                    <div
                      style={{
                        color: 'rgba(0, 0, 0, .5)',
                        fontSize: '.8rem',
                        marginRight: '.5rem',
                      }}
                    >Showing {currentMatch} / {matches.length} results
                    </div>
                    <div style={{ marginLeft: 'auto', marginRight: '.5rem' }}>
                      <MinimalButton onClick={previousMatch}>
                        <PreviousIcon />
                      </MinimalButton>
                    </div>
                    <MinimalButton onClick={nextMatch}>
                      <NextIcon />
                    </MinimalButton>
                  </div>
                  <div
                    style={{
                      borderTop: '1px solid rgba(0, 0, 0, .2)',
                      flex: 1,
                      overflow: 'auto',
                      padding: '.5rem 1rem',
                    }}
                  >
                    {matches.map((match, index) => (
                      <div key={index} style={{ margin: '1rem 0' }}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '.5rem',
                          }}
                        >
                          <div>#{index + 1}</div>
                          <div
                            style={{
                              color: 'rgba(0, 0, 0, .5)',
                              fontSize: '.8rem',
                              textAlign: 'right',
                            }}
                          >Page {match.pageIndex + 1}
                          </div>
                        </div>
                        <div
                          style={{
                            backgroundColor: currentMatch === index + 1 ? 'rgba(0, 0, 0, .1)' : '',
                            border: '1px solid rgba(0, 0, 0, .2)',
                            borderRadius: '.25rem',
                            cursor: 'pointer',
                            overflowWrap: 'break-word',
                            padding: '.5rem',
                          }}
                          onClick={() => flagMatch(index + 1)}
                        >
                          {renderMatchSample(match)}
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default SearchSidebar
