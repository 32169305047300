import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Card, Space, Tag, Typography, Skeleton } from 'antd'
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { OVERRIDE_TYPES } from '../constants/Validation'
import { OVERRIDE_TYPE_CONFIG } from '../constants/Breadcrumbs'
import '../styles/OverrideSummary.scss'

const { Title, Text } = Typography

const renderTypeStats = (loading, stats) => {
  return Object.entries(OVERRIDE_TYPE_CONFIG).map(([key, config]) => {
    const Icon = config.icon
    const value = stats?.byType?.[key] || 0

    return (
      <div className="type-stat" key={key}>
        {loading ? (
          <Skeleton.Button active size="small" style={{ width: 120 }} />
        ) : (
          <>
            <div className="stat-icon">
              <Icon />
            </div>
            <div className="stat-content">
              <Text>{config.title}</Text>
              <Text strong>{value}</Text>
            </div>
          </>
        )}
      </div>
    )
  })
}

const OverrideSummary = ({ overrides, loading }) => {
  const stats = {
    total: 0,
    active: 0,
    inactive: 0,
    byType: {
      [OVERRIDE_TYPES.LOCATION.value]: 0,
      [OVERRIDE_TYPES.VENDOR.value]: 0,
      [OVERRIDE_TYPES.VENDOR_LOCATION.value]: 0,
    },
  }

  // Calculate statistics
  Object.entries(overrides).forEach(([type, items]) => {
    stats.byType[type] = items.length
    stats.total += items.length
    items.forEach((item) => {
      if (item.status === 'active') {
        stats.active++
      } else {
        stats.inactive++
      }
    })
  })

  return (
    <div className="override-summary">
      <Card className="summary-card">
        <div className="summary-content">
          <div className="summary-header">
            <div className="title-section">
              <Title level={2}>Fee Management</Title>
              {loading ? (
                <Skeleton.Input active size="small" style={{ width: 300 }} />
              ) : (
                <Text className="description">
                  Manage your fee and due date overrides for specific vendors and/or locations
                </Text>
              )}
            </div>
            <Text className="total-overrides">{stats.total} Total Overrides</Text>
          </div>
          <div className="summary-details">
            <div className="status-stats">
              <Tag icon={<CheckCircleOutlined />} color="success" className="status-tag">
                {stats.active} Active
              </Tag>
              <Tag icon={<ClockCircleOutlined />} className="status-tag">
                {stats.inactive} Inactive
              </Tag>
            </div>
            <div className="type-stats">
              <Space size="large">{renderTypeStats(loading, stats)}</Space>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

OverrideSummary.propTypes = {
  overrides: PropTypes.shape({
    [OVERRIDE_TYPES.LOCATION.value]: PropTypes.array.isRequired,
    [OVERRIDE_TYPES.VENDOR.value]: PropTypes.array.isRequired,
    [OVERRIDE_TYPES.VENDOR_LOCATION.value]: PropTypes.array.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
}

OverrideSummary.defaultProps = {
  loading: false,
}

export default memo(OverrideSummary)
