import React, { useMemo, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Layout, Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import Grid from '../Grid'
import GridControlsCardContainer from '../GridControlsCardContainer'
import { FeeValidationErrorTypes } from '../constants/Validation'
import withMemoization from '../../../common/components/MemoWrapper'
import useGridColumns from '../hooks/useGridColumns'
import { useGridState } from '../hooks/useGridState'
import { useValidationPillsConfig } from '../hooks/useValidationPillsConfig'
import { useRowData } from '../hooks/useRowData'
import '../styles/Grid.scss'
import '../styles/GridContainer.scss'

const GridContainer = ({ initialRowData, onSave, overrideType, overrideName, backButton, selectedFields = [] }) => {
  const gridApiRef = useRef(null)
  const { validatedRows, validationSummary, refreshValidation } = useRowData({
    initialRowData,
    overrideType,
    selectedFields,
    gridApiRef,
  })

  // Move onGridReady from Grid.jsx to here
  const onGridReady = useCallback(
    (params) => {
      if (gridApiRef) {
        gridApiRef.current = params.api
      }
    },
    [gridApiRef],
  )

  // Handle grid-specific state
  const {
    headerDeleteActive,
    filteredRows,
    activeFilters,
    isSummaryExpanded,
    canSaveOverride,
    isSaving,
    handleToggleDelete,
    handleHeaderDeleteToggle, // eslint-disable-line no-unused-vars,
    handleFilterChanged,
    handleFilteredRowsChange,
    handleRemoveFilter,
    handleToggleExpand,
    handleValidationFilterChange,
    handleCellValueChanged,
    saveGridData,
    handleExportCSV,
  } = useGridState({
    validatedRows,
    gridApiRef,
    overrideType,
    overrideName,
    fieldsToOverride: selectedFields,
  })

  // Handle save button click
  const handleSaveClick = async () => {
    const result = await saveGridData()
    if (result.success) {
      // Pass the complete result object including sheet_id, counts, and override name
      onSave(result.result)
    }
  }

  const validationPillsConfig = useValidationPillsConfig(overrideType, selectedFields)

  // Grid context - combine both context objects into one
  const gridContext = useMemo(
    () => ({
      onToggleDelete: handleToggleDelete,
      refreshValidation: refreshValidation,
    }),
    [handleToggleDelete, refreshValidation],
  )

  // Configure grid columns
  const { columnDefs, defaultColDef } = useGridColumns({
    rowData: filteredRows,
    headerDeleteActive,
    onHeaderDeleteToggle: handleHeaderDeleteToggle,
    gridContext,
  })

  return (
    <div className="grid-manager">
      <Layout className="content-layout">
        <Grid
          rowData={filteredRows}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          context={gridContext}
          onGridReady={onGridReady}
          onCellValueChanged={handleCellValueChanged}
          onFilterChanged={handleFilterChanged}
        />
        <GridControlsCardContainer
          isExpanded={isSummaryExpanded}
          onToggleExpand={handleToggleExpand}
          rowData={filteredRows}
          validationSummary={validationSummary}
          onFilteredRowsChange={handleFilteredRowsChange}
          activeFilters={activeFilters}
          onRemoveFilter={handleRemoveFilter}
          validationConfig={validationPillsConfig}
          validationErrorTypes={FeeValidationErrorTypes}
          onValidationFilterChange={handleValidationFilterChange}
        />
      </Layout>
      <div className="save-button-row">
        <div className="download-button">
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={handleExportCSV}
            disabled={!filteredRows || filteredRows.length === 0}
          >
            Export CSV
          </Button>
        </div>
        <div className="action-buttons">
          {backButton && backButton}
          <Button
            id="save-override-button"
            type="primary"
            onClick={handleSaveClick}
            disabled={!canSaveOverride}
            loading={isSaving}
          >
            Save Override
          </Button>
        </div>
      </div>
    </div>
  )
}

GridContainer.propTypes = {
  initialRowData: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  overrideType: PropTypes.string.isRequired,
  overrideName: PropTypes.string.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
  backButton: PropTypes.element,
}

export default withMemoization(GridContainer, [
  'initialRowData',
  'overrideType',
  'overrideName',
  'selectedFields',
  'activeFilters',
])
