import React from 'react'
import { createStore } from '@react-pdf-viewer/core'

/**
 * Renders a single annotation rectangle
 */
const AnnotationRect = ({ area, scale }) => {
  // Use orange for manual annotations, red for others
  const backgroundColor = area.isManual
    ? 'rgba(212, 107, 8, 0.1)' // Orange for MANUAL status
    : 'rgba(255, 0, 0, 0.1)' // Red for other failure statuses

  return (
    <div
      style={{
        position: 'absolute',
        left: `${area.x * scale}px`,
        top: `${(area.y) * scale}px`,
        width: `${area.width * scale}px`,
        height: `${(area.height) * scale}px`,
        backgroundColor,
        pointerEvents: 'none',
      }}
    />
  )
}

/**
 * Renders all annotations for a page
 */
const AnnotationLayer = ({ pageIndex, store, scale }) => {
  const annotations = store.get('annotations')
  const pageAnnotations = annotations[pageIndex + 1] || []

  if (pageAnnotations.length === 0) {
    return null
  }

  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
      }}
    >
      {pageAnnotations.map((annotation, index) => (
        <AnnotationRect
          key={`annotation-${pageIndex}-${index}`}
          area={annotation}
          scale={scale}
        />
      ))}
    </div>
  )
}

/**
 * Creates an annotation plugin for the PDF viewer
 * @param {Object} annotations - The annotations object with page numbers as keys
 * @returns {Object} The annotation plugin instance
 */
export function createAnnotationPlugin(annotations = {}) {
  const store = createStore({
    annotations: annotations || {},
  })

  return {
    renderPageLayer: (renderPageProps) => (
      <AnnotationLayer
        pageIndex={renderPageProps.pageIndex}
        store={store}
        scale={renderPageProps.scale}
      />
    ),
  }
}
