import PropTypes from 'prop-types'

// Grid validation error types that can be used across different features

const MISSING_VALUE = 'MISSING_VALUE'
const INVALID_FORMAT = 'INVALID_FORMAT'
const DUPLICATE_ROW = 'DUPLICATE_ROW'

export const GridValidationErrorTypes = {
  MISSING_VALUE,
  INVALID_FORMAT,
  DUPLICATE_ROW,
}

// Grid validation error prop type
export const GridValidationErrorPropType = PropTypes.shape({
  errorTypes: PropTypes.instanceOf(Set).isRequired,
  message: PropTypes.string,
})

// Grid validation state prop type
export const GridValidationStatePropType = PropTypes.shape({
  isDuplicateRow: PropTypes.bool.isRequired,
  userMarkedForDelete: PropTypes.bool.isRequired,
  validationErrors: PropTypes.objectOf(GridValidationErrorPropType).isRequired,
})

// Helper functions for validation state
export const createGridValidationState = ({
  rowNumber,
  userMarkedForDelete = false,
  markedDuplicateRows = new Set(),
}) => ({
  isDuplicateRow: markedDuplicateRows.has(rowNumber),
  userMarkedForDelete: userMarkedForDelete || false,
  validationErrors: {},
})

export const createGridValidationError = ({ errorTypes = new Set(), message = '' } = {}) => ({
  errorTypes,
  message,
})
