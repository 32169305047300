import { memo } from 'react'
import isEqual from 'lodash.isequal'

/**
 * Higher-order component to memoize a functional component with deep comparison.
 * @param {React.Component} WrappedComponent - The component to wrap.
 * @param {Array<string>} keysToCompare - List of prop keys to compare using deep equality.
 */
const withMemoization = (WrappedComponent, keysToCompare = []) => {
  const arePropsEqual = (prevProps, nextProps) => {
    return keysToCompare.every((key) => isEqual(prevProps[key], nextProps[key]))
  }

  return memo(WrappedComponent, arePropsEqual)
}

export default withMemoization
