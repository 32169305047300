import { createSelector } from 'reselect'
import { checkPermission } from '../utils/helpers'

export const F_PUBLIC = 2 ** 0
export const F_PROTECTED = 2 ** 1
export const F_UNAUTHORISED = 2 ** 2
export const F_LOAN_OFFICER = 2 ** 3
export const F_LENDER_ADMIN = 2 ** 4

// administration
export const F_ADMINISTRATION = 2 ** 6
//  loan-files
export const F_LOANS = 2 ** 7
export const F_LOANS_SEND_CONSUMER_INFO = 2 ** 8
export const F_LOANS_CREATE_ORDER = 2 ** 9
export const F_LOANS_SINGLE_VIEW = 2 ** 10
// orders
export const F_ORDERS = 2 ** 11
export const F_ORDERS_CREATE_ORDER = 2 ** 12
export const F_ORDERS_SINGLE_PAGE = 2 ** 13
// appraisers
export const F_APPRAISERS = 2 ** 14
export const F_APPRAISERS_INVITE_APPRAISE = 2 ** 15
export const F_APPRAISERS_SINGLE_APPRAISER = 2 ** 16
export const F_APPRAISERS_ADD_IN_BLACKLIST = 2 ** 17
// fee
export const F_FEES = 2 ** 18
export const F_FEES_fee_add = 2 ** 19
export const F_FEES_fee_edit = 2 ** 20
export const F_FEES_fee_delete = 2 ** 21
export const F_FEES_EDIT_PERMISSIONS = 2 ** 22
export const F_FEES_MANAGEMENT = 2 ** 23

// NOTE F_CHIEF have full access to application
// should contains all flags. the value should be next exponent minus one
// NOTE the maximum exponent can be 52, because the MAX_SAFE_INTEGER is (2 ** 53)
// const F_CHIEF            = 2 ** 52 - 1

export const userLevelSelector = createSelector(
  // base permissions
  _ => checkPermission('dashboard_route') ? F_PROTECTED : F_UNAUTHORISED,
  //  loan-files
  _ => checkPermission('loans_route') ? F_LOANS : 0,
  _ => checkPermission('loans_route') ? F_LOANS_SEND_CONSUMER_INFO : 0,
  _ => checkPermission('loans_route') ? F_LOANS_CREATE_ORDER : 0,
  _ => checkPermission('singleLoan_route') ? F_LOANS_SINGLE_VIEW : 0,
  // orders
  _ => checkPermission('orders_route') ? F_ORDERS : 0,
  _ => checkPermission('order_create') ? F_ORDERS_CREATE_ORDER : 0,
  _ => checkPermission('singleOrder_route') ? F_ORDERS_SINGLE_PAGE : 0,
  // appraisers
  _ => checkPermission('appraisers_route') ? F_APPRAISERS : 0,
  _ => checkPermission('appraiser_invite') ? F_APPRAISERS_INVITE_APPRAISE : 0,
  _ => checkPermission('singleAppraiser_route') ? F_APPRAISERS_SINGLE_APPRAISER : 0,
  _ => checkPermission('appraiser_edit') ? F_APPRAISERS_ADD_IN_BLACKLIST : 0,
  // fees
  _ => checkPermission('fee_route') ? F_FEES : 0,
  _ => checkPermission('fee_add') ? F_FEES_fee_add : 0,
  _ => checkPermission('fee_edit') ? F_FEES_fee_edit : 0,
  _ => checkPermission('fee_delete') ? F_FEES_fee_delete : 0,
  _ => checkPermission('fee_edit_permission') ? F_FEES_EDIT_PERMISSIONS : 0,
  _ => checkPermission('fee_management_route') ? F_FEES_MANAGEMENT : 0,
  // administration
  _ => checkPermission('admin_settings_route') ? F_ADMINISTRATION : 0,
  // collect all user permissions
  (...args) => args.reduce((level, flag) => level | flag, F_PUBLIC)
)
