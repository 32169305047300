import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'
import FeeManagementRoutes from './FeeManagementRoutes'

const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  minHeight: 0,
  backgroundColor: '#fff',
}

const FeeMgmtLayout = (props) => {
  return (
    <Container fluid style={contentStyle}>
      <FeeManagementRoutes />
    </Container>
  )
}

FeeMgmtLayout.propTypes = {
  route: PropTypes.shape({
    routes: PropTypes.array,
  }),
}

export default FeeMgmtLayout
