import Column from '../../common/tables/Column'
import {
  renderStatusBadge,
  checkTableData,
  checkPermission,
  utcToLocal,
  getUTCTime,
  checkCanViewAppraiserNameOnOrder,
  checkTableDataWithTime,
} from '../../common/utils/helpers'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Table from '../../common/tables/Table'
import { Container, Row, Col, Badge } from 'reactstrap'
import Pagination from '../../common/widgets/Pagination'
import OrderAlerts from './OrderAlerts'
import ConsumerAlerts from './ConsumerAlerts'
import FlagContainer from './FlagContainer'
import {
  EnvironmentOutlined,
  LinkOutlined,
  LoadingOutlined,
  UserOutlined,
  DownSquareOutlined,
} from '@ant-design/icons'
import {
  Alert,
  Tag,
  Tooltip,
  TreeSelect,
  Popover,
  Avatar,
  DatePicker,
  Button,
  Select,
  Checkbox,
  Dropdown,
  Menu,
  Space,
} from 'antd'
import React, { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { COUNTER_OFFER } from '@app/common/constants/alerts'
import {
  DATE_RANGE_FILTER_TEXT,
  KEY_3_MONTHS,
  KEY_6_MONTHS,
  KEY_12_MONTHS,
  KEY_ALL_TIME,
  KEY_CUSTOM_RANGE,
} from '../../common/constants/dateFilter'
import Rephrase from '../../common/utils/Rephrase'
import OrderSearch from './OrderSearch'
import DefaultFilters from './OrderTableFilters/DefaultFilters'
import analytics from '@helpers/segment'
import { TrackingContext } from './TrackingContext'
import OrderGetNext from './OrderGetNext'
import { useDispatch, useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { fetchReviewerQueues } from '../../store/reducers/user/reviewerQueuesSlice'
import { selectCurrentUserId, selectReviewerQueues, selectUserFilterSettings } from '../../store/reducers/user/userSelectors'
import { selectOrdersUnderwriterState } from '../../store/reducers/underwriters/undewritersSelectors'
import { updateOrdersUnderwriter, updateOrdersUnderwriterState } from 'app/store/reducers/underwriters/underwritersSlice'
import { DAYS_TO_CLOSE_COLUMN_NAME, CLOSING_DATE_COLUMN_NAME } from 'app/store/reducers/underwriters/constants'

const { RangePicker } = DatePicker
const CheckableTag = Tag.CheckableTag

export default function Orders(props) {
  const dispatch = useDispatch()

  const currentUserId = useSelector(selectCurrentUserId)
  const reviewerQueues = useSelector(selectReviewerQueues)
  const filterSettings = useSelector(selectUserFilterSettings)
  const ordersUnderwriterState = useSelector(selectOrdersUnderwriterState)
  const { closingDateDaysToCloseColumn, ordersPageSize } = useFlags() || {}
  const [selectedOrderIds, setSelectedOrderIds] = useState([])

  const [trackState, setTrackState] = useState({
    searched: false,
    searchesUntilOrderClick: 0,
    pageClicksUntilOrderClick: 0.0,
  })
  useEffect(() => {
    return () => {
      setTrackState({ searched: false, searchesUntilOrderClick: 0, pageClicksUntilOrderClick: 0 })
    }
  }, [])

  useEffect(() => {
    dispatch(fetchReviewerQueues(currentUserId))
  }, [dispatch, currentUserId])

  const ableToViewPaymentIcon = checkPermission('payments_received_view')
  let togglePaymentColumnClass = ''

  if (ableToViewPaymentIcon) {
    togglePaymentColumnClass = 'view-payment-column'
  } else {
    togglePaymentColumnClass = 'hide-payment-column'
  }

  const handleUnderwriterChange = (value, orderIds) => {
    dispatch(updateOrdersUnderwriter({
      underwriterId: value,
      orderIds,
    }))
    dispatch(updateOrdersUnderwriterState({
      underwriterId: value,
      orderIds,
    }))
  }

  const orderList = []

  for (const order of props.orders.data) {
    orderList.push(order)
    if (order.follow_up_orders) {
      const totalOrders = order.follow_up_orders.length
      let count = 0
      for (const follow_up of order.follow_up_orders) {
        if (count === 0) {
          orderList.push({
            ...follow_up,
            primaryOrderId: order.id,
            is_first: true,
            is_last: order.follow_up_orders.length === 1,
          })
        } else if (count === (totalOrders - 1)) {
          orderList.push({ ...follow_up, primaryOrderId: order.id, is_last: true })
        } else {
          orderList.push({ ...follow_up, primaryOrderId: order.id })
        }
        count++
      }
    }
  }

  const customDateField = props.pipeline_column_settings?.custom_date_field

  const content = (
    <div>
      <Button
        type='text'
        onClick={ props.handleDateFilterChange }
        daterange={ KEY_3_MONTHS }
      >
        { DATE_RANGE_FILTER_TEXT[KEY_3_MONTHS] }
      </Button>
      <br />
      <Button
        type='text'
        daterange={KEY_6_MONTHS}
        onClick={props.handleDateFilterChange}
      >
        {DATE_RANGE_FILTER_TEXT[KEY_6_MONTHS]}
      </Button>
      <br />
      <Button
        type='text'
        daterange={KEY_12_MONTHS}
        onClick={props.handleDateFilterChange}
      >
        {DATE_RANGE_FILTER_TEXT[KEY_12_MONTHS]}
      </Button>
      <br />
      <Button
        type='text'
        daterange={KEY_ALL_TIME}
        onClick={props.handleDateFilterChange}
      >
        {DATE_RANGE_FILTER_TEXT[KEY_ALL_TIME]}
      </Button>
      <br />
      <Button
        type='text'
        daterange={ KEY_CUSTOM_RANGE }
        onClick={props.handleDateFilterChange}
      >
        {DATE_RANGE_FILTER_TEXT[KEY_CUSTOM_RANGE]}
      </Button>
    </div>
  )

  const generateProductFilter = (product, item) => {
    const MAX_LEN_PRODUCT_NAME = 20

    const productsList = item.products.filter(p => p).map((product, productIndex) => {
      let productDisplay = <small className='text-muted'>{product.product_name}</small>
      // We have a namespace conflict between the Single order page when there are multiple orders, and
      // the order table, so if we click on a primary from the order table, then click on a follow up tab in the single
      // order page, then click on the Orders breadcrumb, the orders namespace has weird and unexpected data.
      if (product.product_name && product.product_name.length > MAX_LEN_PRODUCT_NAME) {
        const smallName = product.product_name.substring(0, MAX_LEN_PRODUCT_NAME) + '...'
        productDisplay = <Tooltip title={product.product_name}><small className='text-muted'>{smallName}</small></Tooltip>
      }
      return <div key={productIndex}>{productDisplay}</div>
    })
    return <div>{productsList}</div>
  }

  const formatSubmissionData = (submissionData) => {
    const formatedDate = checkTableData(submissionData)
    if (formatedDate) {
      return <Tooltip title="Latest Submission Date">{formatedDate}</Tooltip>
    }
    return formatedDate
  }

  const getCustomDateFieldColumnProps = customFieldName => {
    const customField = (props.custom_los_fields || []).find(field =>
      field.mapping_field_name === customFieldName
    )
    if (!customField) {
      return { title: 'Custom Field', format: value => value }
    }
    let title = customField.field_name
    let format = value => value
    if (title === CLOSING_DATE_COLUMN_NAME && closingDateDaysToCloseColumn) {
      title = DAYS_TO_CLOSE_COLUMN_NAME
      format = value => {
        if (!value) {
          return null
        }
        const daysToClose = moment(value).startOf('day').diff(moment().startOf('day'), 'days')
        const daysText = daysToClose === 1 ? 'day' : 'days'
        return <Tag className='days-to-close' color={daysToClose < 5 ? 'red' : 'green'}>{Math.abs(daysToClose)} {daysText}</Tag>
      }
    }
    return { title, format }
  }

  const mapPipelineColumnSettingToHtml = {
    loan_officer: (
      <Column
        className="loan_officer"
        field='loan_officer'
        format={(loanOfficer, item) => {
          if (loanOfficer) {
            return <small id={item.loan_officer.id}>{loanOfficer}</small>
          } else {
            return <small><span className="badge badge-danger">No Loan Officer</span></small>
          }
        }}
        key='loan_officer'
        title={'Loan Officer'}
      />
    ),
    address: (
      <Column
        className='address'
        field="address"
        format={(address, item) => {
          const addressItems = []
          let realAddress = ''

          if (address) {
            addressItems.push(address)
          }

          if (item.city) {
            addressItems.push(item.city)
          }

          if (item.state) {
            addressItems.push(item.state)
          }

          if (addressItems.length > 0) {
            realAddress = addressItems.join(', ')
          }

          if (item.is_follow_up && item.priority !== 'Rush') {
            return <Link to={{ pathname: `/orders/${item.primaryOrderId}`, state: { followUpOrder: item.id, loading: true } }}
              id={item.id}>{item.product_name}</Link>
          } else if (item.is_follow_up && item.priority === 'Rush') {
            return <div className="followup-order-rush-container">
              <div className="followup-order-rush">
                <p className="followup-rush-order-indicator">Rush</p>
              </div>
              <Link to={{
                pathname: `/orders/${item.primaryOrderId}`,
                state: { followUpOrder: item.id, loading: true },
              }} id={item.id}>{item.product_name}</Link>
            </div>
          }
          return (
            <div>
              <span className='text-nowrap'>{realAddress}</span>
              <br/>
              <small className='text-muted'>{item.client_name}</small>
            </div>)
        }}
        key='address'
        title={'Address'}
        width={'23%'}
      />
    ),
    product: (<Column
      className='product_filter'
      field='product_filter'
      filterable={props.productFilterOptions}
      format={(product, item) => {
        return (item.products && generateProductFilter(product, item))
      }}
      key='product_filter'
      onFilter={props.onFilter}
      title={'Products'}
    />),
    payment: (<Column
      className={togglePaymentColumnClass}
      field='consumer'
      filterable={props.paymentStatusFilterOptions}
      format={(consumer, item) => <ConsumerAlerts order={item} />}
      onFilter={props.onFilter}
      key='payment'
      title={'Payment'}
    />),
    ordered: (
      <Column
        className='ordered'
        field="created"
        format={(created, item) => checkTableData(created)}
        key='ordered'
        orderable="desc"
        title={'Ordered'}
      />
    ),
    due_date: (
      <Column
        className='due_date'
        field='lender_due_date'
        format={
          (due, item) => {
            if (item.job_type !== 'va') {
              return getUTCTime(due)
            } else {
              return 'N/A'
            }
          }
        }
        orderable="desc"
        orderingField='lender_due_date'
        key='due_date'
        title={'Due Date'}
      />
    ),
    submission_date: (
      <Column
        field="submission_date"
        format={(submissionDate, item) => formatSubmissionData(submissionDate)}
        key='submission_date'
        orderable="desc"
        title='Submission Date'
      />
    ),
    alerts: (
      <Column
        className='alerts'
        field='alert'
        format={(alert, item) => (<OrderAlerts order={item} is_amc_lender={props.is_amc_lender}/>)}
        key='alerts'
        title={'Alerts'}
        width={'7%'}
      />
    ),
    status: (
      <Column
        className='status'
        field="status"
        filterable={props.status_filter}
        format={(status, item) => status === 'Inspection Scheduled' ? (
          <Tooltip title={utcToLocal(item.inspection_date).format('MMM Do YYYY, h:mm a')}>
            <small>{renderStatusBadge(status, props.is_amc_lender, item)}</small>
          </Tooltip>
        ) : (
          <small>{renderStatusBadge(status, props.is_amc_lender, item)}</small>
        )}
        key='status'
        onFilter={props.onFilter}
        title={'Status'}
      />
    ),
    custom_date_field: (
      <Column
        className='custom_date_field'
        field={`loan_additional_data_fields.${customDateField}`}
        key='custom_date_field'
        {...getCustomDateFieldColumnProps(customDateField)}
      />
    ),
    underwriter: (
      <Column
        className='underwriter'
        field='underwriter'
        key='underwriter'
        title='Underwriter'
        format={(underwriter, item) => {
          const underwriterFromState = ordersUnderwriterState[item.id]
          let underwriterId = underwriter
          if (underwriterFromState && underwriterFromState.id) {
            underwriterId = underwriterFromState.id
          } else if (underwriterFromState && underwriterFromState.deleted) {
            underwriterId = null
          }
          return (
            <Select
              className="underwriter-select"
              value={underwriterId}
              suffixIcon={<UserOutlined />}
              onChange={value => handleUnderwriterChange(value, [item.id])}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { value: null, label: <span className="unassigned-option">Unassigned</span> },
                ...(underwriters || []).map(u => ({
                  value: u.value,
                  label: <span className="assigned-option">{u.title}</span>,
                })),
              ]}
              optionRender={(option) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>{option.label}</span>
                </div>
              )}
            />
          )
        }}
      />
    ),
    cu_scores: (
      <Column
        className='cu_score'
        cu_filter={props.cu_filter}
        cu_max={props.cu_max}
        cu_min={props.cu_min}
        cuHandler={props.cuHandler}
        field='cu_score'
        filterableRange={{ min: 1, max: 5, step: 0.1 }}
        format={(cu_score, item) => {
          if (cu_score && cu_score >= 0 && cu_score <= 5) {
            return (
              <div>
                <span key={item.id} className=''>{cu_score} out of 5<br/></span>
              </div>
            )
          } else if (cu_score) {
            return (
              <div>
                <span key={item.id} className=''>No CU Score<br/></span>
              </div>
            )
          }
          return null
        }}
        key='cu_score'
        onFilter={props.onFilter}
        title={'CU Scores'}
      />
    ),
    oldest_unapproved_message_date: (
      <Column
        className='oldest_unapproved_message_date'
        field="oldest_unapproved_message_date"
        format={(oldest_unapproved_message_date, item) => checkTableDataWithTime(oldest_unapproved_message_date)}
        key='oldest_unapproved_message_date'
        orderable="asc"
        title={'Unapproved Message Date'}
      />
    ),
  }
  const cols = [
    <Column
      className="priority"
      key='priority'
      field="priority"
      format={(priority, item) => {
        return <p className={item.priority === 'Rush' ? 'rush-order-indicator' : 'd-none'}>Rush</p>
      }}
      title=""
      width="2%"
    />,
    <Column
      className="flags flags-left-padding"
      key='flags'
      field="flags"
      format={(flags, item) => <FlagContainer key={item} updateFlagCount={props.updateFlagCount} order={item} />}
      title=""
      width="2%"
      isDashboard={true}
    />,
    <Column
      className="order_id"
      field="number"
      format={(number, item) => {
        if (!item.is_follow_up) {
          return <Link onClick={() => {
            analytics.trackComponent('Orders Single Order Clicked', {
              order_id: item.id,
              searches_until_order_click: trackState.searchesUntilOrderClick,
              page_clicks_until_order_click: trackState.pageClicksUntilOrderClick,
            })
          }} to={`/orders/${item.id}`} id={item.id}>{number || 'View'}</Link>
        } else {
          return (
            <div className='followup-container'>
              <div className='link-icon'>
                <LinkOutlined />
              </div>
              <div className='followup-order'>
                <div className='linked-order'></div>
                {item.is_last ? <div className='linked-order no-border'></div> : <div className='linked-order'></div>}
              </div>
            </div>
          )
        }
      }}
      key='order_id'
      title={'Order ID'}
    />,
  ]

  // check the lender pipeline_column_settings and add the column to cols if it is enabled
  if (props.pipeline_column_settings) {
    // Create array of enabled columns
    const enabledColumns = Object.entries(props.pipeline_column_settings)
      .filter(([_, enabled]) => enabled)
      .map(([key]) => key)

    // Sort enabled columns based on pipeline_column_order
    const orderedColumns = enabledColumns.sort((a, b) => {
      const orderA = props.pipeline_column_order.indexOf(a)
      const orderB = props.pipeline_column_order.indexOf(b)
      return orderA - orderB
    })

    // Add columns in the sorted order
    orderedColumns.forEach(key => {
      if (key === 'loan_officer') {
        if (checkPermission('user_view_loan_officer')) {
          cols.push(mapPipelineColumnSettingToHtml[key])
        }
      } else {
        cols.push(mapPipelineColumnSettingToHtml[key])
      }
    })
  }

  const unapprovedMsgFilterSetting = filterSettings?.unapproved_messages
  if (unapprovedMsgFilterSetting && props.selectedDefaultViews.includes('Unapproved Messages')) {
    cols.push(mapPipelineColumnSettingToHtml.oldest_unapproved_message_date)
  }

  /*
    Handles rendering the appropriate badges in the Appraiser Column based on status & jobtype
    Use checkCanViewAppraiserNameOnOrder helper to determine if user has permission to  view the appraiser on the order
  */
  const appraiserCol = (<Column
    className='appraiser'
    key='appraiser_column'
    field="accepted"
    format={(accepted, item) => {
      const randomAcceptedFirmId = `a${Math.random()}`.replace('.', '')
      if (item.job_type === 'va' && checkCanViewAppraiserNameOnOrder(item)) {
        if (accepted && accepted.firm_name) {
          return <small id={randomAcceptedFirmId}>{accepted.firm_name}</small>
        }
        return <small><span className="badge badge-warning">Unassigned</span></small>
      } else if (item.job_type === 'review') {
        return <small><span className="badge badge-primary">Reggora Review</span></small>
      }

      if (item.statusKey === 'waiting_for_payment' && item.status !== 'Cancelled') {
        return <small><span className="badge badge-warning">Waiting for Payment</span></small>
      } else if (item.job_type === 'amc' && !props.is_amc_lender && !props.custom_panel && checkCanViewAppraiserNameOnOrder(item)) {
        return <small><span className="badge badge-primary">Reggora Network</span></small>
      } else if (accepted && accepted.firm_name && checkCanViewAppraiserNameOnOrder(item)) {
        return (
          <Popover overlayClassName="order-appraiser-card" title={<div className="popover-card-title">
            <Avatar size={64} icon={<UserOutlined />} />
            <br />
            <div className="appraiser-card-firm-name">
              {accepted.firm_name}
            </div>
            <br />
            { accepted.firm_location ? <div className="appraiser-card-icon">
              <EnvironmentOutlined />{accepted.firm_location}
            </div> : null }
          </div>} content={<Container>
            <Row>
              <Col className="card-row">{accepted.firm_appraisers}</Col>
              <Col className="card-row">{accepted.firm_past_orders}</Col>
              <Col className="card-row card-row-last">
                {(accepted.firm_past_orders && accepted.firm_past_on_time_percentage) ? (accepted.firm_past_on_time_percentage + '%') : 'N/A'}
              </Col>
            </Row>
            <Row>
              <Col className="card-labels"><Rephrase>Appraisers</Rephrase></Col>
              <Col className="card-labels">Past Orders</Col>
              <Col className="card-labels">On Time</Col>
            </Row>
          </Container>} target={randomAcceptedFirmId}>
            <small id={randomAcceptedFirmId}>{ accepted.firm_name }</small>
          </Popover>
        )
      // Show 'No Appraiser' when LAR in finding appraisers unless we are in LAR for COUNTER_OFFER
      } else if ((item.statusKey === 'finding_appraisers' &&
        (item.lender_attention_required && item.lender_attention_required_reason !== COUNTER_OFFER) &&
        (item.job_type !== 'amc' || props.is_amc_lender)) ||
        (item.statusKey === 'finding_appraisers' && item.lender_canceled === true)) {
        return <small><span className="badge badge-danger"><Rephrase>No Appraiser</Rephrase></span></small>
      } else if (item.statusKey === 'finding_appraisers' && item.order_request_method === 'broadcast') {
        return <small><span className="badge badge-primary">Broadcast</span></small>
      } else if (item.statusKey === 'finding_appraisers' && item.order_request_method === 'cascade') {
        return <small><span className="badge badge-primary">Cascade</span></small>
      } else if (item.statusKey === 'finding_appraisers' && item.requested_appraiser && checkCanViewAppraiserNameOnOrder(item)) {
        return <small>{item.requested_appraiser.firm_name} <span className="d-block"><span className="badge badge-info">Pending</span></span></small>
      } else if (item.statusKey === 'finding_appraisers' && item.job_type === 'automatically') {
        return <small><span className="badge badge-primary"><Rephrase>Calculating Appraiser</Rephrase></span></small>
      } else if (item.statusKey === 'finding_appraisers' && item.job_type === 'amc') {
        return <small><span className="badge badge-primary"><Rephrase>Assigning Appraiser</Rephrase></span></small>
      }
    }}
    title={<Rephrase>Appraiser</Rephrase>}
  />)


  // Add the appraiserCol based on user permission
  if (checkPermission('user_view_appraiser') || checkPermission('user_view_va_appraiser')) {
    cols.splice(4, 0, appraiserCol)
  }

  props.is_amc_lender && cols.push(
    <Column
      className='lender_filter'
      field='lender_name'
      filterable={props.lenderFilterOptions}
      format={(lenderName, item) => (<small id={item.lender_id}>{lenderName}</small>)}
      key='lender_filter'
      onFilter={props.onFilter}
      title="Lender"
    />
  )

  const loanOfficers = []
  let sortedLoanOfficers = []
  if (!isEmpty(props.officer_filter)) {
    sortedLoanOfficers = props.officer_filter.sort((a, b) => (a.name > b.name) ? 1 : -1)
  }

  sortedLoanOfficers.length && sortedLoanOfficers.forEach(a => {
    const formattedLoanOfficer = {}
    formattedLoanOfficer.title = a.name
    formattedLoanOfficer.search = a.name
    formattedLoanOfficer.value = a.id
    loanOfficers.push(formattedLoanOfficer)
  })

  const underwriters = []
  const underwriterMap = {}
  let sortedUnderwriters = []
  if (!isEmpty(props.underwriter_filter)) {
    sortedUnderwriters = props.underwriter_filter.sort((a, b) => (a.name > b.name) ? 1 : -1)
  }

  sortedUnderwriters.length && sortedUnderwriters.forEach(a => {
    underwriterMap[a.id] = a
    const formattedUnderwriter = {}
    formattedUnderwriter.title = a.name
    formattedUnderwriter.search = a.name
    formattedUnderwriter.value = a.id
    underwriters.push(formattedUnderwriter)
  })

  let sortedAssignedUsers = []
  if (!isEmpty(props.assigned_filter)) {
    sortedAssignedUsers = props.assigned_filter.sort((a, b) => (a.name > b.name) ? 1 : -1)
  }

  const assignedUsers = sortedAssignedUsers.map(({ id, name }) => ({
    title: name,
    value: id,
    search: name,
  }))

  const menu = (
    <Menu>
      <Menu.Item
        key="unassign"
        onClick={() => {
          handleUnderwriterChange(null, selectedOrderIds)
          setSelectedOrderIds([])
        }}
        style={{ fontWeight: 500 }}
      >
        Unassign
      </Menu.Item>
      <Menu.SubMenu
        key="reassign"
        title="Reassign"
        popupClassName="bulk-underwriter-sub-menu"
      >
        {(underwriters || []).map(u => ({ label: u.title, key: u.value })).map(
          (item) => (
            <Menu.Item
              key={item.key}
              onClick={() => {
                handleUnderwriterChange(item.key, selectedOrderIds)
                setSelectedOrderIds([])
              }}
            >
              {item.label}
            </Menu.Item>
          )
        )}
      </Menu.SubMenu>
    </Menu>
  )

  const bulkUnderwriterChangeColumn = (
    <Column
      className='bulk-underwriter-change'
      key='bulk-underwriter-change'
      hideFollowupClasses={true}
      title={(
        <div className='bulk-underwriter-controls'>
          <Checkbox
            className='bulk-underwriter-checkbox'
            checked={selectedOrderIds.length > 0}
            onChange={({ target: { checked } }) => {
              if (checked) {
                setSelectedOrderIds(orderList.map(order => order.id))
              } else {
                setSelectedOrderIds([])
              }
            }}
          />
          <Dropdown
            overlay={menu}
            overlayStyle={{ minWidth: 150 }}
            disabled={selectedOrderIds.length === 0}
          >
            <a onClick={e => e.preventDefault()}>
              <Space>
                <DownSquareOutlined
                  className={
                    selectedOrderIds.length > 0
                      ? 'bulk-underwriter-dropdown-icon-active'
                      : 'bulk-underwriter-dropdown-icon-inactive'
                  }
                />
              </Space>
            </a>
          </Dropdown>
        </div>
      )}
      format={(bulkUnderwriterChange, item) => {
        return <Checkbox
          checked={selectedOrderIds.includes(item.id)}
          onChange={({ target: { checked } }) => {
            if (checked) {
              setSelectedOrderIds([...selectedOrderIds, item.id])
            } else {
              setSelectedOrderIds(selectedOrderIds.filter(id => id !== item.id))
            }
          }}
        />
      }}
    />
  )

  if (props.pipeline_column_settings?.underwriter) {
    cols.splice(0, 0, bulkUnderwriterChangeColumn)
  }

  const renderCombinedAppraiserFilter = () => {
    const panelistTreeData = []
    const companyMap = {}
    const companyAppraiserMapping = {}
    const resourceIdMap = {}


    const reggoraNetworkSelected = props.selectedAppraisers.filter((a) => a.value === 'amc').length > 0
    // show the reggora network if the user searches for it
    if (!reggoraNetworkSelected && props.has_amc_orders && 'reggora network'.includes(props.panelistSearchText.toLowerCase()) && !props.panelistSearch.isLoading) {
      const reggoraNetwork = {}
      reggoraNetwork.title = 'Reggora Network'
      reggoraNetwork.value = 'amc'
      reggoraNetwork.search = 'Reggora Network'
      panelistTreeData.push(reggoraNetwork)
      // adding in reggora network to resourceIdMap so we don't duplicate object in filter
      resourceIdMap[reggoraNetwork.value] = 1
    }

    if (props.panelistSearch && !props.panelistSearch.isLoading &&
      props.panelistSearch.data && props.panelistSearch.data.results) {
      props.panelistSearch.data.results.forEach(panelist => {
        const panelistDataObject = {
          title: panelist.panelist_name,
          value: panelist.resource_id,
          // used by tree select component to search. We want to display all results if the firm or panelist name matches
          search: panelist.panelist_name + ' ' + panelist.firm_name,
        }

        // add company panelist to tree data
        if (panelist.panelist_type === 'company') {
          if (!(panelist.resource_id in companyMap)) {
            panelistTreeData.push(panelistDataObject)
            companyMap[panelist.resource_id] = 1
            resourceIdMap[panelist.resource_id] = 1
          }
        // add appraiser data to company appraiser map
        } else if (panelist.panelist_type === 'appraiser') {
          if (panelist.company in companyAppraiserMapping) {
            companyAppraiserMapping[panelist.company].push(panelistDataObject)
            resourceIdMap[panelist.resource_id] = 1
          } else {
            companyAppraiserMapping[panelist.company] = [panelistDataObject]
            resourceIdMap[panelist.resource_id] = 1
          }
          // add appraiser's company to tree data if it doesn't already exist
          if (!(panelist.company in companyMap)) {
            panelistTreeData.push({
              title: panelist.firm_name,
              value: panelist.company,
              search: panelist.panelist_name + ' ' + panelist.firm_name,
            })
            companyMap[panelist.company] = 1
            resourceIdMap[panelist.company] = 1
          }
        }
      })
    }

    // iterate through company tree data and add children appraisers using our company appraiser map
    panelistTreeData.forEach(panelistTreeDataObject => {
      const companyId = panelistTreeDataObject.value
      if (companyId in companyAppraiserMapping) {
        panelistTreeDataObject.children = companyAppraiserMapping[companyId].sort(
          (a, b) => (a.title.toLowerCase() > b.title.toLowerCase()) ? 1 : -1)
      }
    })

    props.selectedAppraisers.forEach((selectedAppraiser) => {
      if (!(selectedAppraiser.value in resourceIdMap)) {
        panelistTreeData.push({
          title: selectedAppraiser.label,
          value: selectedAppraiser.value,
          // set search to panelistSearchText so that selected appraisers are displayed
          search: props.panelistSearchText,
        })
      }
    })

    return (
      <Col className='appraiser-col'>
        <TreeSelect
          disabled={props.filters.isLoading}
          showSearch
          treeCheckable={!props.panelistSearch.isLoading && !props.filters.isLoading}
          value={props.selectedAppraisers}
          placeholder={<Rephrase>Filter by Vendor</Rephrase>}
          allowClear
          labelInValue={true}
          multiple
          treeDefaultExpandAll
          treeNodeFilterProp='search'
          treeNodeLabelProp='title'
          treeData={panelistTreeData}
          searchValue={props.panelistSearchText}
          onSearch={props.handlePanelistSearch}
          onChange={props.handlePanelistChange}
          className='tree-filter'
          dropdownClassName='tree-filter-dropdown'
        />
      </Col>
    )
  }

  const pipelineViews = [
    ...(props.pipelineViews?.data || []),
    ...(props.reviewerQueueQueryBuilders?.data || []),
  ]

  const canClear = props.selectedDefaultViews.length > 0 || props.selectedPipelineViews.length > 0 ||
    props.selectedAssignedUsers.length > 0 || props.selectedLoanOfficers.length > 0 ||
    props.selectedAppraisers.length > 0

  return (
    <TrackingContext.Provider value={{ trackState: trackState, setTrackState: setTrackState }}>
      <Container fluid className="pl-0">
        <Row>
          <Col xs="12" sm="4" md="4" lg="3" xl="2" className="pl-4 pt-3 pb-5 pr-1" style={{ backgroundColor: '#f4f5f7', border: '1px solid #e7e8e8', height: '100vh', overflow: 'scroll' }}>
            <div className='top-filters'>
              <div className='filters-clear'>
                <h5 className='ml-1 mb-0'>Filters</h5>
                {canClear && <div className="clear-filters ml-1 mb-2" onClick={props.clearFilters}>Clear</div>}
              </div>
              {/* Loan Officer Selection Filter */}
              {props.pipeline_column_settings && props.pipeline_column_settings.loan_officer && (
                <Col className="loan-officer-col">
                  <TreeSelect
                    value={props.selectedLoanOfficers}
                    onChange={props.handleLoanOfficerChange}
                    treeCheckable={!props.officer_filter.isLoading}
                    treeData={loanOfficers}
                    allowClear
                    labelInValue={true}
                    multiple
                    treeDefaultExpandAll
                    treeNodeFilterProp='search'
                    treeNodeLabelProp='title'
                    disabled={props.officer_filter.isLoading}
                    placeholder={<Rephrase>Filter by Loan Officer</Rephrase>}
                    className='tree-filter'
                    dropdownClassName='tree-filter-dropdown'
                  />
                </Col>
              )}
              {props.pipeline_column_settings && props.pipeline_column_settings.underwriter && (
                <Col className="underwriter-col">
                  <TreeSelect
                    value={props.selectedUnderwriters}
                    onChange={props.handleUnderwriterChange}
                    treeCheckable={!props.underwriters.isLoading}
                    treeData={[{
                      title: 'Unassigned',
                      value: 'null',
                      search: 'Unassigned',
                    }, ...underwriters]}
                    allowClear
                    labelInValue={true}
                    multiple
                    treeDefaultExpandAll
                    treeNodeFilterProp='search'
                    treeNodeLabelProp='title'
                    disabled={props.underwriters.isLoading}
                    placeholder={<Rephrase>Filter by Underwriter</Rephrase>}
                    className='tree-filter'
                    dropdownClassName='tree-filter-dropdown'
                  />
                </Col>
              )}
              {
                checkPermission('display_assigned_filter_on_order_table') && (
                  <Col className="assigned-users-col">
                    <TreeSelect
                      value={props.selectedAssignedUsers}
                      onChange={props.handleAssignedUserChange}
                      treeCheckable={!props.assignedUsers.isLoading}
                      treeData={assignedUsers}
                      allowClear
                      labelInValue={true}
                      multiple
                      treeDefaultExpandAll
                      treeNodeFilterProp='search'
                      treeNodeLabelProp='title'
                      disabled={props.assignedUsers.isLoading}
                      placeholder={<Rephrase>Filter by Assigned Users</Rephrase>}
                      className='tree-filter'
                      dropdownClassName='tree-filter-dropdown'
                    />
                  </Col>
                )
              }
              {/* Appraiser Selection Filter */}
              {checkPermission('user_view_appraiser') && renderCombinedAppraiserFilter()}
              {props.show_default_filters && (
                <DefaultFilters
                  selectedDefaultViews={props.selectedDefaultViews}
                  handleChange={props.handleChange}
                />
              )}
            </div>
            <h6 className='ml-1 mt-4'>Custom Filters</h6>
            {pipelineViews &&
            pipelineViews.filter(
              (view) => view.user_roles.includes(props.current_user_role_id)
            )
              .map((view) => (
                <div key={view.id}>
                  <CheckableTag
                    className='mr-0'
                    key={view.id}
                    title={view.name}
                    checked={props.selectedPipelineViews.indexOf(view.id) > -1}
                    onChange={checked => props.handleViewChange(view.id, checked)}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <span className="pr-3 pipeline-view-name">{view.name}</span>
                    {!props.filters.isLoading && props.filters.data &&
                    props.filters.data.custom_views && view.name in props.filters.data.custom_views &&
                    props.filters.data.custom_views[view.name] > 0 &&
                    <p className="mb-0"><Badge pill color='danger' className=''>{props.filters.data.custom_views[view.name]}</Badge></p>}
                    {props.filters.isLoading ? <LoadingOutlined className="ml-1" /> : null}
                  </CheckableTag>
                </div>
              )
              )
            }
          </Col>
          <Col xs="12" sm="8" md="8" lg="9" xl="10">
            <Row className="">
              <Col xs="12">
                {props.getNextQueueIsEmpty &&
                  <Alert
                    message="Could not assign next order. Queue is empty."
                    type="error" showIcon closable
                    onClose={props.handleCloseGetNextQueueEmpty}
                  />
                }
              </Col>
            </Row>
            <Row className="">
              {/* Order Searchbox Filter */}
              <Col className="p-3 order-search">
                <OrderSearch { ...props } />
              </Col>

              {/* Date Range Filter */}
              {
                (props.customDateRangeDisplay === true)
                  ? (
                    <Col className='p-3'>
                      { props.orders.count } Orders:<RangePicker
                        open={true}
                        onChange={props.handleCustomDateFilterChange}
                        onOpenChange={props.onOpenChange}
                      />
                    </Col>
                  ) : (
                    <Col className='p-3'>
                      {props.orders.count} Orders:
                      <Popover
                        placement='bottomLeft'
                        content={content}
                        trigger='click'
                        onClick={props.togglePopOver}
                        visible={props.popOverState}
                        onVisibleChange={props.popOverStatus}
                      >
                        <Button
                          type='link'
                          style={{ color: '#434343', textAlign: 'left', paddingLeft: 1 }}
                        >
                          {props.dateRangeText} ▼
                        </Button>
                      </Popover>
                    </Col>
                  )
              }

              {/* Get Next Order Button */}
              {(reviewerQueues.length > 0) &&
                <Col className='p-3' style={{ textAlign: 'right' }}>
                  <OrderGetNext handleReceivedNextOrder={props.handleReceivedNextOrder} />
                </Col>
              }
            </Row>
            <Row>
              <Col className="orderListTableContainer">
                <Table
                  className="orderListTable"
                  list={
                    {
                      results: orderList,
                      filters: {
                        data: props.orders.filters,
                        orderBy: (name) => props.orders.filter({ ordering: name }),
                      },
                    }
                  }
                >
                  {cols}
                </Table>
                <Pagination
                  items={props.orders}
                  last_id
                  lastIdPerPage={props.lastIdPerPage}
                  pageName={'orders'}
                  canChangeLimit={ordersPageSize}
                  limitSelectLabel='Orders Per Page'
                  limitSelectedHandler={props.ordersLimitHandler}
                />

              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </TrackingContext.Provider>
  )
}
