import { useState, useCallback, useRef } from 'react'
import Papa from 'papaparse'
import { FEE_MGMT_COLS_BY_READABLE, FEE_MGMT_COLUMNS } from '../constants/Validation'

export const useCSVProcessor = () => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [progress, setProgress] = useState(0)
  const [timeRemaining, setTimeRemaining] = useState(null)
  const [fileName, setFileName] = useState('')
  const lastProgressUpdateTimeRef = useRef(0)

  const processCSV = useCallback(async (file) => {
    if (!file) {
      throw new Error('No file provided')
    }

    setIsProcessing(true)
    setProgress(0)
    setTimeRemaining(null)
    setFileName(file.name)
    lastProgressUpdateTimeRef.current = 0

    return new Promise((resolve, reject) => {
      const startTime = Date.now()
      const processedRows = []
      let totalBytes = 0
      let headersSet = new Set()

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        chunkSize: 1024 * 1024, // 1MB chunks
        chunk: (results) => {
          if (!headersSet.size) {
            headersSet = new Set(results.meta.fields)
          }

          // Add row numbers to the chunk and map readable names to column keys
          const rowsToAdd = results.data.map((row, index) => {
            return {
              rowNumber: processedRows.length + index + 1,
              validationErrors: {},
              userMarkedForDelete: false,
              ...Object.fromEntries(
                Object.entries(row).map(([readableKey, value]) => {
                  const columnKeyConstant = FEE_MGMT_COLS_BY_READABLE[readableKey]
                  const mappedKey = columnKeyConstant ? FEE_MGMT_COLUMNS[columnKeyConstant].columnKey : readableKey
                  return [mappedKey, value]
                }),
              ),
            }
          })

          processedRows.push(...rowsToAdd)
          totalBytes += results.data.length

          // Calculate progress and time metrics
          const progress = Math.min((totalBytes / (file.size / 50)) * 100, 100)
          const elapsedTime = (Date.now() - startTime) / 1000

          if (elapsedTime > 0) {
            const rowsPerSecond = totalBytes / elapsedTime
            const remainingRows = file.size / 50 - totalBytes
            const estimatedTimeRemaining = remainingRows / rowsPerSecond

            // Throttle progress updates to reduce re-renders
            const now = Date.now()
            if (now - lastProgressUpdateTimeRef.current > 100 || progress >= 99.9) {
              // Update every 100ms or at completion
              lastProgressUpdateTimeRef.current = now
              setProgress(progress)
              setTimeRemaining(estimatedTimeRemaining)
            }
          }
        },
        complete: () => {
          setIsProcessing(false)
          setProgress(100)
          const processingTime = ((Date.now() - startTime) / 1000).toFixed(1)
          resolve({
            rows: processedRows,
            totalRows: processedRows.length,
            fileName: file.name,
            processingTime: Number(processingTime),
            headers: headersSet,
          })
        },
        error: (error) => {
          setIsProcessing(false)
          setProgress(0)
          reject(error)
        },
      })
    })
  }, [])

  const reset = useCallback(() => {
    setIsProcessing(false)
    setProgress(0)
    setTimeRemaining(null)
    setFileName('')
  }, [])

  const formatTimeRemaining = useCallback((seconds) => {
    if (seconds < 60) {
      return `${Math.round(seconds)}s remaining`
    }
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.round(seconds % 60)
    return `${minutes}m ${remainingSeconds}s remaining`
  }, [])

  return {
    isProcessing,
    progress,
    timeRemaining,
    fileName,
    processCSV,
    reset,
    formatTimeRemaining,
    setFileName,
  }
}
