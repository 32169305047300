import React, { Fragment } from 'react'
import { ModalHeader, ModalBody, FormGroup, Col, Label, ModalFooter, Button, Badge } from 'reactstrap'
import moment from 'moment'
import Select from 'react-select'
import { RadiosField } from '../../../common/forms'

const stepOptions = [
  { label: 'Only Scheduling', value: 'calendar' },
  { label: 'Only Payment', value: 'payment' },
  { label: 'Both Scheduling and Payment', value: 'both' },
]

export default function SendConsumerModal(props) {
  const hasSent = false
  const boolValue = true
  const sentReceipts = props.sentReceipts

  return (
    <Fragment>
      <ModalHeader toggle={props.toggle}>Send Consumer {props.report ? 'Report' : 'Info'}</ModalHeader>
      <ModalBody>
        {hasSent || boolValue ? null : <FormGroup row>
          <Col xs='12'>
            <Label>Which steps should be sent to the consumers (these steps will be given to all consumers
               who receive the Scheduling App for this order, and cannot be changed)?</Label>
            <RadiosField
              name='steps'
              options={stepOptions}
              valueKey='value'
              labelKey='label'
            />
          </Col>
        </FormGroup>}
        <FormGroup row>
          <Col xs="12">
            <Label htmlFor="select">Consumer(s)</Label>
            <Select
              name='users'
              valueKey={'id'}
              labelKey={'email'}
              placeholder={'Please select'}
              options={props.consumers.filter(consumer => consumer.email && consumer.email !== '')}
              multi={true}
              value={props.selectedConsumers}
              onChange={props.handleChange}
              removeSelected={true}
              optionRenderer={option => {
                let color = 'secondary'
                let badgeText = 'Not Yet Sent'
                const sentConfirmation = sentReceipts.find(s => {
                  return s.consumer_id && (s.consumer_id === option.id)
                })

                if (sentConfirmation) {
                  color = 'success'
                  badgeText = `Sent ${moment(sentConfirmation.time).format('MMM Do, YYYY')}`
                }

                let name = ''
                let role = ''
                if (option.role) {
                  role = option.role[0].toUpperCase() + option.role.slice(1)
                }

                if (option.name) {
                  name = option.name
                } else {
                  name = option.firstname + ' ' + option.lastname
                }
                return (<span>
                  {name} | {role}
                  <Badge className='ml-1' color={color}>{badgeText}</Badge>
                </span>)
              }}
            />
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter className="border-0">
        <Button onClick={props.onHide}>Cancel</Button>
        <Button
          disabled={props.isLoading || props.selectedConsumers.length < 1}
          color='primary'
          onClick={props.onSubmit}
        >
          Send to Consumer
        </Button>
      </ModalFooter>
    </Fragment>
  )
}
