import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { GridValidationErrorTypes } from '../../common/constants/ValidationTypes'
import GridControlsCard from './GridControlsCard'
import GridControlsValidationPills from './GridValidationPills'
import ActiveFiltersSection from './ActiveFiltersSection'

const GridControlsCardContainer = ({
  isExpanded,
  onToggleExpand,
  rowData,
  validationSummary,
  onFilteredRowsChange,
  activeFilters,
  onRemoveFilter,
  validationErrorTypes = GridValidationErrorTypes,
  validationConfig,
  onValidationFilterChange,
}) => {
  const [selectedErrorTypes, setSelectedErrorTypes] = useState(new Set())
  const [flashFields, setFlashFields] = useState(new Set())
  const prevRowDataRef = useRef(null)
  const prevValidationRef = useRef(null)
  const prevCountsRef = useRef(null)
  const debouncedFilterChange = useRef(null)

  // Ensure we always have a valid validation summary
  const currentValidationSummary = useMemo(() => {
    const defaultSummary = {
      errorCounts: Object.values(validationErrorTypes).reduce((acc, type) => {
        acc[type] = 0
        return acc
      }, {}),
      errorRows: Object.values(validationErrorTypes).reduce((acc, type) => {
        acc[type] = new Set()
        return acc
      }, {}),
    }

    const summary = validationSummary
      ? {
        ...validationSummary,
        errorCounts: { ...defaultSummary.errorCounts, ...validationSummary.errorCounts },
        errorRows: { ...defaultSummary.errorRows, ...validationSummary.errorRows },
      }
      : defaultSummary

    return summary
  }, [validationSummary, validationErrorTypes])

  const onToggleErrorType = useCallback((errorType) => {
    setSelectedErrorTypes((prev) => {
      const next = new Set(prev)
      if (next.has(errorType)) {
        next.delete(errorType)
      } else {
        next.add(errorType)
      }
      return next
    })
  }, [])

  const filteredRows = useMemo(() => {
    if (!rowData || selectedErrorTypes.size === 0) {
      return rowData || []
    }

    // Create a Set of row numbers that match any selected error type
    const matchingRowNumbers = new Set()
    selectedErrorTypes.forEach((errorType) => {
      const errorRowsForType = currentValidationSummary?.errorRows?.[errorType]
      if (errorRowsForType) {
        errorRowsForType.forEach((rowNumber) => matchingRowNumbers.add(rowNumber))
      }
    })

    const filtered = rowData.filter((row) => !row.userMarkedForDelete && matchingRowNumbers.has(row.rowNumber))

    return filtered
  }, [rowData, selectedErrorTypes, currentValidationSummary])

  const rowCounts = useMemo(() => {
    if (!rowData?.length) {
      return {
        total: 0,
        valid: 0,
        invalid: 0,
        pendingDeletion: 0,
      }
    }

    // Create a Set of all error row numbers for O(1) lookup
    const errorRowsSet = new Set()
    Object.values(currentValidationSummary.errorRows).forEach((errorSet) => {
      errorSet.forEach((rowNumber) => errorRowsSet.add(rowNumber))
    })

    const counts = rowData.reduce(
      (acc, row) => {
        acc.total++
        if (row.isDuplicateRow || row.userMarkedForDelete) {
          acc.pendingDeletion++
        } else if (errorRowsSet.has(row.rowNumber)) {
          acc.invalid++
        } else {
          acc.valid++
        }
        return acc
      },
      {
        total: 0,
        valid: 0,
        invalid: 0,
        pendingDeletion: 0,
      },
    )

    return counts
  }, [rowData, currentValidationSummary])

  // Detect changes in counts and trigger flash animations
  useEffect(() => {
    // Skip if this is the initial load
    if (!prevRowDataRef.current || !prevValidationRef.current) {
      prevRowDataRef.current = rowData
      prevValidationRef.current = currentValidationSummary
      prevCountsRef.current = rowCounts
      return
    }

    // Check if this is just the initial file load
    const isInitialFileLoad = !prevRowDataRef.current.length && rowData.length > 0
    if (isInitialFileLoad) {
      prevRowDataRef.current = rowData
      prevValidationRef.current = currentValidationSummary
      prevCountsRef.current = rowCounts
      return
    }

    // This is a user action (like marking for deletion or fixing validation errors)
    const changedFields = new Set()

    // Compare with previous render's counts
    Object.entries(rowCounts).forEach(([field, value]) => {
      if (prevCountsRef.current[field] !== value) {
        changedFields.add(field)
      }
    })

    if (changedFields.size > 0) {
      setFlashFields(changedFields)
      setTimeout(() => setFlashFields(new Set()), 2000)
    }

    prevRowDataRef.current = rowData
    prevValidationRef.current = currentValidationSummary
    prevCountsRef.current = rowCounts
  }, [rowData, currentValidationSummary, rowCounts])

  // Debounced update of filtered rows
  useEffect(() => {
    if (debouncedFilterChange.current) {
      clearTimeout(debouncedFilterChange.current)
    }

    debouncedFilterChange.current = setTimeout(() => {
      onFilteredRowsChange(filteredRows)
    }, 100)

    return () => {
      if (debouncedFilterChange.current) {
        clearTimeout(debouncedFilterChange.current)
      }
    }
  }, [filteredRows, onFilteredRowsChange])

  const handleToggleErrorType = (errorType) => {
    const willBeSelected = !selectedErrorTypes.has(errorType)
    onToggleErrorType(errorType)
    onValidationFilterChange(errorType, willBeSelected)
  }

  const sections = [
    validationSummary && (
      <GridControlsValidationPills
        key="validation-summary"
        title="Validation Summary"
        validationResults={validationSummary.errorCounts}
        selectedErrorTypes={selectedErrorTypes}
        onToggleErrorType={handleToggleErrorType}
        validationConfig={validationConfig}
      />
    ),
    <ActiveFiltersSection
      key="active-filters"
      title="Active Filters"
      activeFilters={activeFilters}
      onRemoveFilter={onRemoveFilter}
    />,
  ].filter(Boolean)

  return (
    <GridControlsCard
      isExpanded={isExpanded}
      onToggleExpand={onToggleExpand}
      rowCounts={rowCounts}
      flashFields={flashFields}
      sections={sections}
    />
  )
}

GridControlsCardContainer.propTypes = {
  isExpanded: PropTypes.bool,
  onToggleExpand: PropTypes.func.isRequired,
  rowData: PropTypes.array.isRequired,
  validationSummary: PropTypes.shape({
    errorCounts: PropTypes.objectOf(PropTypes.number).isRequired,
    errorRows: PropTypes.objectOf(PropTypes.instanceOf(Set)).isRequired,
    totalErrorCount: PropTypes.number.isRequired,
    hasErrors: PropTypes.bool.isRequired,
  }),
  onFilteredRowsChange: PropTypes.func.isRequired,
  activeFilters: PropTypes.array.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  validationErrorTypes: PropTypes.object.isRequired,
  validationConfig: PropTypes.object.isRequired,
  onValidationFilterChange: PropTypes.func.isRequired,
}

export default GridControlsCardContainer
