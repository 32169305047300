/* eslint-disable no-undef */
import './polyfills' // should be first
import '../styles/index.scss'

import { combineReducers } from 'redux'
import ReduxThunk from 'redux-thunk'
import { configureStore as cS } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createEpicMiddleware, combineEpics } from 'redux-observable'
import { datadogRum } from '@datadog/browser-rum'

// Sentry
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import SentryRRWeb from '@sentry/rrweb'

import { middleware as cacheMiddleware } from './cache'
import { reducers, epics } from './store'
import { reducer as resource, epic as resourceEpic } from './common/utils/resource'
import dashboardReducer from './store/reducers/dashboard/dashboardSlice'
import conversationReducer from './store/reducers/conversation/conversationSlice'
import orderReducer from './store/reducers/order/orderSlice'
import userReducer from './store/reducers/user/reviewerQueuesSlice'
import usersReducer from './store/reducers/user/usersSlice'
import inspectionReducer from './store/reducers/inspection/inspectionSlice'
import integrationLogsReducer from './store/reducers/integrationLogs/integrationLogsSlice'
import orderFiltersReducer from './store/reducers/orderFilters/orderFiltersSlice'
import submissionReviewsReducer from './store/reducers/submissionReviews/submissionReviewsSlice'
import submissionFilesReducer from './store/reducers/submissionFiles/submissionFilesSlice'
import vendorScoreReducer from './store/reducers/vendorScore/vendorScoreSlice'
import userProfileReducer from './store/reducers/user/userProfileSlice'
import panelistReducer from './store/reducers/panelist/panelistSlice'
import lenderReducer from './store/reducers/lender/lenderSlice'
import productReducer from './store/reducers/product/productSlice'
import feeManagementReducer from './store/reducers/feeManagement/feeManagementSlice'
import underwritersReducer from './store/reducers/underwriters/underwritersSlice'
import API from './api'

const createHistory = require('history').createBrowserHistory
const history = createHistory()

const shouldUseRum = USE_RUM === 'true'
if (shouldUseRum) {
  datadogRum.init({
    applicationId: RUM_APPLICATION_ID,
    clientToken: RUM_CLIENT_TOKEN,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    defaultPrivacyLevel: 'mask-user-input',
    service: 'lender-frontend',
    env: DEPLOYMENT_ENVIRONMENT,
    version: GIT_COMMITHASH.substring(0, 8),
  })
}

const configureStore = (initialState) => {
  const middleware = [
    ReduxThunk,
    createEpicMiddleware(combineEpics(...epics, resourceEpic), {
      dependencies: { API },
    }),
    routerMiddleware(history),
    cacheMiddleware,
  ]

  // eslint-disable-next-line no-eval
  const shouldUseSentry = USE_SENTRY === 'true'
  if (shouldUseSentry) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: DEPLOYMENT_ENVIRONMENT,
      integrations: [new Integrations.BrowserTracing(), new SentryRRWeb()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0,
    })
  }

  const sentryReduxEnhancer = Sentry.createReduxEnhancer()

  const allReducers = combineReducers({
    router: connectRouter(history),
    resource,
    ...reducers,
    dashboard: dashboardReducer,
    conversation: conversationReducer,
    order: orderReducer,
    reviewerQueues: userReducer,
    inspections: inspectionReducer,
    integrationLogs: integrationLogsReducer,
    orderFilters: orderFiltersReducer,
    submissionReviews: submissionReviewsReducer,
    submissionFiles: submissionFilesReducer,
    vendorScore: vendorScoreReducer,
    userProfile: userProfileReducer,
    panelist: panelistReducer,
    users: usersReducer,
    lender: lenderReducer,
    product: productReducer,
    feeManagement: feeManagementReducer,
    underwriters: underwritersReducer,
  })

  const createStore = (initialState) => {
    return cS(
      {
        reducer: allReducers,
        preloadedState: initialState,
        middleware: middleware,
      },
      sentryReduxEnhancer,
    )
  }

  return createStore(initialState)
}

const ld_client_id = LD_CLIENT_ID

export { history, configureStore, ld_client_id }
