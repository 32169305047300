import { selectResource } from '../resource/resourceSelectors'

export const selectCurrentUser = state => selectResource(state).user.data
export const selectCurrentUserId = state => selectCurrentUser(state).id
export const selectReviewerQueues = state => state.reviewerQueues.queues
export const selectIsReviewAppraiser = state => selectCurrentUser(state).is_review_appraiser
export const selectLenderData = state => selectCurrentUser(state).lender
export const selectIsAmcLender = state => selectLenderData(state).is_amc_lender
export const selectIsUsingAmc = state => selectLenderData(state).is_using_amc
export const selectShouldShowFeeHistoryTab = state => selectCurrentUser(state).should_show_fee_history_tab

export const selectUseUpToAmount = state => selectLenderData(state).use_up_to_amount
export const selectAutoSendBorrowerAppraisal = state => selectLenderData(state).auto_send_borrower_appraisal
export const selectAutoSendBorrowerAppraisalDelay = state => selectLenderData(state).auto_send_borrower_appraisal_delay
export const selectReviewAppraisalHoursStart = state => selectLenderData(state).review_appraisal_hours_start
export const selectReviewAppraisalHoursEnd = state => selectLenderData(state).review_appraisal_hours_end
export const selectStripePaymentsEnabled = state => selectLenderData(state).stripe_payments_enabled
export const selectSendBorrowerAppraisal = state => selectLenderData(state).send_borrower_appraisal
export const selectShouldShowConsumerPaymentStyleField = (state) =>
  selectLenderData(state).should_show_consumer_payment_style_field
export const selectPendingApprovalStatusHelpText = state => selectLenderData(state).pending_approval_status_help_text
export const selectLenderId = state => selectLenderData(state).id
export const selectAppraisalDeliveryStatusFieldsTogglesSettings = (state) =>
  selectLenderData(state).appraisal_delivery_status_fields_toggles_settings

export const selectShouldOverrideSubmissionAMPVisibility = (state) =>
  selectLenderData(state).should_override_submission_amp_visibility

export const selectFetchAssignableUsersStatus = (state) => state.users.fetchAssignableUsersStatus
export const selectAssignableUsers = (state) => state.users.assignableUsers

export const selectCustomReviewRevisionRequestText =
  state => {
    return {
      custom_review_revision_request_text:
        selectLenderData(state)?.custom_review_revision_request_text,
    }
  }

export const selectPipelineColumnSettings = (state) => {
  const currentUser = selectCurrentUser(state)
  const role = currentUser?.role || {}
  const rolePipelineColumnSettings = role?.pipeline_column_settings || {}
  const lender = selectLenderData(state)
  const lenderPipelineColumnSettings = lender?.pipeline_column_settings || {}
  return {
    ...lenderPipelineColumnSettings,
    ...rolePipelineColumnSettings,
  }
}

export const selectShowDefaultFilters = (state) => {
  const currentUser = selectCurrentUser(state)
  const role = currentUser?.role || {}
  // Default to true if not set!!!
  const { show_default_filters = true } = role
  return show_default_filters
}

export const selectPipelineColumnOrder = (state) => {
  const currentUser = selectCurrentUser(state)
  const role = currentUser?.role || {}
  return role.pipeline_column_order || []
}

export const selectLDUser = (state) => {
  const currentUser = selectCurrentUser(state)
  return {
    key: currentUser?.id,
    email: currentUser?.email,
    custom: {
      type: 'LENDER',
      role: currentUser?.role?.name,
      lender: currentUser?.lender?.name,
      lender_id: currentUser?.lender?.id,
    },
  }
}

export const selectUserPermissions = state => {
  const userData = selectCurrentUser(state)
  const {
    role: {
      permissions,
    } = {},
  } = userData

  return permissions
}

export const selectHasPermission = (state, permissionName) => {
  const userPermissions = selectUserPermissions(state)

  if (!userPermissions) { return false }

  if (userPermissions[permissionName] === undefined) {
    throw Error(`Invalid permission name: ${permissionName}`)
  }

  return userPermissions[permissionName].allowed
}

export const selectUserFilterSettings = (state) => {
  const userData = selectCurrentUser(state)
  const {
    role: {
      filter_settings,
    } = {},
  } = userData

  return filter_settings
}
