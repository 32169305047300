import React, { useState, useMemo, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import CreateOverride from './CreateOverride'
import { useFeeMgmtNavigation } from '../hooks/useFeeMgmtNavigation'
import { SettingOutlined, TableOutlined, CheckCircleOutlined } from '@ant-design/icons'
import useStepper from '../hooks/useStepper'
import { logFormattedMessage } from '../../../common/utils/consoleLogging'

const OVERRIDE_STEPS = [
  {
    title: 'Configuration',
    description: 'Set up override details',
    icon: SettingOutlined,
  },
  {
    title: 'Data Review',
    description: 'Review and modify data',
    icon: TableOutlined,
  },
  {
    title: 'Summary',
    description: 'Override created successfully',
    icon: CheckCircleOutlined,
  },
]

const CreateOverrideContainer = () => {
  const { type } = useParams()
  const { currentStep, nextStep, goToStep } = useStepper(OVERRIDE_STEPS)
  const [overrideName, setOverrideName] = useState('')
  const [fileName, setFileName] = useState('')
  const [fieldsToOverride, setFieldsToOverride] = useState([])
  const [hasCompletedConfiguration, setHasCompletedConfiguration] = useState(false)
  const [hasCompletedDataReview, setHasCompletedDataReview] = useState(false)
  const [prevFileValidation, setPrevFileValidation] = useState(null)
  const [gridData, setGridData] = useState(null)
  const [saveResult, setSaveResult] = useState(null)

  const { activeSection, sectionTitle, handleBack } = useFeeMgmtNavigation()

  const handleConfigurationSubmit = useCallback(
    (values) => {
      setOverrideName(values.overrideName)
      setFieldsToOverride(values.fieldsToOverride)
      setFileName(values.fileName)
      setGridData(values.gridData)
      setHasCompletedConfiguration(true)
      setPrevFileValidation({
        overrideName: values.overrideName,
        fieldsToOverride: values.fieldsToOverride,
        fileName: values.fileName,
        gridData: values.gridData,
        fileValidation: values.fileValidation,
      })
      nextStep()
    },
    [nextStep],
  )

  // Handle successful save from GridContainer
  const handleSaveSuccess = useCallback(
    (result) => {
      logFormattedMessage('Override saved successfully', { result })

      // Combine API response data with local state data
      const completeResult = {
        ...result,
        overrideName: overrideName,
        createdAt: new Date().toISOString(),
      }

      setSaveResult(completeResult)
      setHasCompletedDataReview(true)
      nextStep()
    },
    [nextStep, overrideName],
  )

  // Track completed steps
  const completedSteps = useMemo(
    () => ({
      0: hasCompletedConfiguration,
      1: hasCompletedDataReview,
      2: false, // Summary step is never "completed" as it's the final step
    }),
    [hasCompletedConfiguration, hasCompletedDataReview],
  )

  // Memoized props
  const overrideProps = useMemo(
    () => ({
      title: sectionTitle,
      type: type || activeSection,
      onBack: handleBack,
    }),
    [sectionTitle, type, activeSection, handleBack],
  )

  const fileHandlingProps = useMemo(() => {
    return {
      fileName,
    }
  }, [fileName])

  const formProps = useMemo(
    () => ({
      handleConfigurationSubmit,
      hasCompletedConfiguration,
      overrideName,
      fieldsToOverride,
    }),
    [handleConfigurationSubmit, hasCompletedConfiguration, overrideName, fieldsToOverride],
  )

  const stepperProps = useMemo(
    () => ({
      currentStep,
      steps: OVERRIDE_STEPS,
      goToStep: (step) => {
        if (completedSteps[step] || step === currentStep) {
          goToStep(step)
        }
      },
      completedSteps: {
        0: hasCompletedConfiguration,
        1: hasCompletedDataReview,
        2: false,
      },
    }),
    [currentStep, goToStep, hasCompletedConfiguration, hasCompletedDataReview, completedSteps],
  )

  const gridProps = useMemo(() => {
    return {
      initialRowData: gridData,
      onSave: handleSaveSuccess,
      overrideType: type,
      overrideName,
      selectedFields: fieldsToOverride,
      fileValidation: prevFileValidation?.fileValidation || { missingColumns: [], extraColumns: [] },
    }
  }, [gridData, handleSaveSuccess, type, overrideName, fieldsToOverride, prevFileValidation])

  const summaryProps = useMemo(() => {
    return {
      saveResult,
      overrideType: type || activeSection,
    }
  }, [saveResult, type, activeSection])

  return (
    <CreateOverride
      overrideProps={overrideProps}
      fileHandlingProps={fileHandlingProps}
      formProps={formProps}
      stepperProps={stepperProps}
      gridProps={gridProps}
      summaryProps={summaryProps}
    />
  )
}

export default CreateOverrideContainer
