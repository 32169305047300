/**
 * Utility functions for handling PDF highlighting and annotations
 * in the ReggoraReview module. This keeps highlighting functionality
 * contained within the ReggoraReview folder.
 *
 * This functionality is controlled by the reggoraReviewPdfAnnotation feature flag.
 */

/**
 * Generates annotations from PDF parsing results and rule data
 * @param {Array} data Rule evaluation data
 * @param {Object} pdfParsingResult PDF parsing results containing field locations
 * @param {Array} globalFields Global field definitions
 * @returns {Object} Object containing annotations, field ID to page mapping, and highlighted field IDs
 */
export function generateAnnotations(data, pdfParsingResult, globalFields) {
  // Check for required data
  if (!pdfParsingResult?.result?.pdf_page_fields || !globalFields?.length) {
    return { annotations: {}, fieldIdToPage: {}, highlightedFieldIds: new Set() }
  }

  // Create mapping of field IDs to their page numbers
  const idToPage = {}

  // Get all data point field IDs from failing rules
  const failingRuleDataPoints = data
    .filter(item => ['NO_STOP', 'SOFT_STOP', 'HARD_STOP', 'MANUAL'].includes(item.fieldData.outcome))
    .flatMap(item => {
      // Add the engine_outcome to each data point for color differentiation
      const isManual = item.fieldData.outcome === 'MANUAL'
      return item.fieldData.data_points?.map(dp => ({
        ...dp,
        isManual,
        outcome: item.fieldData.outcome,
      })) || []
    })

  // Get the paths we want to highlight from global fields
  const pathsToHighlight = new Set()

  // Track which field IDs have matching paths AND exist in the PDF
  const highlightedIds = new Set()

  // Create a mapping between field paths and their corresponding data points
  const pathToDataPoints = new Map()

  // Create a map for sales comparable fields to track which data points have sequence numbers
  const salesComparableMap = {}

  // First, gather all paths and identify sales comparable data points
  failingRuleDataPoints.forEach(dp => {
    const globalField = globalFields.find(gf => gf._id === dp.field_id)

    if (globalField?.path) {
      // Check if this is a sales comparable data point
      const salesComparableMatch = dp.name?.match(/Sales Comparables #(\d+)/i)

      if (salesComparableMatch) {
        const comparableNumber = parseInt(salesComparableMatch[1], 10)

        if (!salesComparableMap[globalField.path]) {
          salesComparableMap[globalField.path] = new Map()
        }

        // Store the datapoint along with its outcome for color differentiation
        salesComparableMap[globalField.path].set(comparableNumber, {
          field_id: dp.field_id,
          isManual: dp.isManual,
        })
      } else {
        // Regular field (not sales comparable)
        pathsToHighlight.add(globalField.path)

        // Store the datapoint for this path to use later when we find matching fields
        if (!pathToDataPoints.has(globalField.path)) {
          pathToDataPoints.set(globalField.path, [])
        }
        pathToDataPoints.get(globalField.path).push(dp)
      }
    }
  })

  // Extract coordinate information from PDF parsing results
  const annotationsObject = {}

  // Sort page numbers to process them in ascending order
  const sortedPageNumbers = Object.keys(pdfParsingResult.result.pdf_page_fields)
    .map(num => parseInt(num))
    .sort((a, b) => a - b)

  // For each page in the PDF parsing results (in ascending page order)
  sortedPageNumbers.forEach(pageNumInt => {
    const pageNum = pageNumInt.toString()
    const fields = pdfParsingResult.result.pdf_page_fields[pageNum]
    const pageNumber = pageNumInt + 1 // Convert to 1-indexed page number

    // Find all fields where the field_name matches one of our paths
    fields.forEach(field => {
      // Find a matching path that is a prefix of field.field_name
      const matchingPath = Array.from(pathsToHighlight).find(path =>
        field.field_name?.startsWith(path)
      )

      if (matchingPath) {
        // Initialize page annotations if needed
        if (!annotationsObject[pageNumber]) {
          annotationsObject[pageNumber] = []
        }

        // Get data points for this field name
        const matchingDataPoints = pathToDataPoints.get(matchingPath) || []

        if (matchingDataPoints.length > 0) {
          // Only now add the field IDs to highlightedIds since we confirmed they exist in the PDF
          matchingDataPoints.forEach(dp => {
            highlightedIds.add(dp.field_id)
          })

          // Determine if any of the matching datapoints are MANUAL
          const hasManual = matchingDataPoints.some(dp => dp.isManual)

          // Add rectangles to annotations with appropriate color flag
          field.rectangles.forEach(rect => {
            annotationsObject[pageNumber].push({
              x: rect.x,
              y: rect.y,
              width: rect.width,
              height: rect.height,
              isManual: hasManual,
            })
          })

          // Store page number for each matching field ID
          matchingDataPoints.forEach(dp => {
            if (!idToPage[dp.field_id]) {
              idToPage[dp.field_id] = pageNumber
            }
          })
        }
      } else {
        // Check if this is a sales comparable field
        const matchingSalesPath = Object.keys(salesComparableMap).find(path =>
          field.field_name?.startsWith(path)
        )

        if (
          matchingSalesPath &&
          salesComparableMap[matchingSalesPath] &&
          field.sequence_number !== undefined
        ) {
          // For sales comparable fields, check if sequence number matches one of our comparable numbers
          if (salesComparableMap[matchingSalesPath].has(field.sequence_number)) {
            // Initialize page annotations if needed
            if (!annotationsObject[pageNumber]) {
              annotationsObject[pageNumber] = []
            }

            // This field has a matching sequence number - get the info
            const dpInfo = salesComparableMap[matchingSalesPath].get(field.sequence_number)

            // Add to highlighted IDs
            highlightedIds.add(dpInfo.field_id)

            // Add rectangles to annotations
            if (field.rectangles) {
              field.rectangles.forEach(rect => {
                annotationsObject[pageNumber].push({
                  x: rect.x,
                  y: rect.y,
                  width: rect.width,
                  height: rect.height,
                  isManual: dpInfo.isManual,
                })
              })
            } else if (field.x !== undefined) {
              // Some fields might not have rectangles array but direct coordinates
              annotationsObject[pageNumber].push({
                x: field.x,
                y: field.y,
                width: field.width,
                height: field.height,
                isManual: dpInfo.isManual,
              })
            }

            // Store the page number for this field ID with the specific sequence number
            if (!idToPage[dpInfo.field_id]) {
              idToPage[dpInfo.field_id] = pageNumber
            }
          }
        }
      }
    })
  })

  return {
    annotations: annotationsObject,
    fieldIdToPage: idToPage,
    highlightedFieldIds: highlightedIds,
  }
}
