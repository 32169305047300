import { useState, useCallback } from 'react'

const useStepper = (steps, initialStep = 0) => {
  const [currentStep, setCurrentStep] = useState(initialStep)

  const nextStep = useCallback(() => {
    setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1))
  }, [steps.length])

  const previousStep = useCallback(() => {
    setCurrentStep((prev) => Math.max(prev - 1, 0))
  }, [])

  const goToStep = useCallback(
    (step) => {
      setCurrentStep(Math.min(Math.max(step, 0), steps.length - 1))
    },
    [steps.length],
  )

  return {
    currentStep,
    nextStep,
    previousStep,
    goToStep,
    isFirstStep: currentStep === 0,
    isLastStep: currentStep === steps.length - 1,
  }
}

export default useStepper
