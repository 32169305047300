import React from 'react'
import { Upload, Progress, Typography, List, Tag, Spin } from 'antd'
import {
  InboxOutlined,
  FileOutlined,
  DeleteOutlined,
  WarningOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import './styles/FileUpload.scss'

const { Text } = Typography
const { Dragger } = Upload

const FileUpload = ({
  onFileUpload,
  onUploadChange,
  isProcessing,
  progress,
  displayProgress,
  showProgress,
  timeRemaining,
  formatTimeRemaining,
  disabled,
  fileName,
  fileValidation,
  showValidation,
}) => {
  const handleRemoveFile = (e) => {
    e.stopPropagation()
    onFileUpload(null, { action: 'remove' })
  }

  const uploadProps = {
    name: 'file',
    multiple: false,
    accept: '.csv',
    maxCount: 1,
    disabled,
    beforeUpload: (file) => {
      onFileUpload(file, { action: 'upload' })
      return false
    },
    showUploadList: false,
    onChange: (info) => {
      onUploadChange(info)
    },
    action: 'javascript:void(0)',
    customRequest: ({ onSuccess }) => {
      setTimeout(() => {
        onSuccess('ok', null)
      }, 0)
    },
  }

  const getUploadText = () => {
    if (fileName && !isProcessing && progress === 100 && displayProgress === 100) {
      return 'Upload Complete'
    }
    return 'Click or drag CSV file to this area to upload'
  }

  // Determine if spinner should be shown
  const shouldShowSpinner =
    isProcessing || (progress > 0 && progress < 100) || (progress === 100 && displayProgress < 100)

  const renderValidationMessage = () => {
    if (!showValidation || (!fileValidation.missingColumns.length && !fileValidation.extraColumns.length)) {
      return null
    }

    return (
      <div className="validation-messages">
        {fileValidation.missingColumns.length > 0 && (
          <div className="validation-error">
            <div className="validation-header">
              <WarningOutlined />
              <Text strong>Missing Required Columns</Text>
            </div>
            <div className="validation-content">
              <Text type="danger">Please ensure your CSV includes these columns:</Text>
              <div className="validation-tags">
                {fileValidation.missingColumns.map((column) => (
                  <Tag key={column} color="error">
                    {column}
                  </Tag>
                ))}
              </div>
            </div>
          </div>
        )}

        {fileValidation.extraColumns.length > 0 && (
          <div className="validation-warning">
            <div className="validation-header">
              <InfoCircleOutlined />
              <Text strong>Additional Columns Found</Text>
            </div>
            <div className="validation-content">
              <Text type="warning">
                Extra columns may cause data misalignment. Remove extra columns or review changes in the next step.
              </Text>
              <div className="validation-tags">
                {fileValidation.extraColumns.length > 5 ? (
                  <Text type="warning">{`${fileValidation.extraColumns.length} additional columns found`}</Text>
                ) : (
                  fileValidation.extraColumns.map((column) => (
                    <Tag key={column} color="warning">
                      {column}
                    </Tag>
                  ))
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <div className="file-upload-container">
        <div className="upload-content">
          <div className="upload-section">
            <div className="upload-main">
              <Dragger {...uploadProps} className="file-upload-dragger">
                {shouldShowSpinner ? (
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                ) : (
                  <>
                    <p className="upload-icon">
                      <InboxOutlined />
                    </p>
                    <p className="upload-text">{getUploadText()}</p>
                    {!fileName && (
                      <Text className="upload-hint">Select at least one field to override before uploading a file</Text>
                    )}
                  </>
                )}
              </Dragger>

              <div className="file-list">
                {fileName ? (
                  <List
                    itemLayout="horizontal"
                    dataSource={[{ name: fileName }]}
                    renderItem={(item) => (
                      <List.Item
                        className="file-list-item"
                        actions={[<DeleteOutlined key="delete" onClick={handleRemoveFile} className="delete-icon" />]}
                      >
                        <div className="file-item-content">
                          <div className="file-info">
                            <FileOutlined className="file-icon" />
                            <Text ellipsis className="file-name">
                              {item.name}
                            </Text>
                          </div>
                          {showProgress && (
                            <div className="progress-wrapper">
                              <Progress
                                percent={Math.round(displayProgress)}
                                size="small"
                                status={isProcessing ? 'active' : 'success'}
                              />
                            </div>
                          )}
                        </div>
                      </List.Item>
                    )}
                  />
                ) : (
                  <div className="file-list-placeholder">
                    <Text type="secondary">No file selected</Text>
                  </div>
                )}
              </div>
            </div>
            {renderValidationMessage()}
          </div>
        </div>
      </div>

      {showProgress && isProcessing && timeRemaining && (
        <div className="time-remaining">
          <Text className="time-text">{formatTimeRemaining(timeRemaining)}</Text>
        </div>
      )}
    </>
  )
}

FileUpload.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
  onUploadChange: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool,
  progress: PropTypes.number,
  displayProgress: PropTypes.number,
  showProgress: PropTypes.bool,
  timeRemaining: PropTypes.number,
  formatTimeRemaining: PropTypes.func,
  disabled: PropTypes.bool,
  fileName: PropTypes.string,
  fileValidation: PropTypes.shape({
    missingColumns: PropTypes.arrayOf(PropTypes.string),
    extraColumns: PropTypes.arrayOf(PropTypes.string),
    isValid: PropTypes.bool,
  }).isRequired,
  showValidation: PropTypes.bool,
}

FileUpload.defaultProps = {
  isProcessing: false,
  progress: 0,
  displayProgress: 0,
  showProgress: false,
  timeRemaining: null,
  formatTimeRemaining: (seconds) => `${Math.round(seconds)}s remaining`,
  fileName: '',
  showValidation: false,
}

export default FileUpload
