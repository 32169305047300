import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'
import STATUS from '../../../common/constants/reducer_statuses'

export const initialState = {
  updateOrdersStatus: STATUS.IDLE,
  fetchUnderwritersStatus: STATUS.IDLE,
  orders: {},
  underwriters: {},
}

export const updateOrdersUnderwriter = createAsyncThunk('order/updateOrdersUnderwriter', async ({ orderIds, underwriterId }, { rejectWithValue }) => {
  try {
    const assignURL = 'underwriter/assign-underwriter'
    const data = { order_ids: orderIds, underwriter_id: underwriterId }
    return await API(assignURL, API_VERSION.TWO).request(REQUEST_METHOD.PUT, {}, data)
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const fetchUnderwriters = createAsyncThunk('underwriters/fetchUnderwriters', async (_, { rejectWithValue }) => {
  try {
    const underwritersURL = 'underwriter/'
    return await API(underwritersURL, API_VERSION.TWO).request(REQUEST_METHOD.GET, {})
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const underwritersSlice = createSlice({
  name: 'underwriters',
  initialState,
  reducers: {
    updateOrdersUnderwriterState: (state, action) => {
      const { orderIds, underwriterId } = action.payload
      const ordersStateUpdate = orderIds.reduce((acc, orderId) => {
        acc[orderId] = underwriterId ? { id: underwriterId } : { deleted: true }
        return acc
      }, {})
      const newOrders = { ...state.orders, ...ordersStateUpdate }
      state.orders = newOrders
    },
  },
  extraReducers: builder => {
    builder.addCase(updateOrdersUnderwriter.pending, state => {
      state.updateOrdersStatus = STATUS.LOADING
    })
    builder.addCase(updateOrdersUnderwriter.fulfilled, (state, action) => {
      state.updateOrdersStatus = STATUS.SUCCEEDED
    })
    builder.addCase(updateOrdersUnderwriter.rejected, state => {
      state.updateOrdersStatus = STATUS.FAILED
    })
    builder.addCase(fetchUnderwriters.pending, state => {
      state.fetchUnderwritersStatus = STATUS.LOADING
    })
    builder.addCase(fetchUnderwriters.fulfilled, (state, action) => {
      state.fetchUnderwritersStatus = STATUS.SUCCEEDED
      const { underwriters } = action.payload
      state.underwriters = underwriters
    })
    builder.addCase(fetchUnderwriters.rejected, state => {
      state.fetchUnderwritersStatus = STATUS.FAILED
    })
  },
})

export default underwritersSlice.reducer

export const { updateOrdersUnderwriterState } = underwritersSlice.actions
