import React from 'react'
import { AgGridReact } from 'ag-grid-react'
import { ModuleRegistry, AllCommunityModule, ClientSideRowModelModule } from 'ag-grid-community'
import PropTypes from 'prop-types'
import { Layout } from 'antd'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import './styles/fontImport.css'

const { Content } = Layout

// Register the required modules
ModuleRegistry.registerModules([AllCommunityModule, ClientSideRowModelModule])

const Grid = ({ rowData, columnDefs, defaultColDef, context, onGridReady, onCellValueChanged, onFilterChanged }) => {
  return (
    <Content className="grid-content">
      <div
        className="ag-theme-quartz"
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
        }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          context={context}
          onGridReady={onGridReady}
          onCellValueChanged={onCellValueChanged}
          onFilterChanged={onFilterChanged}
          suppressColumnMoveAnimation={true}
          rowHeight={48}
          headerHeight={48}
          enableCellTextSelection={true}
          getRowId={(params) => String(params.data.rowNumber)}
          suppressMenuHide={false}
          alwaysMultiSort={true}
          rowClassRules={{
            'row-marked-for-delete': (params) => params.data?.userMarkedForDelete,
            'row-is-duplicate row-marked-for-delete ': (params) => params.data?.isDuplicateRow,
            'row-has-error': (params) => {
              if (!params.data?.validationErrors) {
                return false
              }

              // Check if any cell has validation errors in details only
              return Object.values(params.data.validationErrors).some((error) => {
                const hasErrorDetails = error.details && Object.keys(error.details).length > 0
                return hasErrorDetails
              })
            },
          }}
        />
      </div>
    </Content>
  )
}

Grid.propTypes = {
  rowData: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnDefs: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultColDef: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  onGridReady: PropTypes.func,
  onCellValueChanged: PropTypes.func,
  onFilterChanged: PropTypes.func,
}

export default Grid
