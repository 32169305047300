import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Tag } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { FEE_MGMT_COLS_READABLE, LOCATION_TYPES } from './constants/Validation'
const { Text } = Typography

const renderFilterValue = (filter, columnName) => {
  if (!filter) {
    return ''
  }

  if (columnName === FEE_MGMT_COLS_READABLE.DELETE) {
    return filter.value === 'marked' ? 'Marked' : 'Unmarked'
  }

  if (columnName === FEE_MGMT_COLS_READABLE.ERROR) {
    return filter.value === 'errors' ? 'Has validation errors' : 'No validation errors'
  }

  if (columnName === FEE_MGMT_COLS_READABLE.LOCATION_TYPE) {
    const locationType = Object.values(LOCATION_TYPES).find((type) => type === filter.value)
    return locationType || filter.value
  }

  if (filter.type === 'equals') {
    return `equals "${filter.filter}"`
  } else if (filter.type === 'contains') {
    return `contains "${filter.filter}"`
  } else if (filter.type === 'lessThan') {
    return `< ${filter.filter}`
  } else if (filter.type === 'greaterThan') {
    return `> ${filter.filter}`
  } else if (filter.find !== undefined) {
    // Handle FindReplaceFilter type
    return `contains "${filter.find}"`
  }
  return JSON.stringify(filter)
}

const ActiveFiltersSection = ({ title, activeFilters, onRemoveFilter }) => {
  return (
    <div title={title} id="active-filters-section">
      {!activeFilters || activeFilters.length === 0 ? (
        <Text
          style={{
            color: 'rgba(255, 255, 255, 0.6)',
            fontFamily:
              "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
            fontSize: '14px',
          }}
          className="no-filters"
        >
          No filters applied
        </Text>
      ) : (
        activeFilters.map(({ colId, columnName, filter }) => (
          <Tag
            key={colId}
            closable
            onClose={() => onRemoveFilter(colId)}
            style={{
              margin: '0 8px 8px 0',
              background: '#4c5260', // $charcoal-header
              border: '1px solid rgba(255, 255, 255, 0.18)', // $border-light
              color: 'rgba(255, 255, 255, 0.9)', // $white-text
              padding: '4px 8px',
              borderRadius: '4px',
              fontSize: '14px',
              fontFamily:
                "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
            }}
            closeIcon={
              <CloseOutlined
                style={{
                  color: 'rgba(255, 255, 255, 0.9)', // $white-text
                  fontSize: '12px',
                  opacity: 0.7,
                  transition: 'all 0.3s',
                  '&:hover': {
                    opacity: 1,
                    color: '#5c9bfa', // $accent-blue on hover
                  },
                }}
              />
            }
          >
            <span
              style={{
                marginRight: '4px',
                fontWeight: '500',
                color: '#5c9bfa', // $accent-blue
              }}
            >
              {columnName}:
            </span>
            {renderFilterValue(filter, columnName)}
          </Tag>
        ))
      )}
    </div>
  )
}

ActiveFiltersSection.propTypes = {
  title: PropTypes.string.isRequired,
  activeFilters: PropTypes.arrayOf(
    PropTypes.shape({
      colId: PropTypes.string.isRequired,
      columnName: PropTypes.string.isRequired,
      filter: PropTypes.object.isRequired,
    }),
  ),
  onRemoveFilter: PropTypes.func.isRequired,
}

export default ActiveFiltersSection
