import { GridValidationErrorTypes } from '../../../common/constants/ValidationTypes'

export const ZIP_REGEX = /^\d{5}$/
export const STATE_REGEX = /^[A-Z]{2}$/
export const COUNTY_REGEX = /^[A-Za-z\s-]+$/

const DELETE_COL = {
  readable: 'Delete',
  columnKey: 'delete',
}
const ERROR_COL = {
  readable: 'Error',
  columnKey: 'error',
}
const PRODUCT_NAME_COL = {
  readable: 'Product Name',
  columnKey: 'productName',
}
const LOCATION_TYPE_COL = {
  readable: 'Location Type',
  columnKey: 'locationType',
}
const VENDOR_COL = {
  readable: 'Vendor',
  columnKey: 'vendor',
}
const STATE_COL = {
  readable: 'States',
  columnKey: 'states',
}
const COUNTY_COL = {
  readable: 'Counties',
  columnKey: 'counties',
}
const ZIP_CODE_COL = {
  readable: 'Zip Codes',
  columnKey: 'zipCodes',
}
export const FEE_COL = {
  readable: 'Fee',
  columnKey: 'fee',
  snakeCaseKey: 'fee',
}
export const DUE_DATE_COL = {
  readable: 'Due Date',
  columnKey: 'dueDate',
  snakeCaseKey: 'due_date',
}

const DEFAULT_GRID_COLUMN_OPTIONS = {
  shouldSplitCommaValues: true,
  includeInCsvExport: true,
  suppressQuotesInCsvExport: false,
}

const DEFAULT_VALIDATION_OPTIONS = {
  existsInFile: true,
  supportsMultiValue: true,
}

export const FEE_MGMT_COLUMNS = {
  DELETE: {
    ...DELETE_COL,
    context: {
      validation: {
        existsInFile: false,
        supportsMultiValue: false,
      },
      customGridColumnOptions: {
        shouldSplitCommaValues: false,
        includeInCsvExport: false,
      },
    },
  },
  ERROR: {
    ...ERROR_COL,
    context: {
      validation: {
        existsInFile: false,
        supportsMultiValue: false,
      },
      customGridColumnOptions: {
        shouldSplitCommaValues: false,
        includeInCsvExport: false,
      },
    },
  },
  PRODUCT_NAME: {
    ...PRODUCT_NAME_COL,
    context: {
      validation: DEFAULT_VALIDATION_OPTIONS,
      customGridColumnOptions: DEFAULT_GRID_COLUMN_OPTIONS,
    },
  },
  VENDOR: {
    ...VENDOR_COL,
    context: {
      validation: {
        existsInFile: true,
        supportsMultiValue: false,
      },
      customGridColumnOptions: {
        shouldSplitCommaValues: false,
        includeInCsvExport: true,
      },
    },
  },
  LOCATION_TYPE: {
    ...LOCATION_TYPE_COL,
    context: {
      validation: {
        existsInFile: true,
        supportsMultiValue: false,
      },
      customGridColumnOptions: {
        shouldSplitCommaValues: false,
        includeInCsvExport: true,
      },
    },
  },
  STATES: {
    ...STATE_COL,
    context: {
      validation: DEFAULT_VALIDATION_OPTIONS,
      customGridColumnOptions: DEFAULT_GRID_COLUMN_OPTIONS,
    },
  },
  COUNTIES: {
    ...COUNTY_COL,
    context: {
      validation: DEFAULT_VALIDATION_OPTIONS,
      customGridColumnOptions: DEFAULT_GRID_COLUMN_OPTIONS,
    },
  },
  ZIP_CODES: {
    ...ZIP_CODE_COL,
    context: {
      validation: DEFAULT_VALIDATION_OPTIONS,
      customGridColumnOptions: DEFAULT_GRID_COLUMN_OPTIONS,
    },
  },
  FEE: {
    ...FEE_COL,
    context: {
      validation: {
        existsInFile: true,
        supportsMultiValue: false,
      },
      customGridColumnOptions: {
        shouldSplitCommaValues: false,
        includeInCsvExport: true,
        suppressQuotesInCsvExport: true,
      },
    },
  },
  DUE_DATE: {
    ...DUE_DATE_COL,
    context: {
      validation: {
        existsInFile: true,
        supportsMultiValue: false,
      },
      customGridColumnOptions: {
        shouldSplitCommaValues: false,
        includeInCsvExport: true,
        suppressQuotesInCsvExport: true,
      },
    },
  },
}

export const FEE_MGMT_COLS_READABLE = Object.keys(FEE_MGMT_COLUMNS).reduce((acc, key) => {
  acc[key] = FEE_MGMT_COLUMNS[key].readable
  return acc
}, {})

export const FEE_MGMT_COLS_COLUMN_KEYS = Object.keys(FEE_MGMT_COLUMNS).reduce((acc, key) => {
  acc[key] = FEE_MGMT_COLUMNS[key].columnKey
  return acc
}, {})

export const FEE_MGMT_COLS_BY_READABLE = Object.keys(FEE_MGMT_COLUMNS).reduce((acc, key) => {
  acc[FEE_MGMT_COLUMNS[key].readable] = key
  return acc
}, {})

export const ROW_NUMBER_COL = {
  readable: 'Row #',
  columnKey: 'rowNumber',
  context: {
    validation: {
      existsInFile: false,
      supportsMultiValue: false,
    },
    customGridColumnOptions: {
      shouldSplitCommaValues: false,
      includeInCsvExport: false,
    },
  },
}

const REQUIRED_LOCATION_COLUMNS = [
  LOCATION_TYPE_COL.columnKey,
  STATE_COL.columnKey,
  COUNTY_COL.columnKey,
  ZIP_CODE_COL.columnKey,
]

const REQUIRED_VENDOR_COLUMNS = [VENDOR_COL.columnKey]

export const REQUIRED_LOCATION_COLUMNS_READABLE = [
  LOCATION_TYPE_COL.readable,
  STATE_COL.readable,
  COUNTY_COL.readable,
  ZIP_CODE_COL.readable,
]
export const REQUIRED_VENDOR_COLUMNS_READABLE = [VENDOR_COL.readable]

export const OVERRIDE_TYPES = {
  LOCATION: { value: 'location', readable: 'Location' },
  VENDOR: { value: 'vendor', readable: 'Vendor' },
  VENDOR_LOCATION: { value: 'vendorLocation', readable: 'Vendor Location' },
}

export const REQUIRED_COLUMNS_BY_OVERRIDE_TYPE = {
  common: [PRODUCT_NAME_COL.columnKey],
  [OVERRIDE_TYPES.LOCATION.value]: REQUIRED_LOCATION_COLUMNS,
  [OVERRIDE_TYPES.VENDOR.value]: REQUIRED_VENDOR_COLUMNS,
  [OVERRIDE_TYPES.VENDOR_LOCATION.value]: [...REQUIRED_VENDOR_COLUMNS, ...REQUIRED_LOCATION_COLUMNS],
}

export const REQUIRED_COLUMNS_BY_OVERRIDE_TYPE_READABLE = {
  common: [PRODUCT_NAME_COL.readable],
  [OVERRIDE_TYPES.LOCATION.value]: REQUIRED_LOCATION_COLUMNS_READABLE,
  [OVERRIDE_TYPES.VENDOR.value]: REQUIRED_VENDOR_COLUMNS_READABLE,
  [OVERRIDE_TYPES.VENDOR_LOCATION.value]: [...REQUIRED_VENDOR_COLUMNS_READABLE, ...REQUIRED_LOCATION_COLUMNS_READABLE],
}

export const OVERRIDE_COLUMNS_READABLE = [FEE_COL.readable, DUE_DATE_COL.readable]
export const OVERRIDE_COLUMNS_BY_SNAKE_KEY = {
  [FEE_COL.snakeCaseKey]: FEE_COL,
  [DUE_DATE_COL.snakeCaseKey]: DUE_DATE_COL,
}
export const OVERRIDE_COLUMNS_KEYS = [FEE_COL.columnKey, DUE_DATE_COL.columnKey]

export const OVERRIDE_COLUMNS_READABLE_TO_COLUMN_KEY = {
  [FEE_COL.readable]: FEE_COL.columnKey,
  [DUE_DATE_COL.readable]: DUE_DATE_COL.columnKey,
}

export const LOCATION_TYPE_API_TO_READABLE = {
  zip: 'ZIP',
  county: 'COUNTY',
  multistate: 'MULTI-STATE',
}

// Generate the reverse mapping automatically
export const LOCATION_TYPE_READABLE_TO_API = Object.fromEntries(
  Object.entries(LOCATION_TYPE_API_TO_READABLE).map(([key, value]) => [value, key]),
)

export const API_OVERRIDE_TYPE_MAPPING = {
  LOCATION: ['product', 'location'],
  VENDOR: ['product', 'panelist'],
  VENDOR_LOCATION: ['product', 'location', 'panelist'],
}

const DUPLICATE_STATE = 'DUPLICATE_STATE'
const DUPLICATE_COUNTY = 'DUPLICATE_COUNTY'
const DUPLICATE_ZIP = 'DUPLICATE_ZIP'
const DUPLICATE_VENDOR = 'DUPLICATE_VENDOR'
const INVALID_FIELD_FOR_LOCATION_TYPE = 'INVALID_FIELD_FOR_LOCATION_TYPE'

// Extend grid validation types with domain-specific ones
export const FeeValidationErrorTypes = {
  ...GridValidationErrorTypes,
  INVALID_FIELD_FOR_LOCATION_TYPE,
  DUPLICATE_STATE,
  DUPLICATE_COUNTY,
  DUPLICATE_ZIP,
  DUPLICATE_VENDOR,
  INVALID_VALUE: 'INVALID_VALUE',
}

const STATE = 'MULTI-STATE'
const COUNTY = 'COUNTY'
const ZIP = 'ZIP'

export const LOCATION_TYPES = { STATE, COUNTY, ZIP }

/* eslint-disable no-template-curly-in-string */
export const ERROR_MESSAGE_TEMPLATES = {
  [FeeValidationErrorTypes.MISSING_VALUE]: {
    default: 'Required field',
  },
  [FeeValidationErrorTypes.INVALID_FORMAT]: {
    default: 'Invalid format',
    [FEE_MGMT_COLS_COLUMN_KEYS.FEE]: 'Must be a whole number',
    [FEE_MGMT_COLS_COLUMN_KEYS.DUE_DATE]: 'Must be a whole number',
  },
  [FeeValidationErrorTypes.DUPLICATE_STATE]: {
    default: "'${value}' is a duplicate state (see row(s) ${rowNumbers})",
  },
  [FeeValidationErrorTypes.DUPLICATE_COUNTY]: {
    default: "'${value}' is a duplicate county (see row(s) ${rowNumbers})",
  },
  [FeeValidationErrorTypes.DUPLICATE_ZIP]: {
    default: "'${value}' is a duplicate ZIP code (see row(s) ${rowNumbers})",
  },
  [FeeValidationErrorTypes.DUPLICATE_VENDOR]: {
    default: "'${value}' is a duplicate vendor (see row(s) ${rowNumbers})",
  },
  [FeeValidationErrorTypes.DUPLICATE_ROW]: {
    default: 'Duplicate of row ${rowNumber}',
  },
  [FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE]: {
    default: '${value}',
  },
  [FeeValidationErrorTypes.INVALID_VALUE]: {
    default: 'Invalid value',
    [FEE_MGMT_COLS_COLUMN_KEYS.PRODUCT_NAME]: 'Invalid product name',
    [FEE_MGMT_COLS_COLUMN_KEYS.VENDOR]: 'Invalid vendor',
    [FEE_MGMT_COLS_COLUMN_KEYS.COUNTIES]: 'Invalid county',
    [FEE_MGMT_COLS_COLUMN_KEYS.STATES]: 'Invalid state',
    [FEE_MGMT_COLS_COLUMN_KEYS.ZIP_CODES]: 'ZIP must be 5 digits',
    [FEE_MGMT_COLS_COLUMN_KEYS.LOCATION_TYPE]: 'Must be MULTI-STATE, COUNTY, or ZIP',
  },
}
/* eslint-enable no-template-curly-in-string */
