import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Space, Breadcrumb, Button, Dropdown, Menu } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { ArrowLeftOutlined, HomeOutlined, DownOutlined } from '@ant-design/icons'
import '../styles/FeeManagementHeader.scss'
import '../styles/OverridesShared.scss'

const { Title } = Typography

const breadCrumbItemWrapper = (crumb, showDropdown = false) => {
  return (
    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
      {crumb.icon ? <crumb.icon style={{ marginRight: 8, fontSize: 14 }} /> : <></>}
      <span style={{ lineHeight: 1 }}>{crumb.text}</span>
      {showDropdown && <DownOutlined style={{ fontSize: 12 }} />}
    </span>
  )
}

const FeeManagementHeader = ({ title, breadcrumbs = [], onBack, actions, icon: Icon }) => {
  const history = useHistory()

  const renderBreadcrumbItem = (crumb) => {
    if (crumb.dropdownItems) {
      const menu = (
        <Menu>
          {crumb.dropdownItems.map((item) => (
            <Menu.Item key={item.path} onClick={() => history.push(item.path)}>
              <Space>
                {item.icon && <item.icon />}
                <span>{item.text}</span>
              </Space>
            </Menu.Item>
          ))}
        </Menu>
      )

      return (
        <Dropdown overlay={menu} trigger={['click']}>
          <a onClick={(e) => e.preventDefault()} className="override-type-dropdown">
            {breadCrumbItemWrapper(crumb, true)}
          </a>
        </Dropdown>
      )
    }

    return crumb.path ? <Link to={crumb.path}>{breadCrumbItemWrapper(crumb)}</Link> : breadCrumbItemWrapper(crumb)
  }

  return (
    <div className="fee-management-header">
      <div className="header-main">
        <Space align="start" size={16}>
          {onBack && (
            <Button type="text" icon={<ArrowLeftOutlined />} onClick={onBack} className="view-header-back-button" />
          )}
          <div className="title-section">
            <Title level={4} className="section-title">
              Fee Management
            </Title>
            <Space align="center" size={12}>
              {Icon && <Icon className="page-icon" />}
              <Title level={2}>{title}</Title>
            </Space>
          </div>
        </Space>
        {actions && <div className="header-actions">{actions}</div>}
      </div>

      <div className="header-breadcrumbs-container">
        <Breadcrumb className="header-breadcrumbs">
          <Breadcrumb.Item>
            <Link to="/fee-management">
              {breadCrumbItemWrapper({
                icon: HomeOutlined,
                text: 'Fee Management',
              })}
            </Link>
          </Breadcrumb.Item>
          {breadcrumbs.map((crumb, index) => (
            <Breadcrumb.Item key={index}>{renderBreadcrumbItem(crumb)}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
    </div>
  )
}

FeeManagementHeader.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      path: PropTypes.string,
      icon: PropTypes.elementType,
      dropdownItems: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
          icon: PropTypes.elementType,
        }),
      ),
    }),
  ),
  onBack: PropTypes.func,
  actions: PropTypes.node,
  icon: PropTypes.elementType,
}

export default FeeManagementHeader
