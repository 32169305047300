/**
 * Maps the human readable strings we show as options for the user to the
 * keys expected by the backend
 */
export const FORM_KEYS_TO_READABLE = {
  urar: '1004',
  mc: '1004MC',
  individual_condo_form: '1073 Condo',
  ext_coop_form: '1075 Ext. Condo',
  resedential_form: '2000 Two to Four',
  ext_res_form: '2055 Ind. Ext.',
  residential_income: '71b Residential Income',
  gpar: 'GPAR',
  reo_addendum: 'REO',
  pdr: 'PDR',
}

export const READABLE_FORMS_TO_KEYS = {
  1004: 'urar',
  '1004MC': 'mc',
  GPAR: 'gpar',
  '1073 Condo': 'individual_condo_form',
  '1075 Ext. Condo': 'ext_coop_form',
  '2055 Ind. Ext.': 'ext_res_form',
  '2000 Two to Four': 'resedential_form',
  REO: 'reo_addendum',
  '71b Residential Income': 'residential_income',
  PDR: 'pdr',
}

export const RAR_SUPPORTED_KEYS = ['urar', 'individual_condo_form', 'ext_coop_form', 'ext_res_form']

export const RAW_SUPPORTED_KEYS = ['urar']
