import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import FileUpload from './FileUpload'
import { useCSVProcessor } from './hooks/useCSVProcessor'

const FileUploadContainer = ({
  onFileUpload,
  disabled,
  onUploadStart,
  fieldsToOverride,
  fileName: initialFileName,
  fileValidation: initialFileValidation,
}) => {
  const {
    isProcessing,
    progress,
    timeRemaining,
    processCSV,
    reset,
    formatTimeRemaining,
    fileName: processorFileName,
    setFileName,
  } = useCSVProcessor()
  const [displayProgress, setDisplayProgress] = useState(0)
  const [showProgress, setShowProgress] = useState(false)
  const [fileValidation, setFileValidation] = useState(
    initialFileValidation || {
      missingColumns: [],
      extraColumns: [],
      isValid: true,
    },
  )
  // Add state to control when to show validation messages
  const [showValidation, setShowValidation] = useState(
    !!initialFileValidation?.missingColumns?.length || !!initialFileValidation?.extraColumns?.length,
  )
  const animationRef = useRef(null)
  const lastProgressRef = useRef(displayProgress)
  const lastProgressUpdateTimeRef = useRef(Date.now())
  const simulatedProgressRef = useRef(null)

  // Initialize the CSV processor with the initial file name
  useEffect(() => {
    if (initialFileName && !processorFileName) {
      setFileName(initialFileName)
    }
  }, [initialFileName, processorFileName, setFileName])

  // Log progress changes to help with debugging
  useEffect(() => {
    if (progress > 0) {
      const now = Date.now()
      lastProgressUpdateTimeRef.current = now

      // Clear any simulated progress when we get a real progress update
      if (simulatedProgressRef.current) {
        clearInterval(simulatedProgressRef.current)
        simulatedProgressRef.current = null
      }

      // Only show validation when progress is 100% AND displayProgress is 100%
      if (progress !== 100) {
        // Hide validation while processing
        setShowValidation(false)
      }
    }
  }, [progress, isProcessing])

  // Simulate intermediate progress for large files when progress stalls
  useEffect(() => {
    // Only start simulating if we're processing and progress is > 0 but < 100
    if (isProcessing && progress > 0 && progress < 100) {
      // Wait a bit to see if we get a real progress update
      const stallDetectionTimeout = setTimeout(() => {
        const now = Date.now()
        const timeSinceLastUpdate = now - lastProgressUpdateTimeRef.current

        // If it's been more than 1 second since the last real progress update, start simulating
        if (timeSinceLastUpdate > 1000 && !simulatedProgressRef.current) {
          // Calculate how much progress to simulate based on current progress
          // The closer we are to 100%, the slower we should simulate
          const getSimulationIncrement = (currentProgress) => {
            if (currentProgress < 10) {
              return 0.2
            } // Faster at the beginning
            if (currentProgress < 50) {
              return 0.1
            } // Medium speed in the middle
            return 0.05 // Slower near the end
          }

          // Start a simulation interval that slowly increases progress
          simulatedProgressRef.current = setInterval(() => {
            const currentProgress = lastProgressRef.current
            const increment = getSimulationIncrement(currentProgress)

            // Don't go beyond 95% with simulation (leave room for real completion)
            if (currentProgress < 95) {
              const simulatedProgress = Math.min(95, currentProgress + increment)
              setDisplayProgress(simulatedProgress)
              lastProgressRef.current = simulatedProgress
            } else {
              // Stop simulating if we reach 95%
              clearInterval(simulatedProgressRef.current)
              simulatedProgressRef.current = null
            }
          }, 200) // Update every 200ms for smooth animation
        }
      }, 1000) // Wait 1 second before deciding progress is stalled

      return () => {
        clearTimeout(stallDetectionTimeout)
        if (simulatedProgressRef.current) {
          clearInterval(simulatedProgressRef.current)
          simulatedProgressRef.current = null
        }
      }
    }

    // If processing completes or stops, clear any simulation
    if (!isProcessing || progress >= 100) {
      if (simulatedProgressRef.current) {
        clearInterval(simulatedProgressRef.current)
        simulatedProgressRef.current = null
      }
    }
  }, [isProcessing, progress])

  // Smooth progress animation for real progress updates
  useEffect(() => {
    // Store the current display progress value in the ref
    lastProgressRef.current = displayProgress

    if (isProcessing || progress === 100) {
      setShowProgress(true)

      // Cancel any existing animation
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current)
      }

      const startTime = Date.now()
      const startProgress = lastProgressRef.current
      const targetProgress = progress

      // Calculate an appropriate duration based on the progress delta
      // For small changes (< 5%), use a shorter duration
      // For larger changes, use a longer duration to ensure smoothness
      const progressDelta = Math.abs(targetProgress - startProgress)
      let duration = 600 // Base duration of 0.6 seconds

      if (progressDelta > 20) {
        // For large jumps, use longer duration to make it smoother
        duration = 800
      } else if (progressDelta > 5) {
        // For medium jumps
        duration = 600
      }

      // If we're completing (progress = 100), always use a consistent duration
      if (progress === 100) {
        duration = 600 // Revert back to 600ms
      }

      const animate = () => {
        const currentTime = Date.now()
        const elapsed = currentTime - startTime

        if (elapsed < duration) {
          // Use easeOutQuad easing function for smoother animation
          const t = elapsed / duration
          const easeT = t * (2 - t) // easeOutQuad formula
          const nextProgress = startProgress + (targetProgress - startProgress) * easeT

          setDisplayProgress(nextProgress)
          animationRef.current = requestAnimationFrame(animate)
        } else {
          setDisplayProgress(targetProgress)
          animationRef.current = null

          // Show validation only when animation completes and we're at 100%
          if (targetProgress === 100) {
            setShowValidation(true)
          }
        }
      }

      animationRef.current = requestAnimationFrame(animate)

      // Cleanup function to cancel animation when component unmounts or effect re-runs
      return () => {
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current)
        }
      }
    }
    // We intentionally exclude displayProgress from the dependency array
    // to prevent an infinite loop, as this effect updates displayProgress.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessing, progress])

  const validateFileColumns = (headers, requiredFields) => {
    const headerSet = new Set(headers)
    const missingColumns = requiredFields.filter((field) => !headerSet.has(field))
    const extraColumns = Array.from(headers).filter((header) => !requiredFields.includes(header))

    return {
      missingColumns,
      extraColumns,
      isValid: missingColumns.length === 0,
    }
  }

  const handleFileUploadStart = async (file, metadata) => {
    if (metadata?.action === 'remove') {
      setShowProgress(false)
      setDisplayProgress(0)
      setFileValidation({ missingColumns: [], extraColumns: [], isValid: true })
      setShowValidation(false)
      reset()
      onFileUpload([], { fileName: '', headers: new Set(), totalRows: 0 })
      if (onUploadStart) {
        onUploadStart(false)
      }
      return
    }

    if (!file) {
      return
    }

    try {
      if (onUploadStart) {
        onUploadStart(true)
      }

      // Hide validation while processing
      setShowValidation(false)

      lastProgressUpdateTimeRef.current = Date.now()

      const result = await processCSV(file)

      // Validate columns
      const validation = validateFileColumns(result.headers, fieldsToOverride)
      setFileValidation(validation)

      // Always process the file, but include validation state
      onFileUpload(result.rows, {
        fileName: file.name,
        headers: result.headers,
        totalRows: result.rows.length,
        validation, // Pass validation state to parent
      })
      if (onUploadStart) {
        onUploadStart(false)
      }

      return false // Explicitly return false to prevent default upload
    } catch (error) {
      console.error('Error processing file:', error)
      setShowProgress(false)
      setDisplayProgress(0)
      setFileValidation({ missingColumns: [], extraColumns: [], isValid: true })
      setShowValidation(false)
      reset()
      if (onUploadStart) {
        onUploadStart(false)
      }
      return false // Explicitly return false to prevent default upload
    }
  }

  const handleUploadChange = (info) => {
    if (info.file.status === 'uploading') {
      setShowProgress(true)
      setShowValidation(false)
    }
  }

  return (
    <FileUpload
      onFileUpload={handleFileUploadStart}
      onUploadChange={handleUploadChange}
      isProcessing={isProcessing}
      progress={progress}
      displayProgress={displayProgress}
      showProgress={showProgress}
      timeRemaining={timeRemaining}
      formatTimeRemaining={formatTimeRemaining}
      disabled={disabled || !!processorFileName}
      fileName={processorFileName}
      fileValidation={fileValidation}
      showValidation={showValidation}
    />
  )
}

FileUploadContainer.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onUploadStart: PropTypes.func,
  fieldsToOverride: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.string,
}

export default FileUploadContainer
