import React from 'react'
import PropTypes from 'prop-types'
import { Card, Typography } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import '../styles/RowCard.scss'

const { Text } = Typography

const StatsSection = ({ statsItems }) => {
  return (
    <div className="stats-section">
      {statsItems.map((item) => {
        return (
          <div className={`stat-item ${item.className || ''}`} key={item.label}>
            <Text type="secondary">{item.label}</Text>
            {typeof item.value === 'string' ? <Text strong>{item.value}</Text> : item.value}
          </div>
        )
      })}
    </div>
  )
}

const RowCard = ({ leftContent, statsItems, rightContent, onSelect, className }) => {
  const handleClick = (e) => {
    e.stopPropagation()
    onSelect()
  }

  const cardClassName = `row-card-base ${className || ''}`

  return (
    <Card className={cardClassName} onClick={handleClick}>
      <div className="card-main-content">
        <div className="card-title-section">{leftContent}</div>

        <div className="section-divider" />

        {statsItems && <StatsSection statsItems={statsItems} />}

        {rightContent && rightContent}

        <div className="card-select-button-container">
          <RightOutlined className="select-icon" />
        </div>
      </div>
    </Card>
  )
}

RowCard.propTypes = {
  leftContent: PropTypes.node.isRequired,
  middleContent: PropTypes.node,
  rightContent: PropTypes.node,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default RowCard
