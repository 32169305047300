import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Typography, Button, Space, Result, Statistic, Switch, Tooltip, Row, Col, Card, Skeleton } from 'antd'
import { CheckOutlined, CloseOutlined, FileTextOutlined } from '@ant-design/icons'
import GridContainer from './GridContainer'
import CreateOverrideStepper from './CreateOverrideStepper'
import FeeManagementHeader from './FeeManagementHeader'
import { OVERRIDE_TYPE_CONFIG } from '../constants/Breadcrumbs'
import CreateOverrideForm from './CreateOverrideForm'
import { OVERRIDE_TYPES, OVERRIDE_COLUMNS_READABLE_TO_COLUMN_KEY } from '../constants/Validation'
import { useFeeMgmtNavigation } from '../hooks/useFeeMgmtNavigation'
import '../styles/CreateOverride.scss'
import '../styles/OverridesShared.scss'

const { Text } = Typography

const CreateOverride = ({ overrideProps, fileHandlingProps, formProps, stepperProps, gridProps, summaryProps }) => {
  const { title, type: overrideType, onBack } = overrideProps
  const { fileName } = fileHandlingProps
  const { fieldsToOverride, overrideName, handleConfigurationSubmit } = formProps
  const { currentStep, steps, goToStep, completedSteps } = stepperProps
  const typeConfig = OVERRIDE_TYPE_CONFIG[overrideType]
  const { handleViewOverride } = useFeeMgmtNavigation()

  // Add state for animation
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [displayStep, setDisplayStep] = useState(currentStep)

  // Handle step transitions
  useEffect(() => {
    if (currentStep !== displayStep) {
      setIsTransitioning(true)

      // Wait for exit animation then change content
      const timer = setTimeout(() => {
        setDisplayStep(currentStep)

        // Wait a bit then fade in new content
        const fadeInTimer = setTimeout(() => {
          setIsTransitioning(false)
        }, 50)

        return () => clearTimeout(fadeInTimer)
      }, 300) // Match this to your CSS transition time

      return () => clearTimeout(timer)
    }
  }, [currentStep, displayStep])

  // Enhanced goToStep with transition
  const handleStepTransition = (step) => {
    if (step !== currentStep) {
      setIsTransitioning(true)
      setTimeout(() => {
        goToStep(step)
      }, 50)
    }
  }

  // Configuration Step Skeleton
  const ConfigurationSkeleton = () => (
    <div className="configuration-content">
      <div className="configuration-header">
        <Skeleton active paragraph={{ rows: 0 }} />
        <Skeleton active paragraph={{ rows: 1 }} />
      </div>
      <div className="configuration-sections">
        <div className="form-section">
          <Skeleton active paragraph={{ rows: 1 }} />
          <Skeleton active paragraph={{ rows: 1 }} />
          <div style={{ marginTop: 24 }}>
            <Skeleton.Input active size="default" style={{ width: 400 }} />
          </div>
        </div>
        <div className="form-section">
          <Skeleton active paragraph={{ rows: 1 }} />
          <Skeleton.Button active size="default" style={{ width: 150, marginRight: 16 }} />
          <Skeleton.Button active size="default" style={{ width: 150 }} />
          <div style={{ marginTop: 24 }}>
            <Skeleton.Input active size="default" style={{ width: 400 }} />
          </div>
        </div>
      </div>
      <div style={{ marginTop: 'auto', paddingTop: 24, display: 'flex', justifyContent: 'flex-end' }}>
        <Skeleton.Button active size="default" style={{ width: 100, marginRight: 16 }} />
        <Skeleton.Button active size="default" style={{ width: 100 }} />
      </div>
    </div>
  )

  // Data Review Step Skeleton
  const DataReviewSkeleton = () => (
    <div className="create-override-grid-layout">
      <div className="metadata-section">
        <div className="metadata-instructions">
          <Skeleton active paragraph={{ rows: 0 }} />
          <Skeleton active paragraph={{ rows: 1 }} />
        </div>
        <div className="details-grid">
          <Skeleton.Input active size="default" style={{ width: 200, marginRight: 16 }} />
          <Skeleton.Input active size="default" style={{ width: 120 }} />
        </div>
      </div>
      <div className="grid-section" style={{ minHeight: 400 }}>
        <Skeleton active paragraph={{ rows: 10 }} />
      </div>
    </div>
  )

  // Summary Step Skeleton
  const SummarySkeleton = () => (
    <div className="summary-content">
      <Skeleton active avatar paragraph={{ rows: 3 }} />
      <Card className="summary-details" style={{ marginTop: 24 }}>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Skeleton active paragraph={{ rows: 1 }} />
          </Col>
          <Col span={12}>
            <Skeleton active paragraph={{ rows: 1 }} />
          </Col>
          <Col span={12}>
            <Skeleton active paragraph={{ rows: 1 }} />
          </Col>
          <Col span={12}>
            <Skeleton active paragraph={{ rows: 1 }} />
          </Col>
        </Row>
      </Card>
    </div>
  )

  // Get the appropriate skeleton based on step
  const getStepSkeleton = (step) => {
    switch (step) {
      case 0:
        return <ConfigurationSkeleton />
      case 1:
        return <DataReviewSkeleton />
      case 2:
        return <SummarySkeleton />
      default:
        return <ConfigurationSkeleton />
    }
  }

  const StepContent = ({ children }) => (
    <div className="step-content-layout override-card-base">
      <div className="step-sidebar">
        <CreateOverrideStepper
          vertical
          currentStep={currentStep}
          steps={steps}
          onStepClick={handleStepTransition}
          completedSteps={completedSteps}
        />
      </div>
      <div className="step-divider" />
      <div className="step-main-content">{isTransitioning ? getStepSkeleton(currentStep) : children}</div>
    </div>
  )

  const renderConfigurationStep = () => {
    return (
      <StepContent>
        <CreateOverrideForm
          overrideType={overrideType}
          initialValues={{
            overrideName: overrideName || '',
            fieldsToOverride: fieldsToOverride || [],
            fileName: fileName || '',
            gridData: gridProps.initialRowData || null,
            fileValidation: gridProps.fileValidation || { missingColumns: [], extraColumns: [] },
          }}
          onSubmit={(values) => {
            setIsTransitioning(true)
            setTimeout(() => {
              handleConfigurationSubmit(values)
            }, 50)
          }}
          onBack={onBack}
        />
      </StepContent>
    )
  }

  const renderDataReviewStep = () => (
    <StepContent>
      <div className="create-override-grid-layout">
        <div className="metadata-section">
          <div className="metadata-instructions">
            <h2>Review Override Data</h2>
            <Text type="secondary">
              Review your override data below. Fix any validation errors before saving. You can use the grid controls on
              the right to filter and validate your data.
            </Text>
          </div>
          <div className="details-grid">
            <Statistic title="Override Name" value={overrideName} />
            <div className="status-item">
              <Text className="status-label">Status</Text>
              <Space align="center" size={12}>
                <Tooltip title="Overrides are inactive when first created">
                  <Switch
                    checked={false}
                    disabled
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Tooltip>
                <Text strong className="status-inactive">
                  Inactive
                </Text>
              </Space>
            </div>
          </div>
        </div>
        <div className="grid-section">
          <GridContainer
            initialRowData={gridProps.initialRowData}
            overrideType={overrideType}
            overrideName={overrideName}
            selectedFields={fieldsToOverride.map((field) => OVERRIDE_COLUMNS_READABLE_TO_COLUMN_KEY[field])} // map these to columnNames
            onSave={(result) => {
              setIsTransitioning(true)
              setTimeout(() => {
                gridProps.onSave(result)
              }, 50)
            }}
            backButton={<Button onClick={() => handleStepTransition(0)}>Back</Button>}
          />
        </div>
      </div>
    </StepContent>
  )

  const renderSummaryStep = () => {
    const { saveResult } = summaryProps || {}

    // Only render if we have the save result
    if (!saveResult) {
      return (
        <StepContent>
          <div className="summary-content">
            <Result status="info" title="Processing..." subTitle="Your override is being processed." />
          </div>
        </StepContent>
      )
    }

    // Create the view override URL
    const sheetId = saveResult.sheet_id

    // Handle both camelCase and snake_case property names from API
    const insertedCount = saveResult.inserted_count || 0
    const updatedCount = saveResult.updated_count || 0
    const totalCount = insertedCount + updatedCount

    return (
      <StepContent>
        <div className="summary-content">
          <Result
            status="success"
            title="Override Created Successfully"
            subTitle={`Your ${title.toLowerCase()} override has been created and is ready to use.`}
            icon={<CheckOutlined />}
            extra={[
              <Button type="primary" key="done" onClick={onBack} className="summary-button">
                Done
              </Button>,
              <Button
                key="view"
                type="default"
                onClick={() => handleViewOverride(sheetId)}
                icon={<FileTextOutlined />}
                className="summary-button"
              >
                View Override
              </Button>,
            ]}
          />

          <Card className="summary-details">
            <Row gutter={[24, 24]}>
              <Col span={20}>
                <Statistic
                  title="Override Name"
                  value={saveResult.overrideName || overrideName}
                  className="summary-statistic"
                />
              </Col>
              <Col span={4}>
                <div className="status-item summary-status">
                  <Text className="status-label">Status</Text>
                  <Space align="center" size={12}>
                    <Switch
                      checked={false}
                      disabled
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                    <Text strong className="status-inactive">
                      Inactive
                    </Text>
                  </Space>
                </div>
              </Col>
              <Col span={12}>
                <Statistic
                  title="Records"
                  value={totalCount}
                  suffix={
                    <Tooltip title={`${insertedCount} new, ${updatedCount} updated`}>
                      <Text type="secondary">
                        ({insertedCount} new, {updatedCount} updated)
                      </Text>
                    </Tooltip>
                  }
                  className="summary-statistic"
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Created On"
                  value={new Date(saveResult.createdAt).toLocaleDateString()}
                  className="summary-statistic"
                />
              </Col>
            </Row>
          </Card>
        </div>
      </StepContent>
    )
  }

  const stepContent = {
    0: renderConfigurationStep,
    1: renderDataReviewStep,
    2: renderSummaryStep,
  }

  return (
    <div className="create-override fee-management-container">
      <FeeManagementHeader
        title={`Create ${title}`}
        icon={typeConfig.icon}
        onBack={onBack}
        breadcrumbs={[
          {
            text: typeConfig.title,
            path: `/fee-management/overrides/${overrideType}`,
            icon: typeConfig.icon,
          },
          {
            text: 'Create Override',
            path: `/fee-management/overrides/${overrideType}/create`,
          },
        ]}
      />

      <div className="create-override-content">{stepContent[displayStep]()}</div>
    </div>
  )
}

CreateOverride.propTypes = {
  overrideProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(OVERRIDE_TYPES).map((type) => type.value)).isRequired,
    onBack: PropTypes.func.isRequired,
  }).isRequired,

  fileHandlingProps: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
  }).isRequired,

  formProps: PropTypes.shape({
    fieldsToOverride: PropTypes.arrayOf(PropTypes.string).isRequired,
    overrideName: PropTypes.string.isRequired,
    handleConfigurationSubmit: PropTypes.func.isRequired,
    hasCompletedConfiguration: PropTypes.bool.isRequired,
  }).isRequired,

  stepperProps: PropTypes.shape({
    currentStep: PropTypes.number.isRequired,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        icon: PropTypes.elementType.isRequired,
      }),
    ).isRequired,
    goToStep: PropTypes.func.isRequired,
    completedSteps: PropTypes.objectOf(PropTypes.bool).isRequired,
  }).isRequired,

  gridProps: PropTypes.shape({
    initialRowData: PropTypes.array,
    onSave: PropTypes.func.isRequired,
  }).isRequired,

  summaryProps: PropTypes.shape({
    saveResult: PropTypes.object,
    overrideType: PropTypes.string,
  }),
}

export default CreateOverride
