import React, { useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import { useGridFilter } from 'ag-grid-react'
import './styles/GridSelectFilter.scss'

const GridSelectFilter = ({ model, onModelChange, options, doesFilterPassFn, filterParams }) => {
  const [filterValue, setFilterValue] = useState(model?.value || 'all')
  const [filteredOptions, setFilteredOptions] = useState([{ value: 'all', label: 'All' }, ...options])
  const isUpdatingOptionsRef = useRef(false)
  const selfTriggeredChangeRef = useRef(false)

  useEffect(() => {
    if (!filterParams?.api) {
      return
    }

    const updateOptions = () => {
      // Skip if we're already updating options to prevent loops
      if (isUpdatingOptionsRef.current) {
        return
      }

      isUpdatingOptionsRef.current = true

      const visibleValues = new Set()
      const field = filterParams.colDef.field

      filterParams.api.forEachNodeAfterFilter((node) => {
        const value = node.data[field]
        if (value) {
          visibleValues.add(value)
        }
      })

      setFilteredOptions([
        { value: 'all', label: 'All' },
        ...options.filter((option) => visibleValues.has(option.value)),
      ])

      isUpdatingOptionsRef.current = false
    }

    const onFilterChanged = () => {
      if (selfTriggeredChangeRef.current) {
        // Skip if this update was triggered by our own component
        selfTriggeredChangeRef.current = false
        return
      }

      updateOptions()
    }

    // Run initially
    updateOptions()

    // Add global filter change listener
    const gridApi = filterParams.api
    gridApi.addEventListener('filterChanged', onFilterChanged)

    return () => {
      gridApi.removeEventListener('filterChanged', onFilterChanged)
    }
  }, [filterParams, options, filterValue])

  const isFilterActive = useCallback(() => {
    return filterValue !== 'all'
  }, [filterValue])

  const doesFilterPass = useCallback(
    (params) => {
      if (!params.data) {
        return false
      }
      if (filterValue === 'all') {
        return true
      }
      return doesFilterPassFn(filterValue, params.data)
    },
    [filterValue, doesFilterPassFn],
  )

  const getModel = useCallback(() => {
    return filterValue === 'all' ? null : { value: filterValue }
  }, [filterValue])

  const setModel = useCallback((model) => {
    const newValue = model?.value || 'all'
    setFilterValue(newValue)
  }, [])

  useGridFilter({
    doesFilterPass,
    isFilterActive,
    getModel,
    setModel,
  })

  const handleFilterChange = (value) => {
    // Flag that this filter change was triggered by this component
    selfTriggeredChangeRef.current = true

    setFilterValue(value)
    onModelChange(value === 'all' ? null : { value })
  }

  return (
    <div className="grid-select-filter">
      <Select value={filterValue} onChange={handleFilterChange} options={filteredOptions} />
    </div>
  )
}

GridSelectFilter.propTypes = {
  model: PropTypes.shape({
    value: PropTypes.string,
  }),
  onModelChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  doesFilterPassFn: PropTypes.func.isRequired,
  filterParams: PropTypes.object,
}

GridSelectFilter.displayName = 'GridSelectFilter'

export default GridSelectFilter
