// Libraries
import React, { Component, Fragment } from 'react'
import { Nav, NavItem, NavLink, Table, Badge } from 'reactstrap'
import { MessageOutlined, ProfileOutlined, SafetyOutlined } from '@ant-design/icons'
import moment from 'moment'

import { connectResource } from '../../common/utils/resource'
import SingleEvault from './SingleEvault'
import NewMessageContainer from './NewMessageContainer'
import ReggoraChatBubble from '../../common/widgets/ReggoraBubble'
import { ChatFeed } from 'react-chat-ui'
import { apiToReadable, checkCanViewAppraiserNameOnOrder } from '../../common/utils/helpers'
import Loader from '../../common/layout/components/Loader'
import { USER_TYPES } from '../../common/constants/userTypes'
import { JOB_TYPES } from '../../common/constants/jobTypes'
import { COUNTEROFFER_OPTIONS, NO_REASON_PROVIDED } from '../../../reggora_shared_ui/constants'
import Rephrase from '../../common/utils/Rephrase'
import OfferActionButtons from './OfferActionButtons'
import { prepConversationMessages, getConversationTooltips } from '@helpers/conversations'

class OfferTab extends Component {
  state = {
    selectedTab: '',
  }

  componentDidMount() {
    const { orderData, is_amc_lender } = this.props
    const individually = orderData.order_request_method === 'individually'
    const cascade = orderData.order_request_method === 'cascade'
    const bid = orderData.appraiser_acceptance_method === 'bid'

    let selectedTab

    if (this.onlyShowPastConversations() && this.props.conversationParticipation && !is_amc_lender) {
      selectedTab = 'conversation'
    } else if (individually || bid || cascade) {
      selectedTab = 'details'
    } else {
      selectedTab = 'evault'
    }

    this.setState({
      selectedTab,
    })
  }

  onlyShowPastConversations = () => {
    const { orderData } = this.props

    const orderRequestMethod = orderData.order_request_method
    const orderStatus = orderData.statusKey

    let onlyShowPastConversations = false

    if (orderRequestMethod === 'broadcast' && !['waiting_for_payment', 'finding_appraisers'].includes(orderStatus)) {
      onlyShowPastConversations = true
    }
    return onlyShowPastConversations
  }

  getIndividualAppraiserNames = (index) => {
    const { orderData, is_amc_lender } = this.props

    let requestedAppraisersNames = ''
    const assignmentListRequests = is_amc_lender ? orderData.full_assignment_list : orderData.assignment_list
    const assignmentListRequest = assignmentListRequests[index]
    const requestedAppraisers = assignmentListRequest.requested_appraisers
    requestedAppraisers.forEach(appraiser => {
      const firstName = appraiser.firstname
      const lastName = appraiser.lastname
      const fullName = `${firstName} ${lastName}`

      // Create string of appraiser names
      if (requestedAppraisersNames === '') {
        requestedAppraisersNames = requestedAppraisersNames.concat(fullName)
      } else {
        requestedAppraisersNames = requestedAppraisersNames.concat(`, ${fullName}`)
      }
    })
    return requestedAppraisersNames
  }


  getAppraiserNameLabel = (index) => {
    const { orderData, is_amc_lender } = this.props
    const assignmentListRequests = is_amc_lender ? orderData.full_assignment_list : orderData.assignment_list
    const assignmentListRequest = assignmentListRequests[index]
    const requestedAppraisers = assignmentListRequest.requested_appraisers

    const appraiserLabel = requestedAppraisers.length > 1 ? 'Requested Appraisers: ' : 'Requested Appraiser: '
    return appraiserLabel
  }

  showIndividualAppraisersIfNeeded = (index) => {
    const { orderData, lenderData } = this.props
    const appraiserName = this.getIndividualAppraiserNames(index)

    if (appraiserName.length === 0) {

    } else if (lenderData.is_using_individual_appraiser_request_view && checkCanViewAppraiserNameOnOrder(orderData)) {
      return <h6><b>{this.getAppraiserNameLabel(index)}</b>{appraiserName}</h6>
    }
  }

  hasMessages = (messages) => {
    if (messages && messages.length > 0) {
      return true
    } else {
      return false
    }
  }

  shouldShowNavBar = (messages) => {
    const { is_amc_lender } = this.props
    const onlyShowPastConversations = this.onlyShowPastConversations()

    let shouldShowNavBar

    if (onlyShowPastConversations) {
      if (this.hasMessages(messages)) {
        shouldShowNavBar = true
      } else {
        shouldShowNavBar = false
      }
    } else {
      shouldShowNavBar = true
    }
    return shouldShowNavBar || is_amc_lender
  }

  handleOffer = (order_id, request_id, offer_id, action) => {
    const { offer, refreshOrder } = this.props
    offer.put({ order_id, request_id, offer_id, action })
      .then(refreshOrder)
  }


  render() {
    const { selectedTab } = this.state
    const {
      orderData,
      request,
      currentUserID,
      markReadMessages,
      conversationParticipation,
      hasOfferConversation,
      is_amc_lender,
      index,
      isAcceptedCompany,
      lenderData,
      offer,
      refreshOrder,
      usingAmcLender,
    } = this.props

    const {
      order_request_method: orderRequestMethod,
      appraiser_acceptance_method: appraiserAcceptanceMethod,
    } = orderData || {}
    const individually = orderRequestMethod === 'individually'
    const cascade = orderRequestMethod === 'cascade'
    const broadcast = orderRequestMethod === 'broadcast'
    const bid = appraiserAcceptanceMethod === 'bid'

    let messagesBubbles
    let messages
    if (request.conversation) {
      // Show messages from the current vendor if they exist
      const currentVendorMessages = (orderData.current_vendor_messages || []).filter(
        message => Boolean(message.is_amc) === Boolean(is_amc_lender)
      )
      const preppedConversation = prepConversationMessages(
        request.conversation.messages.concat(currentVendorMessages),
        request.conversation.id,
        currentUserID,
        is_amc_lender ? USER_TYPES.AMC : USER_TYPES.LENDER
      )
      messages = preppedConversation.messages
      messagesBubbles = getConversationTooltips(request.conversation, orderData)
    }
    if (offer.isLoading) {
      return (<div className='card mb-3'>
        <div className='card-body'>
          <Loader />
        </div>
      </div>)
    }
    const showDetails = ((individually || bid || cascade) && !this.onlyShowPastConversations()) || is_amc_lender
    const showEvault = !usingAmcLender &&
      ((broadcast || cascade) && request.evault && !this.onlyShowPastConversations())
    const showConversation = !usingAmcLender &&
      ((broadcast || cascade) && request.conversation && hasOfferConversation && conversationParticipation)
    const canViewVendor = !usingAmcLender && checkCanViewAppraiserNameOnOrder(orderData)
    const companyNameToDisplay = canViewVendor
      ? request.company.name
      : usingAmcLender
        ? 'The Appraisal Marketplace'
        : (<Rephrase>Appraiser {index + 1}</Rephrase>)

    const getAdjustedFee = (offerObject) => {
      // If this is an AMC order but the client lender is viewing, apply AMP fee
      if (orderData.job_type === JOB_TYPES.AMC && !is_amc_lender) {
        const ampFee = offerObject.new_reggora_amc_management_fee === null
          ? orderData.reggora_amc_management_fee_dollars
          : offerObject.new_reggora_amc_management_fee

        return (parseFloat(offerObject.fee) + parseFloat(ampFee)).toFixed(2)
      }

      return parseFloat(offerObject.fee).toFixed(2)
    }

    return (
      <div className='card mb-3'>
        <div className={(orderData.accepted && !isAcceptedCompany) ? 'card-body grey-background' : 'card-body'}>
          <div className='card-title w-100 d-flex justify-content-between'>
            <h5><b>Request: </b>{companyNameToDisplay}</h5>
            {orderData.accepted && isAcceptedCompany && ' (Accepted)'}
            {orderData.accepted && !isAcceptedCompany && ' (Rejected)'}
          </div>
          <div>
            {this.showIndividualAppraisersIfNeeded(index)}
          </div>
          {this.shouldShowNavBar(messages) && <Nav tabs className='mt-3'>
            {showDetails ? (
              <NavItem>
                <NavLink
                  className='submission-tab'
                  active={selectedTab === 'details'}
                  onClick={() => {
                    this.setState({ selectedTab: 'details' })
                  }}
                >
                  <small>
                    {<ProfileOutlined className='text-muted mr-1' />}{' '}
                    Request Details
                  </small>
                </NavLink>
              </NavItem>
            ) : null}

            {showEvault ? (
              <NavItem>
                <NavLink
                  className='submission-tab'
                  active={selectedTab === 'evault'}
                  onClick={() => {
                    this.setState({ selectedTab: 'evault' })
                  }}
                >
                  <small>
                    {<SafetyOutlined className='text-muted mr-1' />} Request
                    eVault
                  </small>
                </NavLink>
              </NavItem>
            ) : null}

            {showConversation ? (
              <NavItem>
                <NavLink
                  className='submission-tab'
                  active={selectedTab === 'conversation'}
                  onClick={() => {
                    this.setState({ selectedTab: 'conversation' })
                  }}
                >
                  <small>
                    {<MessageOutlined className='text-muted mr-1' />}{' '}
                    Conversation
                  </small>
                  {request.conversation.unread_messages > 0 && (
                    <Badge style={{ marginLeft: '5px' }} pill color={'danger'}>
                      {request.conversation.unread_messages}
                    </Badge>
                  )}
                </NavLink>
              </NavItem>
            ) : null}
          </Nav>}
        </div>

        <div className={(orderData.accepted && !isAcceptedCompany) ? 'card-footer bg-white submission-card-footer grey-background' : 'card-footer bg-white submission-card-footer'}>
          <div className='d-flex'>
            {selectedTab === 'details' && request.offers.length > 0 && <Table striped>
              <tbody>
                {request.offers.map(offer => {
                  const offerReason = <p>{COUNTEROFFER_OPTIONS[offer.reason] || offer.reason || NO_REASON_PROVIDED}</p>
                  let offerText = offerReason
                  // For AMP orders ALWAYS show AMP notes to client lender
                  if (orderData.job_type === JOB_TYPES.AMC && !is_amc_lender) {
                    offerText = <p>{offer.amp_notes}</p>
                  } else if (orderData.job_type === JOB_TYPES.AMC && is_amc_lender) {
                    offerText = <Fragment>
                      {offerReason}
                      {<p className='text-muted'>AMP Notes - {offer.amp_notes}</p>}
                    </Fragment>
                  }

                  return (
                    <tr key={offer.id}>
                      <td style={{ width: '15%' }}>
                        <p className='font-weight-bold mb-0'>Status:</p>
                        {apiToReadable(offer.status)}
                      </td>
                      <td style={{ width: '15%' }}>
                        <p className='font-weight-bold mb-0'>Fee:</p>
                        ${getAdjustedFee(offer)}
                      </td>
                      <td style={{ width: '15%' }}>
                        <p className='font-weight-bold mb-0'>Due Date:</p>
                        {moment(offer.due_date).format('ll')}
                      </td>
                      {lenderData.is_using_individual_appraiser_request_view &&
                      canViewVendor &&
                      offer.appraiser &&
                      <td style={{ width: '15%' }}>
                        <p className='font-weight-bold mb-0'><Rephrase>Appraiser:</Rephrase></p>
                        {`${offer.appraiser.firstname} ${offer.appraiser.lastname}`}
                      </td>
                      }
                      <td style={{ width: '25%' }}>
                        {!orderData.accepted && (
                          <OfferActionButtons
                            handleOffer={this.handleOffer}
                            offer={offer}
                            order={orderData}
                            request={request}
                            isAmcLender={is_amc_lender}
                            refreshOrder={refreshOrder}
                          />
                        )}
                      </td>
                      <td style={{ width: '15%' }}>
                        <p className='font-weight-bold mb-0'>Reason for Counter Offer:</p>
                        {offerText}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>}
            {selectedTab === 'details' && request.offers.length === 0 && 'This company is being requested.'}
            {selectedTab === 'evault' && !request.evault && 'This company is being requested.'}
            {selectedTab === 'evault' && request.evault && (
              <SingleEvault suppliedEvault={request.evault} />
            )}

            {selectedTab === 'conversation' && (
              <div className='d-flex flex-column align-content-between chat-controls w-100'>
                {(this.onlyShowPastConversations() && this.hasMessages(messages)) || !this.onlyShowPastConversations() ? (<Fragment><div className='chat-avatars'>{messagesBubbles}</div>
                  <ChatFeed
                    messages={messages}
                    chatBubble={ReggoraChatBubble}
                    showSenderName={false}
                  /></Fragment>)
                  : 'No conversation history'}
                {conversationParticipation && !this.onlyShowPastConversations() && (
                  <NewMessageContainer
                    conversation_id={request.conversation.id}
                    conversationType={`offer.${request.id}`}
                    orderData={orderData}
                    is_amc_lender={is_amc_lender}
                    is_revision={false}
                    modal={false}
                    onFocus={() => markReadMessages(request.conversation.id)}
                    newMessageTitle="Conversation"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default connectResource({
  namespace: 'offer',
  endpoint: 'order/offer',
  list: false,
  prefetch: false,
  successMessage: {
    PUT: 'Your order has been updated.',
  },
  apiVersion: 2,
})(OfferTab)
