import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import ViewOverride from './ViewOverride'
import { useFeeMgmtNavigation } from '../hooks/useFeeMgmtNavigation'
import { useGridData } from '../context/GridDataContext'
import {
  fetchOverrideSheets,
  fetchOverrideData,
  updateOverrideSheet,
} from '../../../store/reducers/feeManagement/feeManagementSlice'
import {
  selectOverrideSheetById,
  selectOverrideSheetsLoading,
  selectOverrideSheetsError,
  selectOverrideDataBySheetId,
  selectOverrideDataLoading,
  selectOverrideDataError,
} from '../../../store/reducers/feeManagement/feeManagementSelectors'

const ViewOverrideContainer = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { handleBackFromOverride } = useFeeMgmtNavigation()
  const [status, setStatus] = useState('inactive')

  // Get grid data context but don't actively fetch mapping data here
  const { isLoadingMappingData, mapApiDataToGridData, mappingData } = useGridData()

  const override = useSelector((state) => selectOverrideSheetById(state, id))
  const rawGridData = useSelector((state) => selectOverrideDataBySheetId(state, id))
  const isLoadingSheet = useSelector(selectOverrideSheetsLoading)
  const isLoadingData = useSelector(selectOverrideDataLoading)
  const sheetError = useSelector(selectOverrideSheetsError)
  const dataError = useSelector(selectOverrideDataError)

  // Transform grid data with mapped names
  const gridData = useMemo(() => {
    if (!rawGridData) {
      return null
    }

    // Map the API data to grid format (now includes product and vendor name mapping)
    return mapApiDataToGridData(rawGridData, mappingData)
  }, [rawGridData, mapApiDataToGridData, mappingData])

  // Set initial status from override data
  useEffect(() => {
    if (override?.status) {
      setStatus(override.status)
    }
  }, [override])

  const handleSave = useCallback(async (data) => {
    // Handle save logic
  }, [])

  const handleStatusChange = useCallback(
    (checked) => {
      const newStatus = checked ? 'active' : 'inactive'
      setStatus(newStatus)
      dispatch(updateOverrideSheet({ id: override.id, active: checked })).catch((error) => {
        console.error('Error updating override sheet:', error)
        setStatus(checked ? 'active' : 'inactive')
      })
    },
    [override, dispatch],
  )

  // Transform override data for the view component
  const transformedOverride = useMemo(() => {
    if (!override) {
      return null
    }
    return {
      id: override.id,
      title: override.title,
      overrideType: override.overrideType,
      overriddenFields: override.overriddenFields,
      details: {
        'Total Overrides': gridData?.length || 0,
        'Created By': override.created_by,
        'Created Date': override.created,
        'Last Modified': override.created,
      },
    }
  }, [override, gridData?.length])

  // Fetch data
  useEffect(() => {
    if (!override) {
      dispatch(fetchOverrideSheets())
    }
    dispatch(fetchOverrideData(id))
  }, [dispatch, id, override])

  if (sheetError || dataError) {
    return (
      <div className="error-state">
        Error loading override: {sheetError || dataError}
        <Button onClick={() => handleBackFromOverride(override?.overrideType)}>Back</Button>
      </div>
    )
  }

  return (
    <ViewOverride
      override={transformedOverride}
      gridData={gridData}
      isLoading={isLoadingSheet || isLoadingData || isLoadingMappingData}
      onBack={() => handleBackFromOverride(override?.overrideType)}
      onStatusChange={handleStatusChange}
      onSave={handleSave}
      status={status}
    />
  )
}

export default ViewOverrideContainer
