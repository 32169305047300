import React from 'react'
import { Tooltip, Button } from 'antd'
import { FileSearchOutlined } from '@ant-design/icons'
import parse from 'html-react-parser'

/**
 * Renders a data point value with HTML parsing
 */
const DataPointValue = ({ rendered_value }) => {
  return <>{parse(rendered_value)}</>
}

/**
 * A specialized version of DataPoint for use in ResultView that supports highlighting
 * in PDF documents. This keeps the highlighting logic separate from ReviewDatapoints.
 *
 * When the reggoraReviewPdfAnnotation flag is disabled, this always uses text search.
 */
export function HighlightableDataPoint(props) {
  const {
    name,
    rendered_value,
    field_id,
    highlightedFieldIds,
    onHighlight,
    onSearch,
  } = props

  // Extract Sales Comparable number if present
  const salesComparableMatch = name?.match(/Sales Comparables #(\d+)/i)
  const comparableNumber = salesComparableMatch ? parseInt(salesComparableMatch[1], 10) : null

  // First check if highlighting is enabled AND this is a field that has parsing data
  const isHighlightable = field_id && highlightedFieldIds?.has(field_id)

  return (
    <span>
      {`${name}: `}
      {rendered_value && (
        <>
          <DataPointValue rendered_value={rendered_value} />
          <Tooltip title="Find">
            <Button
              className='mx-1'
              shape='circle'
              onClick={() => {
                if (isHighlightable) {
                  // Use field-based navigation with highlighting
                  onHighlight(field_id, comparableNumber)
                } else {
                  // Always fall back to text search
                  onSearch(rendered_value)
                }
              }}
              icon={<FileSearchOutlined />}
            />
          </Tooltip>
        </>
      )}
    </span>
  )
}
