import React from 'react'
import PropTypes from 'prop-types'
import { Steps } from 'antd'

const { Step } = Steps

const CreateOverrideStepper = ({ vertical, currentStep, steps, onStepClick, completedSteps }) => {
  const handleStepClick = (step) => {
    // Prevent navigation if we're on the summary step
    if (currentStep === steps.length - 1) {
      return
    }

    // Only allow clicking on completed steps or the current step
    if (completedSteps[step] || step === currentStep) {
      onStepClick(step)
    }
  }

  return (
    <div className="override-steps">
      <Steps direction={vertical ? 'vertical' : 'horizontal'} current={currentStep} onChange={handleStepClick}>
        {steps.map(({ title, description }, index) => (
          <Step
            key={index}
            title={title}
            description={description}
            disabled={currentStep === steps.length - 1 || (!completedSteps[index] && index !== currentStep)}
          />
        ))}
      </Steps>
    </div>
  )
}

CreateOverrideStepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
    }),
  ).isRequired,
  vertical: PropTypes.bool,
  onStepClick: PropTypes.func.isRequired,
  completedSteps: PropTypes.objectOf(PropTypes.bool).isRequired,
}

CreateOverrideStepper.defaultProps = {
  vertical: false,
}

export default CreateOverrideStepper
