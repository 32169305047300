import React, { createContext, useContext, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { fetchMappingData } from '../../../store/reducers/feeManagement/feeManagementSlice'
import {
  selectMappingData,
  selectMappingDataLoading,
  selectMappingDataError,
} from '../../../store/reducers/feeManagement/feeManagementSelectors'
import {
  FEE_MGMT_COLUMNS,
  ROW_NUMBER_COL,
  LOCATION_TYPE_API_TO_READABLE,
  LOCATION_TYPE_READABLE_TO_API,
  OVERRIDE_TYPES,
  API_OVERRIDE_TYPE_MAPPING,
} from '../constants/Validation'

// Create the context
const GridDataContext = createContext(null)

// Custom hook to use the context
export const useGridData = () => {
  const context = useContext(GridDataContext)
  if (!context) {
    throw new Error('useGridData must be used within a GridDataProvider')
  }
  return context
}

// Provider component
export const GridDataProvider = ({ children }) => {
  const dispatch = useDispatch()

  // Get mapping data from Redux state using selectors
  const mappingData = useSelector(selectMappingData)
  const isLoadingMappingData = useSelector(selectMappingDataLoading)
  const mappingDataError = useSelector(selectMappingDataError)

  // Function to fetch mapping data based on entity types
  const fetchMappingDataFn = useCallback(
    async (entityTypes) => {
      if (!entityTypes || !entityTypes.length) {
        return
      }

      dispatch(fetchMappingData(entityTypes))
    },
    [dispatch],
  )

  // Automatically fetch all mapping data when the provider mounts
  useEffect(() => {
    // Fetch all entity types to ensure we have complete data
    const allEntityTypes = [
      ...API_OVERRIDE_TYPE_MAPPING.VENDOR,
      ...API_OVERRIDE_TYPE_MAPPING.LOCATION,
      ...API_OVERRIDE_TYPE_MAPPING.VENDOR_LOCATION,
    ]

    // Remove duplicates if any
    const uniqueEntityTypes = [...new Set(allEntityTypes)]

    // Only fetch if we don't already have the data
    if (
      !mappingData ||
      !mappingData.products ||
      !Array.isArray(mappingData.products) ||
      mappingData.products.length === 0 ||
      Object.keys(mappingData).length === 0
    ) {
      fetchMappingDataFn(uniqueEntityTypes)
    }
  }, [fetchMappingDataFn, mappingData])

  const mapCommaSeparatedStringToArray = useCallback((commaSeparatedString) => {
    return (
      commaSeparatedString
        ?.split(',')
        .map((s) => s.trim())
        .filter((s) => s !== '') || []
    )
  }, [])

  // Helper function to get panelist name by ID
  const getPanelistNameById = useCallback(
    (panelistId) => {
      const panelist = mappingData.panelists.find((p) => p.id === panelistId)
      return panelist ? panelist.name : panelistId
    },
    [mappingData.panelists],
  )

  // Helper function to get panelist ID by name
  const getPanelistIdByName = useCallback(
    (panelistName) => {
      const panelist = mappingData.panelists.find((p) => p.name === panelistName)
      return panelist ? panelist.id : panelistName
    },
    [mappingData.panelists],
  )

  // Helper function to get product name by ID
  const getProductNameById = useCallback(
    (productId) => {
      const product = mappingData.products.find((p) => p.id === productId)
      return product ? product.description : productId
    },
    [mappingData.products],
  )

  // Helper function to get product ID by name
  const getProductIdByName = useCallback(
    (productName) => {
      const product = mappingData.products.find((p) => p.description === productName)
      return product ? product.id : productName
    },
    [mappingData.products],
  )

  // Helper function to get counties for a state
  const getCountiesForState = useCallback(
    (stateCode) => {
      return mappingData.state_and_counties_map[stateCode] || []
    },
    [mappingData.state_and_counties_map],
  )

  // Helper function to validate if a county exists in a state
  const isValidCountyForState = useCallback(
    (stateCode, county) => {
      const counties = mappingData.state_and_counties_map[stateCode] || []
      return counties.includes(county.toUpperCase())
    },
    [mappingData.state_and_counties_map],
  )

  // Memoized function to create FIPS code lookup maps
  const createFipsLookupMaps = useCallback(() => {
    const stateFipsToKey = {}
    const countyFipsToReadable = {} // Will now be nested: state_fips -> county_fips -> readable

    if (mappingData?.state_and_counties_map) {
      Object.entries(mappingData.state_and_counties_map).forEach(([stateKey, stateData]) => {
        const stateFips = stateData.fips_code
        stateFipsToKey[stateFips] = stateKey

        if (stateData.counties) {
          // Initialize the nested structure for this state
          countyFipsToReadable[stateFips] = {}

          Object.entries(stateData.counties).forEach(([countyKey, countyData]) => {
            countyFipsToReadable[stateFips][countyData.fips_code] = countyData.readable
          })
        }
      })
    }

    return { stateFipsToKey, countyFipsToReadable }
  }, [mappingData?.state_and_counties_map])

  // Memoized function to map a single item to grid format
  const mapItemToGridFormat = useCallback(
    (item, index, stateFipsToKey, countyFipsToReadable) => {
      // Start with required grid management fields
      const baseFields = {
        [ROW_NUMBER_COL.columnKey]: index + 1,
        [FEE_MGMT_COLUMNS.DELETE.readable]: false,
        [FEE_MGMT_COLUMNS.ERROR.readable]: false,
        validationErrors: {},
        userMarkedForDelete: false,
        isDuplicateRow: false,
      }

      // Map states from FIPS codes to state keys
      const mappedStates = item.states?.map((fipsCode) => stateFipsToKey[fipsCode] || fipsCode) || []

      // Map counties from FIPS codes to readable names, considering state context
      const mappedCounties =
        item.counties?.map((countyFips) => {
          const stateFips = item.states?.[0] // There should only be one state if it has a county mapping
          if (stateFips && countyFipsToReadable[stateFips]?.[countyFips]) {
            return countyFipsToReadable[stateFips][countyFips]
          }
          return countyFips // Fallback to original value if mapping not found
        }) || []

      // Map API fields to column keys
      const mappedFields = {
        [FEE_MGMT_COLUMNS.PRODUCT_NAME.columnKey]: getProductNameById(item.product_id),
        [FEE_MGMT_COLUMNS.VENDOR.columnKey]: getPanelistNameById(item.panelist_id),
        [FEE_MGMT_COLUMNS.LOCATION_TYPE.columnKey]: LOCATION_TYPE_API_TO_READABLE[item.location_type] || '',
        [FEE_MGMT_COLUMNS.STATES.columnKey]: mappedStates.join(','),
        [FEE_MGMT_COLUMNS.COUNTIES.columnKey]: mappedCounties.join(','),
        [FEE_MGMT_COLUMNS.ZIP_CODES.columnKey]: Array.isArray(item.zips) ? item.zips.join(',') : '',
        [FEE_MGMT_COLUMNS.FEE.columnKey]: item.override_data?.fee,
        [FEE_MGMT_COLUMNS.DUE_DATE.columnKey]: item.override_data?.due_date,
      }

      return {
        ...baseFields,
        ...mappedFields,
      }
    },
    [getProductNameById, getPanelistNameById],
  )

  // Optimized mapApiDataToGridData function
  const mapApiDataToGridData = useCallback(
    (data, mappingData) => {
      // Early return for invalid data
      if (!Array.isArray(data)) {
        return []
      }

      // Create FIPS lookup maps once
      const { stateFipsToKey, countyFipsToReadable } = createFipsLookupMaps()

      // Map all items to grid format
      return data.map((item, index) => mapItemToGridFormat(item, index, stateFipsToKey, countyFipsToReadable))
    },
    [createFipsLookupMaps, mapItemToGridFormat],
  )

  // Helper function to map county names to FIPS codes
  const mapCountyToFips = useCallback((countyName, states, stateAndCountiesMap) => {
    if (!stateAndCountiesMap || !states.length) {
      return countyName
    }

    const countyNameLower = countyName.toLowerCase()

    // Try primary state first (most common case)
    const primaryState = states[0]
    const primaryStateMapping = stateAndCountiesMap[primaryState]?.counties
    if (primaryStateMapping) {
      const countyKey = Object.keys(primaryStateMapping).find(
        (key) => primaryStateMapping[key].readable.toLowerCase() === countyNameLower,
      )
      if (countyKey) {
        return primaryStateMapping[countyKey].fips_code
      }
    }

    // Try other states if needed
    for (let i = 1; i < states.length; i++) {
      const stateMapping = stateAndCountiesMap[states[i]]?.counties
      if (stateMapping) {
        const countyKey = Object.keys(stateMapping).find(
          (key) => stateMapping[key].readable.toLowerCase() === countyNameLower,
        )
        if (countyKey) {
          return stateMapping[countyKey].fips_code
        }
      }
    }

    return countyName
  }, [])

  // Helper function to check if a field should be included
  const shouldIncludeField = useCallback((field, columnKey, readable) => {
    return field === readable || field === columnKey
  }, [])

  // Function to map data between grid and API formats
  const mapGridDataToApi = useCallback(
    (data, fieldsToOverride, overrideType) => {
      // Early return for invalid data
      if (!Array.isArray(data)) {
        return []
      }

      // If fieldsToOverride is not provided, we're mapping from API to grid format
      if (!fieldsToOverride) {
        return mapApiDataToGridData(data, mappingData)
      }

      // Map entity_types based on overrideType
      const entityTypes =
        overrideType === OVERRIDE_TYPES.VENDOR_LOCATION.value
          ? API_OVERRIDE_TYPE_MAPPING.VENDOR_LOCATION
          : overrideType === OVERRIDE_TYPES.VENDOR.value
            ? API_OVERRIDE_TYPE_MAPPING.VENDOR
            : overrideType === OVERRIDE_TYPES.LOCATION.value
              ? API_OVERRIDE_TYPE_MAPPING.LOCATION
              : []

      // Map data_types based on fieldsToOverride
      const dataTypes = fieldsToOverride.map((field) => {
        if (shouldIncludeField(field, FEE_MGMT_COLUMNS.FEE.columnKey, FEE_MGMT_COLUMNS.FEE.readable)) {
          return FEE_MGMT_COLUMNS.FEE.snakeCaseKey
        }
        if (shouldIncludeField(field, FEE_MGMT_COLUMNS.DUE_DATE.columnKey, FEE_MGMT_COLUMNS.DUE_DATE.readable)) {
          return FEE_MGMT_COLUMNS.DUE_DATE.snakeCaseKey
        }
        return field.toLowerCase().replace(/\s+/g, '_')
      })

      // Pre-compute field inclusion flags
      const shouldIncludeFee = fieldsToOverride.some((field) =>
        shouldIncludeField(field, FEE_MGMT_COLUMNS.FEE.columnKey, FEE_MGMT_COLUMNS.FEE.readable),
      )
      const shouldIncludeDueDate = fieldsToOverride.some((field) =>
        shouldIncludeField(field, FEE_MGMT_COLUMNS.DUE_DATE.columnKey, FEE_MGMT_COLUMNS.DUE_DATE.readable),
      )

      // Map the grid data to overrides
      const overrides = data
        .filter((row) => !row.userMarkedForDelete)
        .map((row) => {
          // Get product and panelist IDs
          const productId = getProductIdByName(row[FEE_MGMT_COLUMNS.PRODUCT_NAME.columnKey])
          const panelistId = getPanelistIdByName(row[FEE_MGMT_COLUMNS.VENDOR.columnKey])

          // Process location data
          const statesRaw = mapCommaSeparatedStringToArray(row[FEE_MGMT_COLUMNS.STATES.columnKey])
          const countiesRaw = mapCommaSeparatedStringToArray(row[FEE_MGMT_COLUMNS.COUNTIES.columnKey])
          const zipsRaw = mapCommaSeparatedStringToArray(row[FEE_MGMT_COLUMNS.ZIP_CODES.columnKey])

          // Map states to FIPS codes
          const states = statesRaw.map(
            (stateCode) => mappingData?.state_and_counties_map?.[stateCode]?.fips_code || stateCode,
          )

          // Map counties to FIPS codes
          const counties = countiesRaw.map((countyName) =>
            mapCountyToFips(countyName, statesRaw, mappingData?.state_and_counties_map),
          )

          // Create base override object
          const override = {
            product_id: productId,
            panelist_id: panelistId,
            states,
            counties,
            zips: zipsRaw,
            location_type: LOCATION_TYPE_READABLE_TO_API[row[FEE_MGMT_COLUMNS.LOCATION_TYPE.columnKey]] || null,
            override_data: {},
          }

          // Add fee if needed
          if (shouldIncludeFee) {
            const feeValue = row[FEE_MGMT_COLUMNS.FEE.columnKey]
            if (feeValue != null && feeValue !== '') {
              override.override_data.fee = parseFloat(feeValue)
            }
          }

          // Add due_date if needed
          if (shouldIncludeDueDate) {
            const dueDateValue = row[FEE_MGMT_COLUMNS.DUE_DATE.columnKey]
            if (dueDateValue != null && dueDateValue !== '') {
              override.override_data.due_date = parseInt(dueDateValue, 10)
            }
          }

          return override
        })

      return { entityTypes, dataTypes, overrides }
    },
    [
      mappingData,
      getProductIdByName,
      getPanelistIdByName,
      mapApiDataToGridData,
      mapCountyToFips,
      shouldIncludeField,
      mapCommaSeparatedStringToArray,
    ],
  )

  // Expose the context value
  const value = {
    mappingData,
    isLoadingMappingData,
    mappingDataError,
    fetchMappingData: fetchMappingDataFn,
    getProductNameById,
    getProductIdByName,
    getPanelistNameById,
    getPanelistIdByName,
    getCountiesForState,
    isValidCountyForState,
    mapGridDataToApi,
    mapApiDataToGridData,
  }

  return <GridDataContext.Provider value={value}>{children}</GridDataContext.Provider>
}

GridDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GridDataContext
