import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFeeMgmtNavigation } from '../hooks/useFeeMgmtNavigation'
import FeeManagementHeader from './FeeManagementHeader'
import { OVERRIDE_TYPE_CONFIG } from '../constants/Breadcrumbs'
import { fetchOverrideSheets } from '../../../store/reducers/feeManagement/feeManagementSlice'
import ViewOverrideType from './ViewOverrideType'
import {
  selectOverrideSheetsGroupedByType,
  selectOverrideSheetsLoading,
  selectOverrideSheetsError,
} from '../../../store/reducers/feeManagement/feeManagementSelectors'
import '../styles/ViewOverrideType.scss'

const ACTIVE_STATUS = 'active'

const ViewOverrideTypeContainer = () => {
  const dispatch = useDispatch()
  const { type } = useParams()
  const { handleAddOverride, handleBack, handleViewOverride, getOverrideTypeMenuItems } = useFeeMgmtNavigation()

  const overrideSheets = useSelector(selectOverrideSheetsGroupedByType)
  const isLoading = useSelector(selectOverrideSheetsLoading)
  const error = useSelector(selectOverrideSheetsError)
  const [sortedOverrides, setSortedOverrides] = useState([])

  const typeConfig = OVERRIDE_TYPE_CONFIG[type]
  const Icon = typeConfig?.icon

  useEffect(() => {
    dispatch(fetchOverrideSheets())
  }, [dispatch, type])

  useEffect(() => {
    if (overrideSheets[type] && overrideSheets[type].length > 0) {
      const sorted = (overrideSheets[type] || []).sort(
        (a, b) =>
          (b.status === ACTIVE_STATUS) - (a.status === ACTIVE_STATUS) ||
          (a.title || '').localeCompare(b.title || '') ||
          new Date(b.created) - new Date(a.created),
      )
      setSortedOverrides(sorted)
    }
  }, [overrideSheets, type])

  return (
    <div className="view-override-type fee-management-container">
      <FeeManagementHeader
        title={typeConfig.title}
        icon={Icon}
        onBack={handleBack}
        breadcrumbs={[
          {
            text: typeConfig.title,
            path: `/fee-management/overrides/${type}`,
            icon: Icon,
            dropdownItems: getOverrideTypeMenuItems(type),
          },
        ]}
      />

      <ViewOverrideType
        overrideType={type}
        overrides={sortedOverrides}
        isLoading={isLoading}
        error={error}
        typeConfig={typeConfig}
        handleAddOverride={handleAddOverride}
        handleBack={handleBack}
        handleViewOverride={handleViewOverride}
      />
    </div>
  )
}

export default ViewOverrideTypeContainer
