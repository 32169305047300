import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useGridFilter } from 'ag-grid-react'
import { Switch, Input, InputNumber, List, Checkbox, Select, Typography } from 'antd'
import { FEE_MGMT_COLUMNS } from './constants/Validation'
import { useEntityMapping } from './hooks/useEntityMapping'

const FindReplaceFilter = ({ column, model, onModelChange, api, context }) => {
  const [findText, setFindText] = useState(model?.find || [])
  const [replaceText, setReplaceText] = useState(model?.replace || '')
  const [replaceNumber, setReplaceNumber] = useState(null)
  const [isNumericColumn, setIsNumericColumn] = useState(false)
  const [isDueDateColumn, setIsDueDateColumn] = useState(false)
  const [showReplace, setShowReplace] = useState(false)
  const [uniqueValues, setUniqueValues] = useState([])
  const [mounted, setMounted] = useState(true)
  const [searchInput, setSearchInput] = useState('')
  const listContainerRef = useRef(null)
  const [userHasCleared, setUserHasCleared] = useState(false)

  const field = column?.getColDef()?.field
  const { options: entityOptions, isEntityColumn: isEntityColumnType } = useEntityMapping(field, api)

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  useEffect(() => {
    if (!api || !mounted) {
      return
    }

    const field = column.getColDef().field
    const shouldSplitCsvCommas = column.getColDef().context?.shouldSplitCommaValues

    const updateValues = () => {
      if (!mounted) {
        return
      }

      const valuesSet = new Set()
      let hasBlankValues = false

      // Use forEachNode instead of forEachNodeAfterFilter to get all values, not just filtered ones
      api.forEachNode((node) => {
        const value = node.data[field]

        // Check for empty/null values
        if (value === null || value === undefined || value === '') {
          hasBlankValues = true
          return
        }

        if (shouldSplitCsvCommas) {
          value
            .toString()
            .split(',')
            .forEach((item) => {
              const trimmedItem = item.trim()
              if (trimmedItem) {
                valuesSet.add(trimmedItem)
              }
            })
        } else {
          valuesSet.add(value.toString())
        }
      })

      // Add "(Blanks)" option if there are blank values
      const sortedValues = [...valuesSet].sort()
      if (hasBlankValues) {
        sortedValues.unshift('(Blanks)')
      }

      if (mounted) {
        setUniqueValues(sortedValues)
      }
    }

    updateValues()
    api.addEventListener('filterChanged', updateValues)

    return () => {
      api.removeEventListener('filterChanged', updateValues)
    }
  }, [api, column, mounted]) // Removed findText dependency to prevent refiltering when selections change

  useEffect(() => {
    const field = column?.getColDef()?.field
    const isFee = field === FEE_MGMT_COLUMNS.FEE.columnKey
    const isDueDate = field === FEE_MGMT_COLUMNS.DUE_DATE.columnKey
    setIsNumericColumn(isFee || isDueDate)
    setIsDueDateColumn(isDueDate)
  }, [column])

  const isNumericReplaceTextValid = useCallback(
    (inputValue) => {
      // Valid if null (empty input is allowed)
      if (inputValue === null) {
        return true
      }

      // Invalid if undefined or not a number (text input)
      if (inputValue === undefined || isNaN(Number(inputValue))) {
        return false
      }

      // For due date columns, allow any integer (positive or negative)
      if (isDueDateColumn) {
        const isValid = Number.isInteger(Number(inputValue))
        return isValid
      }

      // For fee columns, only allow positive integers
      const isValid = Number.isInteger(Number(inputValue)) && Number(inputValue) >= 0
      return isValid
    },
    [isDueDateColumn],
  )

  // Add effect to update county options when state changes
  useEffect(() => {
    const field = column?.getColDef()?.field
    if (field === FEE_MGMT_COLUMNS.COUNTIES.columnKey) {
      // Force update of unique values when state changes
      if (api) {
        const shouldSplitCsvCommas = column.getColDef().context?.shouldSplitCommaValues

        const updateValues = () => {
          if (!mounted) {
            return
          }

          const valuesSet = new Set()
          let hasBlankValues = false

          api.forEachNode((node) => {
            const value = node.data[field]

            if (value === null || value === undefined || value === '') {
              hasBlankValues = true
              return
            }

            if (shouldSplitCsvCommas) {
              value
                .toString()
                .split(',')
                .forEach((item) => {
                  const trimmedItem = item.trim()
                  if (trimmedItem) {
                    valuesSet.add(trimmedItem)
                  }
                })
            } else {
              valuesSet.add(value.toString())
            }
          })

          const sortedValues = [...valuesSet].sort()
          if (hasBlankValues) {
            sortedValues.unshift('(Blanks)')
          }

          if (mounted) {
            setUniqueValues(sortedValues)
          }
        }

        updateValues()
        api.addEventListener('filterChanged', updateValues)
        return () => {
          api.removeEventListener('filterChanged', updateValues)
        }
      }
    }
  }, [api, column, mounted])

  useEffect(() => {
    // Only select all items if there are uniqueValues, nothing is currently selected,
    // AND the user hasn't explicitly cleared the selection
    if (mounted) {
      if (uniqueValues.length > 0 && (!findText || findText.length === 0) && !userHasCleared) {
        setFindText(uniqueValues)
      }
    }
  }, [uniqueValues, mounted, findText, userHasCleared])

  const renderNumericInput = () => {
    const isInvalid = !isNumericReplaceTextValid(replaceNumber)

    // Create a more specific error message based on the column type
    const getErrorMessage = () => {
      let errorMessage = ''
      const castedNumber = Number(replaceNumber)
      if (replaceNumber !== null) {
        if (Number.isNaN(castedNumber)) {
          errorMessage = `${isDueDateColumn ? FEE_MGMT_COLUMNS.DUE_DATE.readable : FEE_MGMT_COLUMNS.FEE.readable} must be a whole number`
        } else if (castedNumber < 0 && !isDueDateColumn) {
          errorMessage = `${FEE_MGMT_COLUMNS.FEE.readable} must be a positive whole number`
        }
      }

      return errorMessage
    }

    return (
      <div>
        <InputNumber
          placeholder="Replace with..."
          value={replaceNumber}
          controls={false}
          onInput={(value) => {
            setReplaceNumber(value)
          }}
          className={`replace-input ${isInvalid ? 'invalid' : ''}`}
          precision={0}
          min={isDueDateColumn ? undefined : 0}
          status={isInvalid ? 'error' : undefined}
        />
        {isInvalid && (
          <div className="error-message">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="exclamation-circle"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
              <path d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
            </svg>
            {getErrorMessage()}
          </div>
        )}
      </div>
    )
  }

  const renderReplaceInput = () => {
    if (!showReplace) {
      return null
    }

    // For entity columns (including states and counties), use Select component
    if (isEntityColumnType && entityOptions.length > 0) {
      return (
        <div className="replace-select-wrapper">
          <Select
            placeholder="Replace with..."
            value={replaceText || undefined}
            onChange={(value) => {
              setReplaceText(value)
            }}
            className="replace-input"
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={entityOptions}
            dropdownAlign={{ offset: [200, -100] }}
            listHeight={200}
            dropdownClassName="filter-dark-dropdown ag-custom-component-popup"
            onMouseDown={(e) => e.stopPropagation()}
            getPopupContainer={() => document.body}
          />
        </div>
      )
    }

    // For Fee column, use InputNumber with positive numbers only
    if (isNumericColumn) {
      return renderNumericInput()
    }

    // For other columns, use regular Input
    return (
      <Input
        placeholder="Replace with..."
        value={replaceText}
        onChange={(e) => setReplaceText(e.target.value)}
        className="replace-input"
      />
    )
  }

  const isFilterActive = useCallback(() => {
    return !!findText && findText.length !== uniqueValues.length
  }, [findText, uniqueValues])

  const doesFilterPass = useCallback(
    (params) => {
      const value = params.data[column.getColDef().field]?.toString() || ''
      if (!findText || findText.length === 0) {
        return false
      }

      // Special handling for "(Blanks)" option
      if (findText.includes('(Blanks)') && (!value || value === '')) {
        return true
      }

      // If no value and we're not looking for blanks, don't match
      if (!value) {
        return false
      }

      let searchText = findText.filter((text) => text !== '(Blanks)')
      let fieldValue = value

      // If we're only looking for blanks and this isn't blank, don't match
      if (searchText.length === 0) {
        return false
      }

      // Always case insensitive
      searchText = searchText.map((text) => text.toLowerCase())
      fieldValue = fieldValue.toLowerCase()

      let regex
      if (searchText.length === 0) {
        // No values selected, use partial match
        regex = new RegExp(`${searchText}`, 'gi')
      } else {
        // Values are selected, use whole word match
        regex = new RegExp(`\\b(${searchText.join('|')})\\b`, 'gi')
      }

      return regex.test(fieldValue)
    },
    [findText, column],
  )

  const getModel = useCallback(() => {
    if (!findText || findText.length === 0) {
      return null
    }
    return {
      find: findText,
      replace: replaceText,
    }
  }, [findText, replaceText])

  useGridFilter({
    doesFilterPass,
    isFilterActive,
    getModel,
    setModel: (model) => {
      setFindText(model?.find || [])
      setReplaceText(model?.replace || '')
    },
  })

  const handleFindChange = (value) => {
    setFindText(value)
    setUserHasCleared(false)

    // Notify AG Grid about the filter model change
    if (value && value.length > 0) {
      // Check if ALL values are selected
      if (uniqueValues.length > 0 && uniqueValues.every((item) => value.includes(item))) {
        // All values are selected - this is equivalent to no filter
        onModelChange(null)
      } else {
        // Only some values are selected - this is a real filter
        onModelChange({ find: value, replace: replaceText })
      }
    } else {
      onModelChange(null) // No filter when nothing is selected
    }
  }

  const hideFilterMenu = () => {
    // Use setTimeout to ensure state updates before hiding
    setTimeout(() => {
      api.hidePopupMenu()

      // Force cleanup of any lingering menu elements
      const menuElements = document.querySelectorAll('.ag-menu.ag-filter-menu')
      menuElements.forEach((el) => el.remove())

      // Refresh the grid to ensure clean state
      api.redrawRows()
    }, 0)
  }

  const onReplace = (e) => {
    // Prevent the default button behavior which might trigger filter apply
    e.preventDefault()

    if (!findText || findText.length === 0 || !replaceText || !api) {
      return
    }

    const field = column.getColDef().field
    const findValues = findText.filter((text) => text !== '(Blanks)').map((text) => text.toLowerCase())
    const includesBlanks = findText.includes('(Blanks)')

    const rowsToUpdate = []

    api.forEachNodeAfterFilter((node) => {
      const originalValue = node.data[field]

      // Handle blank values
      if (includesBlanks && (originalValue === null || originalValue === undefined || originalValue === '')) {
        // Ensure proper type conversion for numeric columns
        let newValue
        if (column.getColDef().type === 'numericColumn' || column.getColDef().cellDataType === 'number') {
          newValue = Number(replaceText)
          if (isNaN(newValue)) {
            return // Skip this replacement
          }
        } else {
          newValue = replaceText
        }

        const updatedData = { ...node.data, [field]: newValue }
        rowsToUpdate.push(updatedData)
        return
      }

      // Skip if no original value and we're not looking for blanks
      if (!originalValue) {
        return
      }

      const compareValue = originalValue.toString().toLowerCase()
      const matchFound = findValues.some((searchTerm) => compareValue === searchTerm)

      if (matchFound) {
        // Ensure proper type conversion for numeric columns
        let newValue
        if (column.getColDef().type === 'numericColumn' || column.getColDef().cellDataType === 'number') {
          newValue = Number(replaceText)
          if (isNaN(newValue)) {
            return // Skip this replacement
          }
        } else {
          newValue = replaceText
        }

        const updatedData = { ...node.data, [field]: newValue }
        rowsToUpdate.push(updatedData)
      }
    })

    if (rowsToUpdate.length > 0) {
      // Apply transaction to update the grid data
      api.applyTransactionAsync(
        {
          update: rowsToUpdate,
        },
        (result) => {
          // Force AG Grid to refresh cells to display new values
          api.refreshCells({
            force: true,
            rowNodes: rowsToUpdate.map((data) => api.getRowNode(String(data.rowNumber))),
            columns: [field],
          })

          // Update the filter selection to the replacement value
          setFindText([replaceText])

          // Force an update of the unique values list
          setTimeout(() => {
            const newUniqueValues = getUniqueFieldValues(api, field, column.getColDef().context?.shouldSplitCommaValues)
            setUniqueValues(newUniqueValues)

            // Update the filter model to use the new value
            onModelChange({ find: [replaceText], replace: replaceText })

            // Ensure validation is refreshed after the grid has updated
            context.refreshValidation()
          }, 0)
        },
      )
    }
  }

  // Helper function to get unique values for a field
  const getUniqueFieldValues = (api, field, shouldSplitCsvCommas) => {
    const valuesSet = new Set()
    let hasBlankValues = false

    api.forEachNode((node) => {
      const value = node.data[field]

      // Check for empty/null values
      if (value === null || value === undefined || value === '') {
        hasBlankValues = true
        return
      }

      if (shouldSplitCsvCommas) {
        value
          .toString()
          .split(',')
          .forEach((item) => {
            const trimmedItem = item.trim()
            if (trimmedItem) {
              valuesSet.add(trimmedItem)
            }
          })
      } else {
        valuesSet.add(value.toString())
      }
    })

    // Add "(Blanks)" option if there are blank values
    const sortedValues = [...valuesSet].sort()
    if (hasBlankValues) {
      sortedValues.unshift('(Blanks)')
    }

    return sortedValues
  }

  const onReset = () => {
    setFindText([])
    setReplaceText('')
    setShowReplace(false)
    setUserHasCleared(true) // Flag to prevent auto-selection
    onModelChange({ find: [], replace: '' })
  }

  const isReplaceButtonDisabled = useCallback(() => {
    const disabled =
      !findText ||
      findText.length === 0 ||
      (showReplace &&
        ((isNumericColumn && !isNumericReplaceTextValid(replaceNumber)) || (isEntityColumnType && !replaceText)))
    return disabled
  }, [
    findText,
    replaceText,
    showReplace,
    isNumericColumn,
    replaceNumber,
    isEntityColumnType,
    isNumericReplaceTextValid,
  ])

  // Filter the uniqueValues based on the search input
  const filteredValues = uniqueValues.filter(
    (value) => !searchInput || value.toLowerCase().includes(searchInput.toLowerCase()),
  )

  // Handle toggling a value in the selection
  const toggleValue = (value) => {
    setUserHasCleared(false) // User is making selections, reset the flag
    if (findText.includes(value)) {
      handleFindChange(findText.filter((v) => v !== value))
    } else {
      handleFindChange([...findText, value])
    }
  }

  return (
    <div
      className="ag-filter-body-wrapper ag-simple-filter-body-wrapper find-replace-filter"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="ag-filter-body">
        <Input
          placeholder="Search values..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          className="find-search-input"
        />
        <div className="values-list-container" ref={listContainerRef}>
          <List
            size="small"
            dataSource={filteredValues}
            renderItem={(item) => (
              <List.Item className={'value-list-item'} onClick={() => toggleValue(item)}>
                <Checkbox checked={findText.includes(item)} />
                <span className="value-text">{item}</span>
              </List.Item>
            )}
            className="values-list"
          />
        </div>

        <div className="filter-actions-row">
          <Typography.Link onClick={onReset} className="filter-action-link">
            Clear
          </Typography.Link>
          <Typography.Link onClick={() => handleFindChange(filteredValues)} className="filter-action-link">
            Select All
          </Typography.Link>
        </div>

        <div className="replace-toggle">
          <Switch size="small" checked={showReplace} onChange={setShowReplace} />
          <span className="replace-label">Replace</span>
        </div>

        {renderReplaceInput()}

        {showReplace && (
          <div className="replace-button-container">
            <button
              type="button"
              className="ag-button ag-standard-button ag-filter-apply-button replace-all-button"
              onClick={(e) => onReplace(e)}
              disabled={isReplaceButtonDisabled()}
            >
              Replace All
            </button>
          </div>
        )}

        <button type="button" className="done-button" onClick={hideFilterMenu}>
          Done
        </button>
      </div>
    </div>
  )
}

FindReplaceFilter.displayName = 'FindReplaceFilter'

export default FindReplaceFilter
