import React, { useCallback, forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react'
import { Tooltip } from 'antd'
import withMemoization from '../../common/components/MemoWrapper'

// Use a ref to keep track of filter state across component instances
const filterActiveStateRef = new Map()

const IconHeader = forwardRef((props, ref) => {
  const { icon: Icon, tooltip, onClick, showMenu, column } = props
  const columnId = column?.getColId()

  // Initialize state from the ref if available
  const [isFilterActive, setIsFilterActive] = useState(() => {
    if (columnId && filterActiveStateRef.has(columnId)) {
      return filterActiveStateRef.get(columnId)
    }
    return false
  })

  const containerRef = useRef(null)

  useImperativeHandle(ref, () => ({
    getGui: () => containerRef.current,
  }))

  useEffect(() => {
    if (column?.addEventListener) {
      const onFilterChanged = () => {
        const newFilterState = column.isFilterActive()

        // Store state in the global ref to persist across remounts
        if (columnId) {
          filterActiveStateRef.set(columnId, newFilterState)
        }

        // Only update state if actually changed
        if (isFilterActive !== newFilterState) {
          setIsFilterActive(newFilterState)
        }
      }

      // Immediately check and set filter state
      const initialFilterState = column.isFilterActive()
      if (columnId) {
        filterActiveStateRef.set(columnId, initialFilterState)
      }

      if (isFilterActive !== initialFilterState) {
        setIsFilterActive(initialFilterState)
      }

      column.addEventListener('filterChanged', onFilterChanged)

      return () => {
        column.removeEventListener('filterChanged', onFilterChanged)
      }
    }
  }, [column, columnId, isFilterActive])

  const handleClick = useCallback(
    (event) => {
      onClick(event)
    },
    [onClick],
  )

  const onMenuClick = useCallback(
    (event) => {
      event.stopPropagation()
      if (column) {
        props.showColumnMenu(containerRef.current)
      }
    },
    [props, column],
  )

  return (
    <div ref={containerRef} className="ag-header-cell" role="columnheader">
      <div className="ag-header-cell-comp-wrapper">
        <div className="ag-header-cell-label" role="presentation">
          <Tooltip title={tooltip}>
            <div className="header-icon">
              <Icon style={{ fontSize: '16px' }} onClick={handleClick} />
            </div>
          </Tooltip>
          {showMenu && (
            <div className="ag-header-cell-menu" onClick={onMenuClick}>
              <span
                className={`ag-icon ag-icon-filter ${isFilterActive ? 'ag-filter-active' : ''}`}
                aria-hidden="true"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

IconHeader.displayName = 'IconHeader'

// Use withMemoization instead of memo, focusing on the specific props that matter
export default withMemoization(IconHeader, ['column', 'icon', 'showMenu'])
