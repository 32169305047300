import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from 'reactstrap'
import { selectLenderData } from '@app/store/reducers/user/userSelectors'
import { ALERT_TEXT, CONSUMER_SUBMISSION_DOWNLOAD_ALERTS } from '../../common/constants/alerts'
import { useApiRequest } from '../../common/hooks'
import Rephrase from '../../common/utils/Rephrase'
import { updateOrder } from '../../store/reducers/order/orderSlice'

const OrderAlertsDismissible = (props) => {
  const { alerts, orderId } = props
  const lenderData = useSelector(selectLenderData)
  const {
    borrower_appraisal_download_alert_hours: borrowerAppraisalDownloadAlertHours,
  } = lenderData

  const apiRequest = useApiRequest()
  const dispatch = useDispatch()

  const getAlertText = (alertType) => {
    if (alertType === CONSUMER_SUBMISSION_DOWNLOAD_ALERTS) {
      return `Your borrower has not downloaded the report sent to them after ${borrowerAppraisalDownloadAlertHours} hours.`
    }
    return ALERT_TEXT[alertType]
  }

  const resolveAlert = async (alertId) => {
    await apiRequest({
      endpoint: 'order/alert',
      method: 'PUT',
      body: {
        order_id: orderId,
        alert_id: alertId,
        action: 'resolve',
      },
      successMessage: 'Your order has been updated.',
    })

    // Create the updated alerts array
    const alertsTemp = [...alerts]
    const alertIndex = alertsTemp.findIndex((a) => a.id === alertId)
    alertsTemp[alertIndex] = { ...alertsTemp[alertIndex], resolved: true }
    const orderUpdate = { alerts: alertsTemp }
    // Update the order in state
    dispatch(updateOrder({ orderID: orderId, update: orderUpdate }))
  }

  return (
    <div className="alerts my-3">
      {alerts.map(alert => {
        return (
          <Alert
            key={alert.id}
            color="danger"
            isOpen={!alert.resolved}
            toggle={() => resolveAlert(alert.id)}
          >
            <Rephrase>{getAlertText(alert.type)}</Rephrase>
          </Alert>
        )
      })}
    </div>
  )
}

export default OrderAlertsDismissible
