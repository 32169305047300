import React from 'react'
import { Route, Switch } from 'react-router-dom'
import FeeMgmt from './components/FeeMgmt'
import ViewOverrideTypeContainer from './components/ViewOverrideTypeContainer'
import ViewOverrideContainer from './components/ViewOverrideContainer'
import CreateOverrideContainer from './components/CreateOverrideContainer'
import { GridDataProvider } from './context/GridDataContext'

const FeeManagementRoutes = () => {
  return (
    <GridDataProvider>
      <Switch>
        <Route exact path="/fee-management" component={FeeMgmt} />
        <Route exact path="/fee-management/overrides/:type" component={ViewOverrideTypeContainer} />
        <Route exact path="/fee-management/overrides/:type/create" component={CreateOverrideContainer} />
        <Route exact path="/fee-management/override/:id" component={ViewOverrideContainer} />
      </Switch>
    </GridDataProvider>
  )
}

export default FeeManagementRoutes
