import keys from 'lodash/keys'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import isPlainObject from 'lodash/isPlainObject'
import isObject from 'lodash/isObject'
import flatMapDeep from 'lodash/flatMapDeep'
// TODO it seems we can move all query logic to API
import { buildQueryParams } from './common/utils/queryParams'
// import { logout } from 'pages/session'

export const API_VERSION = {
  ONE: 1,
  TWO: 2,
  SERVICE: 'service',
}

export const REQUEST_METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  OPTIONS: 'OPTIONS',
  DELETE: 'DELETE',
}

export const getBaseUrl = (version, service_url_override) => {
  let api = API2_URL
  if (version === API_VERSION.SERVICE) {
    api = SERVICE_URL
    if (service_url_override) {
      api = service_url_override
    }
  }
  return api
}

export default function(endpoint, apiVersion = 1, service_url_override = '') {
  return new API(endpoint, apiVersion, service_url_override)
}

function deepValues(obj) {
  // creates flat list of all `obj` values (including nested)
  if (isPlainObject(obj) || Array.isArray(obj)) {
    return flatMapDeep(obj, deepValues)
  }
  return obj
}

function hasFile(obj) {
  // check if `obj` has at least one `File` instance
  return deepValues(obj).some((v) => v instanceof Blob)
}

class API {
  constructor(endpoint, apiVersion, service_url_override = '') {
    if (apiVersion in API_VERSION) {
      console.error(`invalid API version: ${apiVersion}. Only APIs 1 and 2 are supported.`)
    }
    this.endpoint = endpoint
    this.version = apiVersion
    this.service_url_override = service_url_override
  }

  getAuthorizationHeader() {
    const authToken = localStorage.getItem('reggora_lender_auth_token')
    const prefix = this.version === 1 ? 'JWT' : 'bearer'
    return authToken ? `${prefix} ${authToken}` : ''
  }

  prepareBody(body, isMultipartFormData) {
    if (isEmpty(body)) {
      return body
    }

    if (isPlainObject(body)) {
      // FIXME we shouldn't send file object represented by url
      ;['avatar', 'logo', 'file'].forEach((field) => isString(body[field]) && delete body[field])
    }

    if (isMultipartFormData) {
      const formData = new FormData()
      for (var name in body) {
        if (isFunction(body[name])) {
          // FIXME there should not be functions
          console.warn('API detects invalid data value (function) in field:', name)
          continue
        } else if (Array.isArray(body[name])) {
          body[name].forEach((value, i) => {
            if (isObject(value) && !(value instanceof Blob)) {
              keys(value).forEach((key) => {
                formData.append(`${name}[${i}]${key}`, value[key])
              })
            } else {
              formData.append(name, value)
            }
          })
        } else if (isPlainObject(body[name])) {
          keys(body[name]).forEach((key) => {
            formData.append(`${name}.${key}`, body[name][key])
          })
        } else {
          if (body[name] !== null) {
            formData.append(name, body[name])
          }
        }
      }
      return formData
    } else {
      return JSON.stringify(body)
    }
  }

  async handleResponseCallback(response) {
    if (response.status === 204) {
      // 204 (No Content)
      return Promise.resolve({})
    } else if (response.status === 500) {
      return Promise.reject(response)
    }

    const contentType = response.headers.get('Content-Type')

    if (contentType && contentType === 'application/octet-stream') {
      if (response.ok) {
        const filename = response.headers
          .get('Content-Disposition')
          .split('filename=')[1]
          .substring(1, response.headers.get('Content-Disposition').split('filename=')[1].length - 1)
        const blob = await response.blob()
        return { blob: blob, filename: filename }
      } else {
        return Promise.reject(response)
      }
    }

    if (response.headers.get('Content-Type') !== 'application/json') {
      return Promise.reject(response)
    }

    return response.json().then(function(body) {
      if (response.ok) {
        return body
      }
      // handle errors
      var errors = {
        status: response.status,
      }
      keys(body).forEach((key) => {
        let eKey = key
        if (key === 'non_field_errors' || key === 'nonFieldErrors' || key === 'detail') {
          eKey = '_error'
        }
        if (Array.isArray(body[key])) {
          errors[eKey] = body[key][0]
        } else {
          errors[eKey] = body[key]
        }
      })
      return Promise.reject(errors)
    })
  }

  async handleStreamResponse(response) {
    if (!response.ok) {
      return Promise.reject(response)
    }

    const reader = response.body.getReader()
    const decoder = new TextDecoder()
    let buffer = ''
    const items = []

    async function readStream() {
      while (true) {
        const { value, done } = await reader.read()
        if (done) {
          break
        }

        buffer += decoder.decode(value, { stream: true })
        const lines = buffer.split('\n')

        // Keep the last incomplete line in buffer
        buffer = lines.pop() || ''

        for (const line of lines) {
          try {
            if (line.trim()) {
              items.push(JSON.parse(line))
            }
          } catch (err) {
            console.error('Error parsing line:', line, err)
          }
        }
      }

      // Handle any remaining buffer content
      if (buffer.trim()) {
        try {
          items.push(JSON.parse(buffer))
        } catch (err) {
          console.error('Error parsing last buffer:', buffer, err)
        }
      }
    }

    await readStream()
    return items
  }

  request(method, params = {}, body = {}, isStreaming = false) {
    // choose api url, defaulting to api_v2 on lender side
    const api = getBaseUrl(this.version, this.service_url_override)

    const queryParams = isEmpty(params) ? '' : '?' + buildQueryParams(params)
    let resource = `${api}${this.endpoint}`
    if (this.version === 1 && Object.keys(queryParams).length > 1) {
      resource = `${resource}/${queryParams}`
    } else if (Object.keys(queryParams).length > 1) {
      resource = `${resource}${queryParams}`
    }
    const headers = new Headers({
      Authorization: this.getAuthorizationHeader(),
      'Content-Type': 'application/json',
    })

    const isMultipartFormData = hasFile(body)
    isMultipartFormData && headers.delete('Content-Type')

    body = method === REQUEST_METHOD.GET ? undefined : this.prepareBody(body, isMultipartFormData)
    const options = {
      method,
      headers,
      body,
    }
    return isStreaming
      ? fetch(resource, options).then(this.handleStreamResponse.bind(this))
      : fetch(resource, options).then(this.handleResponseCallback.bind(this))
  }

  post(body = {}, params = {}) {
    return this.request(REQUEST_METHOD.POST, params, body)
  }

  get(params) {
    return this.request(REQUEST_METHOD.GET, params)
  }

  put(body = {}, params = {}) {
    return this.request(REQUEST_METHOD.PUT, params, body)
  }

  patch(body = {}, params = {}) {
    return this.request(REQUEST_METHOD.PATCH, params, body)
  }

  options() {
    return this.request(REQUEST_METHOD.OPTIONS)
  }

  delete() {
    return this.request(REQUEST_METHOD.DELETE)
  }
}
