import React from 'react'
import PropTypes from 'prop-types'
import { Spin, Space, Typography } from 'antd'
import '../styles/LoadingSpinner.scss'

const { Text } = Typography

const LoadingSpinner = ({ text, className }) => {
  return (
    <div className={`loading-spinner-container ${className || ''}`}>
      <Space direction="vertical" align="center" size="large">
        <Spin size="large" style={{ transform: 'scale(1.5)' }} />
        {text && <Text>{text}</Text>}
      </Space>
    </div>
  )
}

LoadingSpinner.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
}

export default LoadingSpinner
