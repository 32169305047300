import React from 'react'
import { Button, Space, Empty, Tag, List, Typography } from 'antd'
import {
  ArrowLeftOutlined,
  PlusOutlined,
  DollarOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  AppstoreOutlined,
} from '@ant-design/icons'
import { Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import RowCard from './RowCard'
import LoadingSpinner from './LoadingSpinner'
import { FEE_COL, DUE_DATE_COL } from '../constants/Validation'
import '../styles/ViewOverrideType.scss'

const { Text, Title } = Typography

const ViewOverrideType = ({
  overrideType,
  overrides,
  isLoading,
  error,
  typeConfig,
  handleAddOverride,
  handleBack,
  handleViewOverride,
}) => {
  if (!typeConfig) {
    return <div>Invalid override type</div>
  }

  if (error) {
    return (
      <div className="view-override-type fee-management-container">
        <div className="header-section">
          <Button className="back-button" icon={<ArrowLeftOutlined />} onClick={handleBack} />
          <div>Error loading overrides: {error}</div>
        </div>
      </div>
    )
  }

  const getFieldIcon = (field) => {
    if (field === FEE_COL.columnKey) {
      return <DollarOutlined />
    }
    if (field === DUE_DATE_COL.columnKey) {
      return <CalendarOutlined />
    }
    return null
  }

  // Calculate metadata
  const totalOverrides = overrides.length
  const activeOverrides = overrides.filter((override) => override.status === 'active').length

  const renderOverrideCard = (override) => {
    const isActiveStatus = override.status === 'active'

    // Create a layout using reactstrap Row and Col
    const leftContent = (
      <Row className="override-card-row">
        <Col xs="9" className="content-column">
          <h4 className="ant-typography">
            {override.title.split(' ').map((word, index) =>
              index === 0 ? (
                <span key={index} className="title-emphasis">
                  {word}
                </span>
              ) : (
                <span key={index}> {word}</span>
              ),
            )}
          </h4>
          <div className="field-badges">
            {override.overriddenFields.map((field) => (
              <Tag key={field} className="field-badge" icon={getFieldIcon(field)}>
                {field === FEE_COL.columnKey
                  ? FEE_COL.readable
                  : field === DUE_DATE_COL.columnKey
                    ? DUE_DATE_COL.readable
                    : field}
              </Tag>
            ))}
          </div>
        </Col>
        <Col xs="3" className="status-column">
          <Tag
            icon={isActiveStatus ? <CheckCircleOutlined /> : <ClockCircleOutlined />}
            color={isActiveStatus ? 'success' : 'default'}
            className="status-tag"
          >
            {override.status.charAt(0).toUpperCase() + override.status.slice(1)}
          </Tag>
        </Col>
      </Row>
    )

    // Just use the modified date in the stats section
    const statsItems = [
      {
        label: 'Modified',
        value: override.details['Last Modified'],
        className: 'modified-stat',
      },
    ]

    return (
      <RowCard
        key={override.id}
        className="override-card"
        leftContent={leftContent}
        statsItems={statsItems}
        onSelect={() => handleViewOverride(override.id)}
      />
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSpinner text={`Loading ${typeConfig.title.toLowerCase()}...`} />
    }

    if (overrides.length === 0) {
      return (
        <Empty
          description={
            <Space direction="vertical" align="center">
              <span>No overrides found</span>
              <span className="text-secondary">{typeConfig.description}</span>
            </Space>
          }
        >
          <Button type="primary" icon={<PlusOutlined />} onClick={() => handleAddOverride(overrideType)}>
            Create First Override
          </Button>
        </Empty>
      )
    }

    return (
      <List className="override-list" itemLayout="vertical" dataSource={overrides} renderItem={renderOverrideCard} />
    )
  }

  return (
    <div className="grid-layout override-list-container">
      <div className="metadata-section">
        <div className="metadata-header">
          <div className="metadata-content">
            <Title level={3}>Overrides</Title>
            <Text type="secondary" className="metadata-description">
              {typeConfig.description}
            </Text>
            <div className="stats-tags">
              <Tag icon={<AppstoreOutlined />} className="stat-tag total-tag">
                <span className="stat-label">Total: </span>
                <span className="stat-value">{totalOverrides}</span>
              </Tag>
              <Tag icon={<CheckCircleOutlined />} className="stat-tag active-tag">
                <span className="stat-label">Active: </span>
                <span className="stat-value">{activeOverrides}</span>
              </Tag>
            </div>
          </div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            className="add-override-button"
            onClick={() => handleAddOverride(overrideType)}
          >
            Add Override
          </Button>
        </div>
      </div>
      <div className="list-section">
        <div className="content-layout">
          <div className="list-content">{renderContent()}</div>
        </div>
      </div>
    </div>
  )
}

export default ViewOverrideType

ViewOverrideType.propTypes = {
  overrideType: PropTypes.string.isRequired,
  overrides: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  typeConfig: PropTypes.object.isRequired,
  handleAddOverride: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleViewOverride: PropTypes.func.isRequired,
}
