import React from 'react'
import { HighlightableDataPoint } from './HighlightableDataPoint'

/**
 * A specialized wrapper component for displaying data points in ResultView
 * that supports PDF highlighting. This keeps highlighting functionality
 * isolated to the ResultView context without modifying ReviewDatapoints.jsx.
 *
 * When the reggoraReviewPdfAnnotation flag is disabled, this always uses text search.
 */
export function HighlightableDataPoints({
  data_points = [],
  highlightedFieldIds,
  onHighlight,
  onSearch,
}) {
  if (data_points.length === 0) {
    return null
  }

  return (
    <>
      <span className="data-points-label">Related Data Points: </span>

      {data_points.length === 1 ? (
        <HighlightableDataPoint
          name={data_points[0].name}
          rendered_value={data_points[0].rendered_value}
          field_id={data_points[0].field_id}
          highlightedFieldIds={highlightedFieldIds}
          onHighlight={onHighlight}
          onSearch={onSearch}
        />
      ) : (
        <ul className="bullets">
          {data_points.map((field, index) => (
            <li key={index}>
              <HighlightableDataPoint
                name={field.name}
                rendered_value={field.rendered_value}
                field_id={field.field_id}
                highlightedFieldIds={highlightedFieldIds}
                onHighlight={onHighlight}
                onSearch={onSearch}
              />
            </li>
          ))}
        </ul>
      )}
    </>
  )
}
