import { useMemo } from 'react'
import { ExclamationCircleFilled, WarningFilled, ClusterOutlined, CopyFilled } from '@ant-design/icons'
import { FeeValidationErrorTypes, FEE_MGMT_COLS_READABLE } from '../constants/Validation'
import { useValidation } from './useValidation'

const createDuplicateFieldConfig = (tooltipText) => ({
  label: `Duplicate ${tooltipText}`,
  color: '#1890ff',
  icon: ClusterOutlined,
  tooltipText: `rows with duplicate ${tooltipText}`,
})

const addLocationHeaders = (baseConfig) => {
  baseConfig[FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE] = {
    label: 'Invalid Field',
    color: '#faad14',
    icon: WarningFilled,
    tooltipText: 'rows with fields not allowed for their location type',
  }
  baseConfig[FeeValidationErrorTypes.DUPLICATE_STATE] = createDuplicateFieldConfig('States')
  baseConfig[FeeValidationErrorTypes.DUPLICATE_COUNTY] = createDuplicateFieldConfig('Counties')
  baseConfig[FeeValidationErrorTypes.DUPLICATE_ZIP] = createDuplicateFieldConfig('ZIP Codes')
}

export const useValidationPillsConfig = (overrideType, selectedFields = []) => {
  const { getRequiredColumns } = useValidation()
  const validationConfig = useMemo(() => {
    const { requiredColumns } = getRequiredColumns(overrideType, selectedFields, true)

    const baseConfig = {
      [FeeValidationErrorTypes.MISSING_VALUE]: {
        label: 'Missing Required',
        color: '#ff4d4f',
        icon: ExclamationCircleFilled,
        tooltipText: 'rows with missing required values',
      },
      [FeeValidationErrorTypes.INVALID_FORMAT]: {
        label: 'Invalid Format',
        color: '#faad14',
        icon: WarningFilled,
        tooltipText: 'rows with invalid format',
      },
      [FeeValidationErrorTypes.DUPLICATE_ROW]: {
        label: 'Duplicate Rows',
        color: '#a78bfa',
        icon: CopyFilled,
        tooltipText: 'duplicate rows (automatically marked for deletion)',
      },
    }

    if (requiredColumns.includes(FEE_MGMT_COLS_READABLE.LOCATION_TYPE)) {
      addLocationHeaders(baseConfig)
    }

    if (requiredColumns.includes(FEE_MGMT_COLS_READABLE.VENDOR)) {
      baseConfig[FeeValidationErrorTypes.DUPLICATE_VENDOR] = {
        label: 'Duplicate Vendor',
        color: '#1890ff',
        icon: ClusterOutlined,
        tooltipText: 'rows with duplicate vendors',
      }
    }

    return baseConfig
  }, [overrideType, selectedFields, getRequiredColumns])

  return validationConfig
}

export default useValidationPillsConfig
