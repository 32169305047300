import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react'
import { Select } from 'antd'
import PropTypes from 'prop-types'

const SelectCellEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value || '')
  const selectRef = useRef(null)
  const containerRef = useRef(null)

  // Options from props or empty array as fallback
  const options = props.options || []

  // Properly implement AG Grid cell editor interface
  useImperativeHandle(ref, () => {
    return {
      // AG Grid will call this when getting the value
      getValue() {
        return value
      },
      // AG Grid calls this when user hits Tab
      isCancelBeforeStart() {
        return false
      },
      // AG Grid calls this when escape is pressed
      isCancelAfterEnd() {
        return false
      },
      // Focus the editor when it's shown
      focusIn() {
        setTimeout(() => {
          selectRef.current?.focus()
        }, 0)
      },
      // Called after the editor is attached to the DOM
      afterGuiAttached() {
        selectRef.current?.focus()
      },
    }
  })

  useEffect(() => {
    // Focus the select when the editor is activated
    setTimeout(() => {
      selectRef.current?.focus()
    }, 100)

    // Add click event listener to detect outside clicks
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        // Check if the click was outside the dropdown too
        const dropdownContainer = document.querySelector('.ant-select-dropdown')
        if (!dropdownContainer || !dropdownContainer.contains(event.target)) {
          // Click was outside both the editor and dropdown, stop editing
          props.api.stopEditing()
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    // Clean up the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [props.api])

  const handleChange = (newValue) => {
    setValue(newValue)

    // Update the data in the grid
    const updatedData = {
      ...props.node.data,
      [props.column.colId]: newValue,
    }

    // Tell AG Grid to update the data
    props.api.applyTransactionAsync(
      {
        update: [updatedData],
      },
      () => {
        // Refresh validation if needed
        if (props.context?.refreshValidation) {
          setTimeout(() => {
            props.context.refreshValidation()
          }, 0)
        }
      },
    )

    // Stop editing to commit the change
    props.api.stopEditing()
  }

  return (
    <div className="entity-select-editor" ref={containerRef}>
      <Select
        ref={selectRef}
        value={value}
        onChange={handleChange}
        options={options}
        style={{ width: '100%' }}
        placeholder={props.placeholder || 'Select a value'}
        showSearch={props.showSearch !== undefined ? props.showSearch : false}
        virtual={props.virtual !== undefined ? props.virtual : false}
        autoFocus={true}
        defaultOpen={true}
      />
    </div>
  )
})

SelectCellEditor.displayName = 'SelectCellEditor'

SelectCellEditor.propTypes = {
  value: PropTypes.string,
  api: PropTypes.object,
  column: PropTypes.object,
  node: PropTypes.object,
  rowIndex: PropTypes.number,
  colDef: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  placeholder: PropTypes.string,
  showSearch: PropTypes.bool,
  virtual: PropTypes.bool,
  context: PropTypes.object,
}

export default SelectCellEditor
